<template>
  <div class="pageLayout">
    <LeftSide v-if="$store.state.width > 768" />
    <div class="pRight" style="text-align:center;">
      <div link style="width:100%; height:96px;display:grid; place-items:center">
        <img alt="Demir Çelik Sözlüğü" width="96" height="96" src="@/assets/dictionary-icon.png">
      </div>
      <h1 style="text-align:center;">Çelik Sözlük</h1>
      <div class="search">
        <input @keypress.enter="searchWord" type="text" v-model="search" placeholder="Arama yapın" />
      </div>
      <div class="alphabet">
        <router-link
        :class="{ hasSubs: item.length > 0, activeLetter: idx === letter }"
        :to="{ name: 'DictionaryLetter', params: { letter: idx }}" v-for="(item,idx) in $store.state.alphabet" :key="idx">{{ idx }}</router-link>
      </div>
      <div style="margin-top:20px">
        <div class="item" v-for="item in letterItems" :key="item.id">
          <h2><router-link :to="{ name: 'DictionaryWord', params: { word: item.slug } }">{{ item.title }}</router-link></h2>
          <div class="answer">
            <div class="description" v-if="item.description">{{ item.description }}</div>
            <div class="ql-editor" v-html="item.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSide from '@/small-components/LeftSide'
export default {
  props: ['page', 'hideTitle'],
  components: { LeftSide },
  data: function () {
    return {
      cdn: this.$store.state.cdn,
      letters: [],
      activeAnswer: null,
      search: '',
      letter: null,
      letterItems: []
    }
  },
  mounted: function () {
    // this.getDictionary()
    const letter = this.$route.params.letter
    if (letter) {
      this.setLetter(letter)
    } else {
      this.setLetter('A')
    }
  },
  watch: {
    $route: function () {
      const r = this.$route.name
      if (r === 'DictionaryWord') {
        this.getWord(this.$route.params.word)
      } else {
        const letter = this.$route.params.letter
        if (letter) {
          this.setLetter(letter)
        } else {
          this.setLetter('A')
        }
      }
    }
  },
  methods: {
    searchWord: async function () {
      const search = this.search.toLowerCase()
      if (search.length > 0) {
        // add delay before search
        await this.$nextTick()
        await this.axios.get('dictionary/s/' + search).then(response => {
          this.letterItems = response.data
          this.letter = null
        })
      } else {
        const r = this.$route.name
        if (r !== 'PageDetail') {
          this.$router.push({ name: 'PageDetail', params: { type: 'di', slug: 'celik-sozluk' } })
        }
      }
    },
    setLetter: function (letter) {
      this.letter = letter
      this.getDictionary()
    },
    getWord: async function (word) {
      await this.axios.get('dictionary/w/' + word).then(response => {
        this.letterItems = response.data
        this.letter = this.letterItems[0].title[0]
      })
    },
    getDictionary: async function () {
      const alphabet = this.$store.state.alphabet
      const r = this.$route.name
      if (alphabet.length === 0) {
        await this.axios.get('dictionary').then(response => {
          this.$store.dispatch('setAlphabet', response.data)
        })
      }
      if (r === 'DictionaryWord') {
        const word = this.$route.params.word
        this.getWord(word)
      } else {
        this.letterItems = this.$store.state.alphabet[this.letter]
      }
      // this.letterItems = this.$store.state.alphabet[this.letter]
    }
  }
}
</script>

<style lang="scss" scoped>
.alphabet {
  display:flex;
  justify-content: center;
  gap: 4px;
  flex-wrap: wrap;
  user-select: none;
  a {
    display: block;
    padding: 4px;
    border-radius: 3px;
    color: #414141;
  }
  .hasSubs {
    color: #ccc;
  }
  .activeLetter {
    background: #fff;
    color: #414141;
  }
}
.pRight {
  background:rgba($color: #fff, $alpha: 0.04);
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
}
.search {
  display:flex;
  place-content: center;
  padding: 12px;
  margin-bottom: 16px;
  input {
    padding: 12px;
    border-radius: 22px;
    min-width: 280px;
  }
}
.item {
  padding: 0;
  margin:0;
  margin-bottom: 45px;
  text-align: left;
  background:red;
  padding: 12px;
  background:rgba($color: #fff, $alpha: 0.04);
  border-radius: 8px;
  h2 {
    justify-content:space-between;
    align-items: center;
    user-select: none;
    margin: 0;
    margin-bottom: 10px;
    a {
      width: 100%;
      display:block;
    }
  }
  .answer {
    line-height: 32px;
    .ql-editor {
      p {
        line-height: 24px;
      }
    }
  }
}
</style>
