<template>
  <div class="container">
    <div class="homeLayout" :style="'margin-top:' + top">
      <div class="left">
        <Promo :type="'leaderboard'" v-if="$store.state.width > 768" />
        <Promo :type="'mobile-leaderboard'" v-else style="margin-top: 10px; margin-bottom:4px;"/>
        <div>
          <div class="caption">
            <h1 class="uppercase">{{ $t('Tüm Yorumlar') }}</h1>
          </div>
          <CommentBar style="width:100%" :info="commentBar" :imageSize="'small'" />
        </div>
      </div>
      <div class="homeSide" v-if="$store.state.width > 768">
        <Promo :type="'half-page'" />
        <div class="caption">
          <h2>POPÜLER YORUMCULAR</h2>
        </div>
        <div style="margin-top:12px">
          <ProfileCard v-for="item in topCommenters.data" :key="item.id" :info="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data () {
    return {}
  },
  computed: {
    commentBar () {
      const cb = this.$store.state.commentBar
      return cb
    },
    top () {
      if (this.$store.state.width > 768) {
        return '20px'
      } else {
        return '4px'
      }
    },
    topCommenters () {
      return this.$store.state.home.topCommenters
    }
  },
  mounted () {
    this.getTopCommenters()
  },
  methods: {
    getTopCommenters () {
      this.axios.get('top-commenters').then(response => {
        this.$store.commit('setTopCommenters', response.data)
      })
    }
  }
}
</script>

<style scoped>
.homeLayout {
  margin-top: 20px;
}
</style>
