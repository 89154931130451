<template>
  <div>
    <form id="loginForm">
      <h1>{{ $t('Üye Girişi') }}</h1>
      <div class="row">
        <label>{{ $t('E-Posta Adresiniz') }}</label>
        <input @keypress.enter="$parent.login" name="email" v-model="$parent.loginForm.email" type="email" autocomplete="email" :placeholder="$t('E-Posta Adresiniz')">
      </div>
      <div class="row">
        <label>{{ $t('Parolanız') }}</label>
        <input @keypress.enter="$parent.login" name="password" v-model="$parent.loginForm.password" type="password" autocomplete="current-password" :placeholder="$t('Parolanız')">
      </div>
      <div class="row">
        <router-link :to="{ name: 'ForgetPassword' }" class="iconLink"><i class="fa fa-fish"></i> {{ $t('Parolanızı mı unuttunuz?') }}</router-link>
      </div>
      <div class="row">
        <button v-if="!$parent.isLoading" class="btn1 c1" @click="$parent.login" type="button"><i class="fa fa-lock"></i>{{ $t('Giriş Yapın') }}</button>
        <button v-else class="btn1 c1" type="button"><span class="inlineLoading"></span>{{ $t('Lütfen Bekleyin') }}</button>
      </div>
      <div class="row center grey">veya</div>
      <AppleLogin :redirect="'Home'" :appleClass="'whiteApple'" />
    </form>
  </div>
</template>

<style lang="scss">
.btn1 {
  i {
    color:#fff;
  }
}
form {
  font-size:14px;
  font-family: var(--font2);
  .isInvalid {
    border: none;
    border-bottom: 1px solid red;
    background: rgba(255,0,0,0.2) !important;
  }
  h1 {
    font-size: 22px;
    font-weight: normal;
    text-transform: uppercase;
  }
  .row {
    padding: 5x;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 0px;
    margin-bottom: 20px;
    label {
      text-align: left;
    }
  }
  @media screen and (max-width: 768px) {
    .row {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 0px;
      label {
        text-align: left;
      }
    }
  }
  input, button, select, .input {
    padding: 9px;
    border-radius: 6px;
    height: 42px;
    background: none;
    border: none;
    border: 1px solid rgb(59, 59, 59);
    color: #fff;
    width: 100%;
    background: rgba($color: #fff, $alpha: 0.05);
  }
  .input {
    background: rgba($color: #fff, $alpha: 0.03);
    border: 1px solid rgba($color: #fff, $alpha: 0);
  }
  .subInfo {
    color: grey;
    padding: 4px;
    font-size: 13px;
  }
  button {
    text-align: center;
    justify-content: center;
    height: 40px !important;
    font-size: 13px !important;
    i {
      font-size: 16px;
    }
  }
  label {
    font-size: 12px;
    margin-bottom: 3px;
    color: #ccc;
  }
  .facebook {
    background: #3b5998;
    color: #fff;
  }
  .google {
    background: #4285f4;
    color: #fff;
  }
  .apple {
    background: #fff;
    color: #000;
  }
  .social {
    button {
      height: 40px !important;
      display: grid;
      span {
        display: none;
      }
    }
  }
}
</style>

<script>
import AppleLogin from '@/components/AppleLoginFire.vue'
export default ({
  components: {
    AppleLogin
  },
  props: ['from'],
  data () {
    return {
      email: '',
      password: '',
      rememberMe: true,
      user: this.$store.state.user,
      formError: '',
      active: false
    }
  },
  methods: {
    setRemember: function () {
      this.rememberMe = !this.rememberMe
    },
    login: async function () {
      const payload = {
        email: this.email,
        password: this.password
      }
      const config = {
        headers: {
          Device: JSON.stringify(this.$store.state.platform)
        }
      }
      await this.axios.post('/login', payload, config).then(response => {
        this.formError = ''
        localStorage.setItem('token', response.data.admin.token)
        this.$store.dispatch('setUser', response.data.admin)
        const oldTokens = response.data.admin.tokens.length
        if (oldTokens > 0) {
          this.$toast.success(this.$t('Since you logged in from this device, your other devices will be logged out.'))
          setTimeout(() => {
            window.location.href = '/tr'
          }, 2000)
        } else {
          window.location.href = '/tr'
        }
      })
    },
    logout: async function () {
      await this.axios.post('/logout').then(response => {
        console.log(response.data)
        localStorage.removeItem('token')
        this.$store.dispatch('setUser', {})
      })
    }
  }
})
</script>
