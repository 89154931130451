import Vue from 'vue'
import App1 from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSplide from '@splidejs/vue-splide'
import x5GMaps from 'x5-gmaps'
import VueMeta from 'vue-meta'
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'
import { Toast } from '@capacitor/toast'
import { StatusBar } from '@capacitor/status-bar'
import { Browser } from '@capacitor/browser'
import { Share } from '@capacitor/share'
import { PushNotifications } from '@capacitor/push-notifications'
import { RateApp } from 'capacitor-rate-app'
// firebase

import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import Header from '@/components/Header.vue'
import ErrorPage from '@/components/ErrorPage.vue'
import SideMenu from '@/components/SideMenu.vue'
import GlassModal from '@/components/GlassModal.vue'
import SimpleHeader from '@/components/SimpleHeader.vue'
import Home from '@/components/Home.vue'
import Footer from '@/components/Footer.vue'
import SimpleFooter from '@/components/SimpleFooter.vue'
import StarRating from 'vue-star-rating'
import Products from '@/components/Products.vue'
import NewsCard1 from '@/components/NewsCard1.vue'
import NewsCard2 from '@/components/NewsCard2.vue'
import NewsCard3 from '@/components/NewsCard3.vue'
import NewsCard4 from '@/components/NewsCard4.vue'
import NewsCard5 from '@/components/NewsCard5.vue'
import NewsCard6 from '@/components/NewsCard6.vue'
import ProfileCard from '@/components/ProfileCard.vue'
import CommentCard from '@/components/CommentCard.vue'
import CommentBar from '@/components/CommentBar.vue'
import Promo from '@/components/Promo.vue'
import SimplePager from '@/components/SimplePager.vue'
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import LoginInfo from '@/components/LoginInfo.vue'
import MemberError from '@/components/MemberError.vue'
import Card from '@/components/Card.vue'
import YoutubeCard from '@/components/YoutubeCard.vue'
import VueSocialSharing from 'vue-social-sharing'

import { fnc } from './fnc.js'
// import * as rax from 'retry-axios'
import i18n from './i18n'
import VueJsonLD from 'vue-jsonld'
import VueGtag from 'vue-gtag'
import VTooltip from 'v-tooltip'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import VModal from 'vue-js-modal'
import VueEditor from 'vue2-editor'
import treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import vuescroll from 'vue-scroll'
import VueApexCharts from 'vue-apexcharts'
// import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2'

import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyAHPzYvbsB5LxvLjmWsBf63OCcryF5Jw24',
  authDomain: 'artkaf-68e7a.firebaseapp.com',
  projectId: 'artkaf-68e7a',
  storageBucket: 'artkaf-68e7a.appspot.com',
  messagingSenderId: '903970955527',
  appId: '1:903970955527:web:1d86c9269c99bf84a065a2',
  measurementId: 'G-J5EM7T415B'
}

initializeApp(firebaseConfig)

Vue.use(VueApexCharts)
// Vue.use(InAppPurchase2)

/* const platform = localStorage.getItem('platform')
// alert('inappplatform: ' + InAppPurchase2.platform)
InAppPurchase2.ready(async () => {
  alert('here')
  console.log('store ready')
  console.log('platform' + platform)
})
InAppPurchase2.error((err) => {
  alert('hereError')
  console.error('Store Error ' + JSON.stringify(err))
})
*/

Vue.component('apexchart', VueApexCharts)

Vue.component('Header', Header)
Vue.component('SideMenu', SideMenu)
Vue.component('ErrorPage', ErrorPage)
Vue.component('GlassModal', GlassModal)
Vue.component('Home', Home)
Vue.component('Footer', Footer)
Vue.component('Products', Products)
Vue.component('NewsCard1', NewsCard1)
Vue.component('NewsCard2', NewsCard2)
Vue.component('NewsCard3', NewsCard3)
Vue.component('NewsCard4', NewsCard4)
Vue.component('NewsCard5', NewsCard5)
Vue.component('NewsCard6', NewsCard6)
Vue.component('ProfileCard', ProfileCard)
Vue.component('CommentCard', CommentCard)
Vue.component('CommentBar', CommentBar)
Vue.component('Promo', Promo)
Vue.component('SimpleHeader', SimpleHeader)
Vue.component('SimpleFooter', SimpleFooter)
Vue.component('StarRating', StarRating)
Vue.component('treeselect', treeselect)
Vue.component('BreadCrumb', BreadCrumb)
Vue.component('LoadingPlaceholder', LoadingPlaceholder)
Vue.component('LoginInfo', LoginInfo)
Vue.component('SimplePager', SimplePager)
Vue.component('MemberError', MemberError)
Vue.component('Card', Card)
Vue.component('YoutubeCard', YoutubeCard)

Vue.use(require('vue-shortkey'))

Vue.use(VueSplide)
Vue.use(VueToast, {
  position: 'top',
  queue: false
})
Vue.use(VueAxios, axios, store)
Vue.use(x5GMaps, {
  key: 'AIzaSyBqD23PQG_GhFUZTPpO0TKFSG0TSrCHaAM',
  libraries: ['visualization', 'places']
})
Vue.use(VTooltip)
Vue.use(VueMeta)
Vue.use(VueJsonLD)
Vue.use(VueEditor)
Vue.use(VueSocialSharing)
Vue.use(MemberError)
Vue.use(VuejsDialog, {
  type: 'basic',
  html: true,
  reverse: false,
  backdropClose: true,
  customClass: 'bg2',
  loader: true,
  animation: 'zoom'
})
Vue.use(VModal)
Vue.use(vuescroll, { debounce: 100 })
Vue.use(VueGtag, {
  pageTrackerExcludedRotues: ['Index', 'Products'],
  config: {
    id: '', // analytics code (UA-XXXXXX)
    params: {
      send_page_view: true
    }
  }
})
// axios.defaults.baseURL = process.env.VUE_APP_BASE
// screen.orientation.lock('portrait')
if (localStorage.getItem('isLocal') === 'true') {
  axios.defaults.baseURL = 'http://192.168.1.195:5000/api/'
  // axios.defaults.baseURL = 'http://localhost:8000/api/'
} else {
  axios.defaults.baseURL = 'https://api.gunceldemir.com/api/'
}
function detectLanguage () {
  let locale = ''
  const savedLocale = localStorage.getItem('lang')
  if (savedLocale) {
    locale = savedLocale
  } else {
    const bLocale = window.location.pathname.replace('/', '').trim().substring(0, 2)
    const langs = ['tr', 'en', 'ru']
    if (langs.includes(bLocale)) {
      locale = bLocale
    } else {
      locale = 'tr'
    }
  }
  return locale
}
const lang = detectLanguage()
/*
var vm = new Vue({
  store
})
*/
if (localStorage.getItem('token')) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token')
}
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

document.querySelector('html').setAttribute('lang', lang)
/*
const retry = 6
axios.defaults.raxConfig = {
  instance: axios,
  retry: retry,
  noResponseRetries: retry,
  retryDelay: 750,
  httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'POST'],
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err)
    if (cfg.currentRetryAttempt === retry) {
      Vue.$toast.error('İşlem tamamlanamadı, lütfen sayfayı yenileyin', {
        position: 'bottom-right',
        duration: 5000,
        onClick: function () {
          location.reload()
        }
      })
    }
    console.log(`Retry attempt #${cfg.currentRetryAttempt}`)
  }
}
rax.attach(axios)
*/
// axios.defaults.timeout = 1200

App.addListener('appStateChange', ({ isActive }) => {
  // console.log('App state changed. Is active?', isActive)
  // reload page
})

App.addListener('backButton', ({ BackButtonListener }) => {
  console.log('App state changed. Is active?', BackButtonListener)
  const path = window.location.pathname
  if (path === '/') {
    App.exitApp()
  } else {
    window.history.back()
  }
})
Vue.config.productionTip = false
Vue.prototype.$fnc = fnc
const addListeners = async () => {
  const device = await Device.getInfo()
  if (device.platform !== 'web') {
    await PushNotifications.addListener('registration', token => {
      console.info('Registration token: ', token.value)
      store.dispatch('setPushToken', token.value)
    })
    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error)
    })

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification)
    })

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue)
    })
  }
}
addListeners()
new Vue({
  data: {
    toast: async function (msg) {
      const d = await Device.getInfo()
      if (d.platform !== 'web') {
        await Toast.show({
          text: msg
        })
      } else {
        Vue.$toast.open(msg)
      }
    },
    bearer: function () {
      if (this.$store.state.user.token) {
        return this.$store.state.user.token
      } else if (localStorage.getItem('token')) {
        return localStorage.getItem('token')
      } else {
        return false
      }
    },
    getDeviceInfo: async function () {
      const d = await Device.getInfo()
      localStorage.setItem('platform', d.platform)
      // set state platform to store
      this.$store.commit('setPlatform', d)
      localStorage.setItem('device', JSON.stringify(d))
    },
    statusBar: async function (color) {
      const d = await Device.getInfo()
      console.log(color)
      if (d.platform !== 'web') {
        StatusBar.setOverlaysWebView({ overlay: false })
        StatusBar.setBackgroundColor({ color: color, animated: false, translucent: true, hideWhenGoingBack: true })
        // StatusBar.setStyle({ style: 'dark' })
        // alert('yes')
        // StatusBar.hide(true)
      }
    },
    hideStatusBar: async function () {
      StatusBar.hide(true)
    },
    showStatusBar: async function () {
      StatusBar.setOverlaysWebView({ overlay: true })
      StatusBar.setStyle({ style: 'DARK' })
      StatusBar.show({ animated: true })
    },
    openBrowser: async function (url) {
      await Browser.open({ url: url })
    },
    share: async function (title, text, url, dialogTitle) {
      // const platform = localStorage.getItem('platform')
      await Share.share({
        title: title,
        text: text,
        url: url,
        dialogTitle: dialogTitle
      })
    },
    registerPush: async function () {
      const device = await Device.getInfo()
      if (device.platform === 'web') {
        return
      }
      let permStatus = await PushNotifications.checkPermissions()
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }
      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!')
      }
      await PushNotifications.register()
    },
    rateApp: function () {
      if (localStorage.getItem('platform') === 'android') {
        RateApp.requestReview()
      }
    }
  },
  router,
  store,
  i18n,
  axios,
  VTooltip,
  treeselect,
  render: h => h(App1)
}).$mount('#app')
