<template>
  <div>
    <div v-if="$store.state.width > 768">
      <div class="container">
        <div class="loginContainer" v-if="$route.name === 'Login' || $route.name === 'Register'">
          <LoginForm :from="From" />
          <div class="sep">&nbsp;</div>
          <LoginRegisterForm :info="info" />
        </div>
        <div class="loginContainer" v-else-if="$route.name === 'ForgetPassword'">
          <PasswordResetForm :from="From" />
          <div class="sep">&nbsp;</div>
          <div>
            <img style="width:100%" src="https://icon-library.com/images/reset-password-icon/reset-password-icon-29.jpg">
          </div>
        </div>
        <div class="loginContainer" v-else-if="$route.name === 'CompleteReset'">
          <PasswordResetForm :from="From" />
          <div class="sep">&nbsp;</div>
          <div>
            <img style="width:100%" src="https://icon-library.com/images/reset-password-icon/reset-password-icon-29.jpg">
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding:20px">
      <LoginForm :from="From" v-if="$route.name === 'Login'" />
      <LoginRegisterForm :info="info" v-if="$route.name === 'Register'" />
      <PasswordResetForm v-if="$route.name === 'ForgetPassword'" />
      <div v-if="$route.name === 'CompleteReset'">
        <PasswordResetForm :from="From" />
      </div>
    </div>
    <div class="backdrop" v-if="isLoading"></div>
  </div>
</template>

<script>
import LoginForm from '@/small-components/LoginForm'
import LoginRegisterForm from '@/small-components/LoginRegisterForm'
import PasswordResetForm from '@/small-components/PasswordResetForm'
export default {
  components: { LoginForm, LoginRegisterForm, PasswordResetForm },
  props: ['info'],
  data () {
    return {
      From: null,
      isLoading: false,
      loginForm: {
        email: '',
        password: ''
      },
      registerForm: {
        name: '',
        email: '',
        company_name: '',
        password: '',
        gsm: '',
        membership: false,
        kvkk: false
      },
      resetForm: {
        email: '',
        gsm: ''
      },
      active: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  mounted () {
    if (this.user) {
      // redirect to profile
      this.$router.push({ name: 'ProfileSettings' })
    }
  },
  metaInfo () {
    let title = ''
    if (this.$route.name === 'Login') {
      title = this.$i18n.t('Giriş Yapın')
    } else if (this.$route.name === 'Register') {
      title = this.$i18n.t('Üye Olun')
    } else if (this.$route.name === 'ForgetPassword') {
      title = this.$i18n.t('Şifenizi Sıfırlayın')
    } else if (this.$route.name === 'CompleteReset') {
      title = this.$i18n.t('Şifenizi Sıfırlayın')
    }
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  },
  methods: {
    login: async function () {
      if (!this.active) {
        return
      }
      const email = this.loginForm.email
      const password = this.loginForm.password
      if (!email) {
        this.$toast.error(this.$i18n.t('Lütfen email adresinizi giriniz.'))
        document.getElementsByName('email')[0].focus()
      } else if (!password) {
        this.$toast.error(this.$i18n.t('Lütfen parolanızı giriniz.'))
        document.getElementsByName('password')[0].focus()
      } else {
        this.isLoading = true
        await this.axios.post('login', this.loginForm).then(response => {
          // dispatch user
          this.$store.dispatch('setUser', response.data.user)
          // set axios default header author  user.token
          this.$store.dispatch('setAxiosHeaderAuth', response.data.user.token)
          this.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.user.token
          if (response.data.user.tokens.length > 0) {
            this.$toast.success(this.$i18n.t('Bu cihazdan giriş yaptığınız için diğer oturumlarınız kapatıldı'))
          }
          this.$store.commit('setMyPrices', response.data.myPrices)
          this.$store.commit('setMyPricesList', response.data.myPricesList)
          this.updateMyPrices()
        }).catch(error => {
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            let m = ''
            for (const key in errors) {
              m += errors[key][0] + '<br>'
            }
            this.$toast.error(m)
          } else {
            this.$toast.error(error.response.data.message)
          }
          setTimeout(() => { this.isLoading = false }, 600)
        })
      }
    },
    updateMyPrices: function () {
      this.axios.get('my-prices').then(response => {
        this.isLoading = false
      }).then(
        this.$router.push({ name: 'ProfileSettings' })
      )
    },
    register: async function () {
      if (!this.active) {
        return
      }
      if (!this.registerForm.membership) {
        this.$toast.error('Lütfen Kullanıcı Sözleşmesini Kabul Edin')
        return
      }
      if (!this.registerForm.kvkk) {
        this.$toast.error('Lütfen KVKK Koşullarını Kabul Edin')
        return
      }
      this.isLoading = true
      await this.axios.post('register', this.registerForm).then(response => {
        this.$store.dispatch('setUser', response.data.user)
        // set axios default header author  user.token
        this.$store.dispatch('setAxiosHeaderAuth', response.data.user.token)
        this.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.user.token
        this.$router.push({ name: 'ProfileSettings' })
        setTimeout(() => { this.isLoading = false }, 600)
      }).catch(error => {
        if (error.response.status === 422) {
          const errors = error.response.data.errors
          let m = ''
          for (const key in errors) {
            m += errors[key][0] + '<br>'
          }
          this.$toast.error(m)
        } else {
          this.$toast.error(error.response.data.message)
        }
        setTimeout(() => { this.isLoading = false }, 600)
      })
    },
    logout: async function () {
      await this.axios.post('logout').then(response => {
        this.$store.dispatch('setUser', null)
        this.$store.dispatch('setAxiosHeaderAuth', '')
        this.axios.defaults.headers.common.Authorization = ''
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.From = (from.name)
    })
  }
}
</script>

<style scoped>
.sep {
  width: 1px;
  background: #ccc;
  height: 100%;
}
.loginContainer {
  width:908px;margin:50px auto 50px auto; display:grid;grid-template-columns: 1fr 1px 1fr;gap:50px;
}
@media screen and (max-width: 908px) {
  .loginContainer {
    width:calc(100% - 40px);
  }
}
</style>
