import { render, staticRenderFns } from "./PriceBoard2.vue?vue&type=template&id=13fb80ff&scoped=true&"
import script from "./PriceBoard2.vue?vue&type=script&lang=js&"
export * from "./PriceBoard2.vue?vue&type=script&lang=js&"
import style0 from "./PriceBoard2.vue?vue&type=style&index=0&lang=css&"
import style1 from "./PriceBoard2.vue?vue&type=style&index=1&id=13fb80ff&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13fb80ff",
  null
  
)

export default component.exports