import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
let cdn = 'https://api.gunceldemir.com/'
if (localStorage.getItem('isLocal') === 'true') {
  cdn = 'http://192.168.1.195:5000/'
}
export default new Vuex.Store({
  state: {
    siteInfo: {},
    info: {},
    cart: 0,
    user: null,
    permissions: [],
    bottomMenu: false,
    catMenu: false,
    mobileWidth: 769,
    width: window.innerWidth,
    height: document.body.scrollHeight,
    sort: 'new',
    showPriceSorter: false,
    pageSize: 24,
    priceWithVat: false,
    storeProducts: false,
    productsChanged: false,
    // fileCdn: 'https://www.kareiki.com/rentatech/files/',
    // cdn: process.env.VUE_APP_CDN,
    cdn: cdn,

    baseUrl: 'http://localhost:8080/assets/',
    hideBottom: false,
    cookieConsent: false,
    platform: 'web',
    currency: { kurCarpan: 1 },
    isLoaded: false,
    mainRoute: null,
    appTitle: 'Artkaf',
    appUrl: 'https://app.gunceldemir.com',
    showAds: false,
    newsDetail: {},
    articleDetail: {},
    axiosHeaderAuth: null,
    promoteToMembership: false,
    priceMode: 'normal',
    pushToken: null,
    allPrices: [],
    alphabet: [],
    seenPromo: localStorage.getItem('promo') ?? false,
    home: {
      sectorelNews: [],
      prices: [],
      economyNews: [],
      articles: [],
      topWriters: {},
      topCommenters: {}
    },
    commentBar: {
      title: 'Son Yorumlar',
      commentType: 1,
      contentId: 1,
      allTitle: 'Tüm Yorumlar',
      tabs: [
        { title: 'Sektörel', contentId: 1 },
        { title: 'Ekonomi', contentId: 2 },
        { title: 'Duyumlar', contentId: 3 }
      ]
    }
  },
  mutations: {
    setInfo (state, info) {
      state.info = info
    },
    setStoreProducts (state, products) {
      state.storeProducts = products
    },
    setProductsChanged (state, productsChanged) {
      state.productsChanged = productsChanged
    },
    setCommentBar (state, commentBar) {
      state.commentBar = commentBar
    },
    setUser (state, user) {
      state.user = user
    },
    setPermissions (state, permissions) {
      state.permissions = permissions
    },
    setHome (state, home) {
      state.home = home
    },
    setCart (state, cart) {
      state.cart = cart
    },
    setSort (state, sort) {
      state.sort = sort
    },
    setBottomMenu (state, bottomMenu) {
      state.bottomMenu = bottomMenu
    },
    setBottomHide (state, bottomMenu) {
      state.hideBottom = bottomMenu
    },
    setCatMenu (state, catMenu) {
      state.catMenu = catMenu
    },
    setSize (state, size) {
      state.width = size.width
      state.height = size.height
    },
    setCookieConsent (state, cookieConsent) {
      state.cookieConsent = cookieConsent
    },
    setPlatform (state, platform) {
      state.platform = platform
    },
    setCurrency (state, currency) {
      state.currency = currency
    },
    setLoaded (state, isLoaded) {
      state.isLoaded = isLoaded
    },
    setMainRoute (state, mainRoute) {
      state.mainRoute = mainRoute
    },
    setHomePrices (state, homePrices) {
      state.homePrices = homePrices
    },
    showAds (state, showAds) {
      state.showAds = showAds
    },
    setNewsDetail (state, newsDetail) {
      state.newsDetail = newsDetail
    },
    setArticleDetail (state, articleDetail) {
      state.articleDetail = articleDetail
    },
    setAxiosHeaderAuth (state, axiosHeaderAuth) {
      state.axiosHeaderAuth = axiosHeaderAuth
    },
    setSiteInfo (state, siteInfo) {
      state.siteInfo = siteInfo
    },
    setMyPrices (state, myPrices) {
      state.siteInfo.myPrices = myPrices
    },
    setMyPricesList (state, myPricesList) {
      state.home.myPrices = myPricesList
    },
    setImFollowing (state, imFollowing) {
      state.siteInfo.imFollowing = imFollowing
    },
    setPromoteToMembership (state, promoteToMembership) {
      state.promoteToMembership = promoteToMembership
    },
    setTopWriters (state, topWriters) {
      state.home.topWriters = topWriters
    },
    setTopCommenters (state, topCommenters) {
      state.home.topCommenters = topCommenters
    },
    setPriceMode (state, priceMode) {
      state.priceMode = priceMode
    },
    setPushToken (state, pushToken) {
      state.pushToken = pushToken
    },
    setAllPrices (state, allPrices) {
      state.allPrices = allPrices
    },
    setAlphabet (state, alphabet) {
      state.alphabet = alphabet
    }
  },
  actions: {
    setInfo (context, info) {
      context.commit('setInfo', info)
    },
    setStoreProducts (context, products) {
      context.commit('setStoreProducts', products)
    },
    setProductsChanged (context, productsChanged) {
      context.commit('setProductsChanged', productsChanged)
    },
    setCommentBar (context, commentBar) {
      context.commit('setCommentBar', commentBar)
    },
    setUser (context, user) {
      context.commit('setUser', user)
    },
    setPermissions (context, permissions) {
      context.commit('setPermissions', permissions)
      const canHideAds = permissions.find(permission => permission.key === 'can_hide_ads')
      if (canHideAds) {
        if (canHideAds.canDo) {
          this.dispatch('showAds', false)
        } else {
          this.dispatch('showAds', true)
        }
      } else {
        this.dispatch('showAds', false)
      }
    },
    setHome (context, home) {
      context.commit('setHome', home)
    },
    setCart (context, cart) {
      context.commit('setCart', cart)
    },
    setSort (context, sort) {
      context.commit('setSort', sort)
    },
    setBottomMenu (context, bottomMenu) {
      context.commit('setBottomMenu', bottomMenu)
    },
    setBottomHide (context, bottomMenu) {
      context.commit('setBottomHide', bottomMenu)
    },
    setCatMenu (context, catMenu) {
      context.commit('setCatMenu', catMenu)
    },
    setSize (context, size) {
      context.commit('setSize', size)
    },
    setCookieConsent (context, cookieConsent) {
      context.commit('setCookieConsent', cookieConsent)
    },
    setPlatform (context, platform) {
      context.commit('setPlatform', platform)
    },
    setCurrency (context, currency) {
      context.commit('setCurrency', currency)
    },
    setLoaded (context, isLoaded) {
      context.commit('setLoaded', isLoaded)
    },
    setMainRoute (context, mainRoute) {
      context.commit('setMainRoute', mainRoute)
    },
    setHomePrices (context, homePrices) {
      context.commit('setHomePrices', homePrices)
    },
    showAds (context, showAds) {
      context.commit('showAds', showAds)
    },
    setNewsDetail (context, newsDetail) {
      context.commit('setNewsDetail', newsDetail)
    },
    setArticleDetail (context, articleDetail) {
      context.commit('setArticleDetail', articleDetail)
    },
    setAxiosHeaderAuth (context, axiosHeaderAuth) {
      context.commit('setAxiosHeaderAuth', axiosHeaderAuth)
      localStorage.setItem('token', axiosHeaderAuth)
    },
    setSiteInfo (context, siteInfo) {
      context.commit('setSiteInfo', siteInfo)
    },
    setMyPrices (context, myPrices) {
      context.commit('setMyPrices', myPrices)
    },
    setMyPricesList (context, myPricesList) {
      context.commit('setMyPricesList', myPricesList)
    },
    setImFollowing (context, imFollowing) {
      context.commit('setImFollowing', imFollowing)
    },
    setPromoteToMembership (context, promoteToMembership) {
      context.commit('setPromoteToMembership', promoteToMembership)
    },
    setTopWriters (context, topWriters) {
      context.commit('setTopWriters', topWriters)
    },
    setTopCommenters (context, topCommenters) {
      context.commit('setTopCommenters', topCommenters)
    },
    setPriceMode (context, priceMode) {
      context.commit('setPriceMode', priceMode)
    },
    setPushToken (context, pushToken) {
      context.commit('setPushToken', pushToken)
    },
    setAllPrices (context, allPrices) {
      context.commit('setAllPrices', allPrices)
    },
    setAlphabet (context, alphabet) {
      context.commit('setAlphabet', alphabet)
    }
  }
})
