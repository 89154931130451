<template>
  <div>
    <div class="bg-navys" style="border-top: 1px solid #525252;">
      <footer class="bg-s container">
        <div class="content">
          <div class="links twitter">
            <Timeline v-if="$store.state.siteInfo.misc.twitterId" :id="$store.state.siteInfo.misc.twitterId" sourceType="profile" :options="{ tweetLimit: '4', theme: 'light', lang: 'tr' }"/>
          </div>
          <div class="mob">
            <h3>Bizi Takip Edin</h3>
            <div class="social">
              <ul>
                <li v-for="item,idx in $store.state.siteInfo.socials" :key="idx">
                  <a :href="item.url" target="_blank"><i :class="item.icon"></i><span>{{ item.title}}</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="reject" v-html="$store.state.siteInfo.misc.rejectText"></p>
      </footer>
      <div class="copy">
        <p v-html="$store.state.siteInfo.misc.metalAtilim"></p>
        <p class="right" v-html="$store.state.siteInfo.misc.kareiki"></p>
      </div>
      <FixedNavbar v-if="$store.state.width < 769" />
    </div>
  </div>
</template>

<script>
import FixedNavbar from '@/components/FixedNavbar'
import { Timeline } from 'vue-tweet-embed'

export default {
  components: { FixedNavbar, Timeline },
  props: ['info'],
  computed: {
    cdn () {
      return this.$store.state.cdn
    },
    platform () {
      return localStorage.getItem('platform')
    }
  }
}
</script>

<style scoped lang="scss">
.twitter {
  height:290px;
  border-radius:5px;
  overflow-y:scroll;
  overflow-x:hidden;
  // hide scrollbar
  -ms-overflow-style: none;
  // IE and Edge
  scrollbar-width: none;
  // webkit
  &::-webkit-scrollbar {
    display: none;
  }
}
.content{
  display: grid;
  grid-template-columns: 320px 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 20px;
  align-content: top;
  h3 {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(68, 68, 68);
  }
  ul {
    list-style: none;
    padding: 5px;
    li {
      margin-bottom: 8px;
      a {
        padding-top:8px;
        padding-bottom:8px;
        text-decoration: none;
      }
    }
  }
  a{
    font-size: 13px;
    text-decoration: none;
  }
  .social {
    margin-bottom: 12px;
    padding-top: 12px;
    ul {
      display: flex;
      gap: 20px;
      margin-top: 5px;
      justify-content: space-around;
    }
    a {
      padding: 3px;
      i {
        font-size: 24px;
      }
      span {
        display: none;
      }
    }
  }
  .apps {
    text-align: center;
    li {
      margin-bottom: 15px;
    }
  }
}
.reject {
  font-size: 11px;
  color: grey;
  line-height: 18px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.copy {
  background: #3b3b3b;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 11px;
  padding: 8px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
  .right {
    text-align: right;
  }
}
@media screen and (max-width: 820px) {
  .content{
    grid-template-columns: 1fr;
  }
  .apps {
    text-align: center;
  }
  .links {
    display: none;
  }
  h3 {
    display: none;
  }
  .copy{
    // padding-bottom: 62px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    text-align: center;
    place-items: center;
    align-items: center;
    padding-bottom: calc(env(safe-area-inset-bottom) + 38px);
  }
}
</style>
