<template>
  <div class="pageLayout">
    <LeftSide v-if="$store.state.width > 768" />
    <div class="pRight">
      <h1>{{ cat.name }}</h1>
      <div class="ql-editor" style="margin-bottom:20px" v-if="cat.description" v-html="$fnc.nl2br(cat.description)"></div>
      <div class="ql-editor" style="margin-bottom:20px" v-if="cat.content" v-html="cat.content"></div>
      <div class="itemsContainer">
        <div class="item" v-for="item in items" :key="item.id">
          <router-link :to="{ name: 'PageDetail', params: { type: 'p', slug: item.slug } }">{{ item.title }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSide from '@/small-components/LeftSide'
export default {
  props: ['page', 'hideTitle'],
  components: { LeftSide },
  data: function () {
    return {
      cdn: this.$store.state.cdn,
      cat: {},
      items: []
    }
  },
  mounted: function () {
    // this.getDictionary()
    this.getItems()
  },
  watch: {
    $route: function () {
      this.getItems()
    }
  },
  methods: {
    getItems: async function () {
      const slug = this.$route.params.slug
      await this.axios.get('pages-by-category/' + slug).then(response => {
        this.cat = response.data.cat
        this.items = response.data.items
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.itemsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.item {
  padding: 0;
  margin:0;
  background:rgba($color: #fff, $alpha: 0.04);
  border-radius: 8px;
  a {
    padding: 12px;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    text-align: center;
  }
  .answer {
    line-height: 32px;
    .ql-editor {
      p {
        line-height: 24px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .itemsContainer {
    grid-template-columns: 1fr;
  }
}
</style>
