<template>
  <div>
    <div v-if="user.is_author">
      <div v-if="!composer">
        <div style="display:flex; justify-content:space-between">
          <h2 style="font-weight:normal">{{ $t('Analizlerim') }}</h2>
          <button class="btn1 c1" @click="showComposer('open')">{{ $t('Yeni Analiz')}}</button>
        </div>
        <div class="posts">
          <div v-if="myPosts" style="display:flex;flex-direction:column; justify-content:space-between; min-height:540px;">
            <table class="tabsle1" style="font-size:12px;width:100%; margin-top:10px">
              <thead>
                <tr>
                  <td width="72">Görsel</td>
                  <td width="80">Tarih</td>
                  <td>Analiz</td>
                  <td width="30" class="center" v-tooltip="'Yorumlar'"><i class="fa fa-comments"></i></td>
                  <td width="30" class="center" v-tooltip="'Görüntülenme'"><i class="fa fa-eye"></i></td>
                  <td width="90" class="center">İşlemler</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in myPosts" :key="item.id">
                  <td>
                    <div v-if="item.cover_image">
                      <img style="border-radius:4px"
                      v-tooltip="'<img src=' + cdn + JSON.parse(item.image.media).small + '>'"
                      loading="lazy" width="64" :src="cdn + JSON.parse(item.image.media).thumb" />
                    </div>
                  </td>
                  <td>{{ $fnc.date(item.created_at) }}</td>
                  <td>{{ item.title }}<br>{{ item.description }}</td>
                  <td class="center">{{ item.all_comments_count }}</td>
                  <td class="center">{{ item.view_count }}</td>
                  <td>
                    <div style="display:flex; jutify-items:space-around">
                      <div class="btn1" v-if="item.published_at" v-tooltip="'Yayında'"><i class="fa fa-check teal"></i></div>
                      <div class="btn1" v-else  v-tooltip="'Henüz Yayınlanmamış'"><i class="fa fa-check grey"></i></div>
                      <button class="btn1" @click="editPost(item)"><i class="fa fa-edit"></i></button>
                      <button class="btn1" @click="deletePost(item)" v-if="item.can_be_deleted"><i class="fa fa-trash-alt"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="tableFooter">
              <div>
                <b>{{ last_page }}</b> sayfada Toplam <b>{{ total }}</b> kayıt var
              </div>
              <ul class="pager">
                <li v-for="item,idx in links" :key="idx">
                  <button @click="getPage(item.label)" class="c1" :class="{ activeButton: parseInt(item.label) === parseInt(activePage) }"
                    v-html="item.label">
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <form class="content" @submit.prevent="savePost" method="dialog">
          <div class="row">
            <label>
              <i class="fa fa-info" v-tooltip="$t('65 Karakteri geçmemeye çalışın')"></i>
              {{ $t('Analiz Başlığı') }} <span class="red">*</span></label>
            <input type="text" v-model="post.title" :placeholder="$t('Analiz Başlığı')">
          </div>
          <div class="row">
            <label>
              <i class="fa fa-info" v-tooltip="$t('1 Mb tan büyük olmamalıdır')"></i>
              {{ $t('Kapak Resmi') }}</label>
              <input @change="uploadFile" ref="file" type="file" accept="image/png, image/jpeg, image/gif" :placeholder="$t('Kapak Resmi')" >
          </div>
          <div class="row">
            <label>
              <i class="fa fa-info" v-tooltip="$t('Boş bırakırsanız otomatik oluşur')"></i>
              {{ $t('Url') }}</label>
            <input type="text" v-model="post.slug" :placeholder="$t('Url')">
          </div>
          <div class="row">
            <label>
              <i class="fa fa-info" v-tooltip="$t('Analizinizin kısa bir özetini buraya yazın')"></i>
              {{ $t('Kısa Açıklama') }} <span class="red">*</span></label>
            <textarea rows="5" v-model="post.description" :placeholder="$t('Kısa açıklama')"></textarea>
          </div>
          <div class="row">
            <label>{{ $t('İçerik') }} <span class="red">*</span></label>
            <VueEditor v-model="post.content" :editor-toolbar="editorToolbar" style="background:#fff;color:black"></VueEditor>
          </div>
          <div class="row" style="display:flex; gap: 12px;">
            <button class="btn1 c2" @click="closeComposer">{{ $t('Vazgeç') }}</button>
            <button class="btn1 c1" type="submit">{{ $t('Kaydet') }}</button>
          </div>
        </form>
      </div>
    </div>
    <GlassModal v-if="confirmModal" @close="closeConfirm" style="color:black">
      <template slot="title">{{ $t('Silme Onayı') }}</template>
      <template slot="body">
        <div class="row">
          <div class="col-12" style="display:flex; gap:12px">
            <button class="btn1 c1" @click="deletePostConfirmed">{{ $t('Sil') }}</button>
            <button class="btn1 c2" @click="closeConfirm">{{ $t('Vazgeç') }}</button>
          </div>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.user
    },
    cdn () {
      return this.$store.state.cdn
    }
  },
  data () {
    return {
      post: {
        id: 0,
        title: '',
        slug: '',
        description: '',
        content: ''
      },
      myPosts: [],
      links: [],
      total: 0,
      last_page: 0,
      composer: false,
      image: '',
      activePage: 1,
      confirmModal: false,
      editorToolbar: [
        [{ header: 2 }],
        ['bold', 'italic', 'underline'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['image', 'link'],
        ['clean']
      ]
    }
  },
  mounted () {
    this.getPosts()
  },
  methods: {
    editPost: function (post) {
      this.post = post
      this.composer = true
    },
    uploadFile: function () {
      this.image = this.$refs.file.files
    },
    showComposer: function (type) {
      if (type === 'open') {
        this.composer = true
      } else {
        this.composer = false
      }
    },
    newPost: function () {
      this.composer = true
    },
    deletePost: function (item) {
      this.confirmModal = item.id
    },
    deletePostConfirmed: async function () {
      const id = this.confirmModal
      if (!id) {
        return
      }
      await this.axios.delete('/posts/' + id).then(response => {
        this.getPosts()
        this.confirmModal = false
      }).catch(error => {
        this.$toast.error(error.response.data.message)
      })
    },
    closeConfirm: function () {
      this.confirmModal = false
    },
    savePost: async function () {
      let image = ''
      if (this.image) {
        image = this.image[0]
      }
      const payload = {
        id: this.post.id ?? '',
        title: this.post.title,
        slug: this.post.slug,
        description: this.post.description,
        content: this.post.content,
        cover: image
      }
      const formData = new FormData()
      for (const key in payload) {
        formData.append(key, payload[key])
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      let url = ''
      if (this.post.id) {
        url = '/update-post'
      } else {
        url = '/new-post'
      }
      await this.axios.post(url, formData, config).then(response => {
        this.closeComposer()
        this.getPosts()
      }).catch(error => {
        this.$toast.error(error.response.data.message)
      })
    },
    closeComposer () {
      this.post = {
        title: '',
        slug: '',
        description: '',
        content: ''
      }
      this.image = ''
      this.composer = false
    },
    getPage: function (page) {
      if (page === '&raquo;') {
        page = this.activePage + 1
      } else if (page === '&laquo;') {
        page = this.activePage - 1
      }
      if (page > 0 && page <= this.last_page) {
        this.activePage = page
        this.getPosts()
      }
    },
    getPosts: async function () {
      await this.axios.get('my-posts?page=' + this.activePage).then(response => {
        this.myPosts = response.data.data
        this.links = response.data.links
        this.total = response.data.total
        this.last_page = response.data.last_page
      })
    },
    getPost: async function (id) {
      await this.axios.get('post/' + id).then(response => {
        this.post = response.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.activeButton, .activeButton:hover {
  background: #f5f5f5;
  color: black !important;
}
td {
  padding: 5px;
  border-bottom: 1px solid #666;
}
.content {
  color:black; background:#f5f5f5;padding:10px;border-radius:4px;
  .row label {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #666;
  }
  .row label i {
    width:14px;
    height: 14px;
    text-align: center;
    border-radius: 10px;
    background: #666;
    color: #fff;
    display: grid;
    place-items: center;
    font-size: 8px;
  }
  .row textarea, input {
    background: #fff;
    border: 1px solid #ccc;
    color: #666;
    padding: 10px;
    font-family: var(--font1);
  }
}
.quillWrapper{
  .ql-toolbar{
    font-size: 11px;
    span {
      button {
        font-size: 11px;
        width: 20px;
        svg {
          width: 16px !important;
          height: 18px;
        }
      }
    }
  }
}
</style>
