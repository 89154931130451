<template>
  <div>
    <ProfileCardSelf />
    <ul class="sideMenu">
      <li v-for="item in routes" :key="item.name">
        <router-link :to="{ name: item.name }">{{ item.title }}</router-link>
      </li>
      <li class="permissions">
        <p>
          <span>SMS Bildirimi</span>
          <button @click="savePermissions('permission_sms')">
            <i v-if="user.permission_sms" class="fa fa-toggle-on teal"></i>
            <i v-else class="fa fa-toggle-off"></i>
          </button>
        </p>
        <p>
          <span>Mobil Bildirimler</span>
          <button @click="savePermissions('permission_push')">
            <i v-if="user.permission_push" class="fas fa-toggle-on teal"></i>
            <i v-else class="fa fa-toggle-off"></i>
          </button>
        </p>
        <p>
          <span>Mail Bildirimi</span>
          <button @click="savePermissions('permission_email')">
            <i v-if="user.permission_email" class="fas fa-toggle-on teal"></i>
            <i v-else class="fa fa-toggle-off"></i>
          </button>
        </p>
      </li>
      <li>
        <a @click="logout">{{ $i18n.t('Log Out') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import ProfileCardSelf from './ProfileCardSelf.vue'
export default {
  components: {
    ProfileCardSelf
  },
  data () {
    return {
      routes: [
        {
          title: this.$i18n.t('Profil Ayarlarım'),
          name: 'ProfileSettings'
        },
        {
          title: this.$i18n.t('Bildirimlerim'),
          name: 'ProfileNotifications'
        },
        {
          title: this.$i18n.t('Comments'),
          name: 'ProfileComments'
        },
        {
          title: this.$i18n.t('Takipçilerim'),
          name: 'ProfileFollowers'
        },
        {
          title: this.$i18n.t('Takip Ettiklerim'),
          name: 'ProfileFollowing'
        },
        {
          title: this.$i18n.t('Parolanızı Değiştirin'),
          name: 'ProfileChangePassword'
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.user.is_author) {
        const item = {
          title: this.$i18n.t('Analizlerim'),
          name: 'ProfilePosts'
        }
        const arr = this.routes
        arr.splice(1, 0, item)
      }
      if (this.user.is_merchant) {
        const item = {
          title: this.$i18n.t('Fiyat Girişi'),
          name: 'ProfilePriceEntry'
        }
        const arr = this.routes
        arr.splice(1, 0, item)
      }
    }, 0)
  },
  methods: {
    savePermissions: async function (k) {
      this.user[k] = !this.user[k]
      const payload = {
        permission_sms: this.user.permission_sms,
        permission_push: this.user.permission_push,
        permission_email: this.user.permission_email
      }
      await this.axios.post('/save-user-permissions/' + this.user.id, payload).then(response => {
        this.$toast.success(this.$t('Tercihleriniz güncellendi'))
        this.$store.commit('setUser', response.data.user)
      })
    },
    logout: async function () {
      await this.axios.post('logout').then(response => {
        this.$store.commit('setMyPricesList', [])
        this.$store.commit('setMyPrices', [])
        this.$store.dispatch('setUser', null)
        this.$store.dispatch('setAxiosHeaderAuth', '')
        this.axios.defaults.headers.common.Authorization = ''
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.permissions {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #525252;
  p {
    font-size: 13px;
    padding: 8px;
    color: #ccc;
    display: grid;
    grid-template-columns: auto 30px;
    align-items: center;
    button {
      background:none;
      border: none;
      color: #ccc;
    }
    i {
      font-size: 20px;
    }
  }
}
.sideMenu {
  list-style: none;
  margin-top: 18px;
  li{
    a{
      font-size: 13px;
      display: block;
      padding: 8px;
      border-bottom: 1px solid #525252;
    }
    .active {
      color: var(--primary1);
      border-bottom: 1px solid var(--primary1);;
    }
  }
  li:last-child a {
    border-bottom: none;
  }
}
</style>
