<template>
  <div class="container" style="padding:10px;">
    <Harita style="max-width:100%;margin-bottom:20px;" />
    <div class="cardConteiner" style="display:grid; gap: 20px">
      <YoutubeCard :vid="'dxAW6pZtpwc'" :title="'Bitmesin sabaha kadar, yakmıyor artık bu kaynar sular'" />
      <YoutubeCard :vid="'https://www.youtube.com/watch?v=f9yMRqaTiGY&ab_channel=EnisKirazoglu'" :title="'Bitmesin sabaha kadar, yakmıyor artık bu kaynar sular'" />
      <Card
      :to="''"
      :external="'https://google.com'"
      :image="'https://imgrosetta.mynet.com.tr/file/14465580/14465580-728xauto.jpg'"
      :title="'2022 Eurovision Şarkı Yarışmasının kazananı belli oldu'"
      :body="'<p>Son dakika haberi: Eurovision Şarkı Yarışmasının kazananı belli oldu.</p><p>Bu yıl 66ncısı düzenlenen yarışmanın kazananı Ukrayna oldu. İtalya da gerçekleşen ve 41 ülkenin katıldığı Eurovision Şarkı Yarışması (Eurovision 2022) 45 ülkeden naklen yayınlandı. İşte son dakika haberinin detayları...</p>'"
      />
      <YoutubeCard :vid="'https://www.youtube.com/watch?v=1AsTd65B2HQ'" :title="'Bitmesin sabaha kadar, yakmıyor artık bu kaynar sular'" />
      <Card
      :to="{ name: 'Login' }"
      :image="'https://imgrosetta.mynet.com.tr/file/14467588/14467588-640x480.jpg'"
      :title="'2022 Eurovision Şarkı Yarışmasının kazananı belli oldu'"
      :body="'<p>Bu yıl 66ncısı düzenlenen yarışmanın kazananı Ukrayna oldu. İtalya da gerçekleşen ve 41 ülkenin katıldığı Eurovision Şarkı Yarışması (Eurovision 2022) 45 ülkeden naklen yayınlandı. İşte son dakika haberinin detayları...</p>'"
      />
      <Card
      :to="'/tr/uye-olun'"
      :image="'https://i.ytimg.com/vi/tasIFLICZ8E/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC26TGYUIlNx-ur9js5MtBTGWn5fw'"
      :title="'They have the same Voice actor'"
      :body="'Greetings humans my name is Dr Lamington. You should subscribe for more epic videos like this. I post video edits and other comedic things.'"
      />
      <Card :image="'https://imgrosetta.mynet.com.tr/file/14467588/14467588-640x480.jpg'"
      :to="''"
      :title="'2022 Eurovision Şarkı Yarışmasının kazananı belli oldu'"
      :body="'<p>Son dakika haberi: Eurovision Şarkı Yarışmasının kazananı belli oldu.</p><p>Bu yıl 66ncısı düzenlenen yarışmanın kazananı Ukrayna oldu. İtalya da gerçekleşen ve 41 ülkenin katıldığı Eurovision Şarkı Yarışması (Eurovision 2022) 45 ülkeden naklen yayınlandı. İşte son dakika haberinin detayları...</p>'"
      />
    </div>
  </div>
</template>

<script>
import Harita from '@/components/Harita'
export default {
  components: {
    Harita
  },
  data () {
    return {
      mainTab: 'priceList',
      enableSort: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  beforeMount () {
    this.getHome()
  },
  methods: {
    registerPush: function () {
      this.$root.registerPush()
    },
    getHome: async function () {
      await this.axios.get('home').then(response => {
        this.$store.dispatch('setHome', response.data)
        if (this.user) {
          if (this.$store.state.home.myPrices[0].children_latest_prices.length > 0) {
            this.setMainTab('myPriceList', true)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.mainNews {
  display: grid;
  grid-template-columns: 11fr 9fr;
  gap: 20px;
}
.banner {
  width: 930px;
  height: 180px;
  margin: auto;
  background-size: cover !important;
}
.firstBanner {
  height: 200px;
}
.mainLayout {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .homeLayout, .mainLayout {
    grid-template-columns: 1fr;
  }
  .firstBanner {
    display:none;
  }
}
</style>
