<template>
  <div class="card">
    <Youtube style="margin-bottom: 13px;" :vid="videoId" :params="params" />
    <div class="card-header" v-if="title">
      <i class="fab fa-youtube" style="color:red"></i>
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
import Youtube from '@miyaoka/vue-youtube-embed-lite'
export default {
  components: { Youtube },
  props: ['title', 'vid'],
  computed: {
    videoId () {
      // parse youtube string
      if (this.vid.length === 11) {
        return this.vid
      } else {
        return this.vid.split('v=')[1].split('&')[0]
      }
    }
  },
  data () {
    return {
      params: {
        autoplay: 1,
        controls: 1,
        showinfo: 0,
        modestbranding: 1,
        rel: 0,
        loop: false
      }
    }
  }
}
</script>
