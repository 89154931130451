<template>
  <transition name="modal-fade">
    <div class="modalBg" @click.self="close">
      <div class="modalG" :style="'max-width: ' + width" :class="{ fullPage: fullPage }">
        <button :title="$t('Kapat')" @click="close" v-if="!hideClose" class="closeBtn"><i class="fa fa-times"></i></button>
        <div class="modalContent">
          <div method="dialog" autocomplete="never">
            <h3><slot name="title"></slot></h3>
            <slot name="body"></slot>
            <div v-if="showLogin">
              <form method="dialog" v-if="!user" style="width:75%; margin:auto;padding-bottom: 20px">
                <div class="row">
                  <label>{{ $t('E-Posta Adresiniz') }}</label>
                  <input @keypress.enter="login" type="email" v-model="loginForm.email" :placeholder="$t('E-Posta Adresiniz')" />
                </div>
                <div class="row">
                  <label>Parolanız</label>
                  <input @keypress.enter="login" type="password" v-model="loginForm.password" :placeholder="$t('Parolanız')" />
                </div>
                <div class="row">
                  <button v-if="!isLoading" class="btn1 c1" @click="login" type="button"><i class="fa fa-lock"></i>{{ $t('Giriş Yapın') }}</button>
                  <button v-else class="btn1 c1" type="button"><span class="inlineLoading"></span>{{ $t('Lütfen Bekleyin') }}</button>
                </div>
                <div class="row center grey">{{ $t('or') }}</div>
                <div class="row social">
                  <AppleLogin :appleClass="'apple'" />
                </div>
                <div class="row2">
                  {{ $t('Hesabın yok mu?') }} <router-link :to="{ name: 'Register' }">{{ $t('Kayıt Olun') }}</router-link><br><br>
                  <router-link :to="{ name: 'ForgetPassword' }">{{ $t('Parolamı Unuttum') }}</router-link>
                </div>
              </form>
              <div v-else>
                Memberhips are available for members of the following companies:
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AppleLogin from '@/components/AppleLoginFire'
export default {
  props: ['hideClose', 'fullPage', 'showLogin', 'width'],
  components: {
    AppleLogin
  },
  data () {
    return {
      isLoading: false,
      formError: '',
      platform: localStorage.getItem('platform'),
      loginForm: {
        email: '',
        password: ''
      },
      active: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    login: async function () {
      if (!this.active) {
        return
      }
      this.isLoading = true
      await this.axios.post('login', this.loginForm).then(response => {
        this.$store.dispatch('setUser', response.data.user)
        this.$store.dispatch('setAxiosHeaderAuth', response.data.user.token)
        this.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.user.token
        if (response.data.user.tokens.length > 0) {
          this.$toast.success(this.$i18n.t('Bu cihazdan giriş yaptığınız için diğer oturumlarınız kapatıldı'))
        }
        this.$store.commit('setMyPrices', response.data.myPrices)
        this.$store.commit('setMyPricesList', response.data.myPricesList)
        setTimeout(() => { this.isLoading = false }, 600)
        this.close()
      }).catch(error => {
        if (error.response.status === 422) {
          const errors = error.response.data.errors
          let m = ''
          for (const key in errors) {
            m += errors[key][0] + '<br>'
          }
          this.$toast.error(m)
        } else {
          this.$toast.error(error.response.data.message)
        }
        setTimeout(() => { this.isLoading = false }, 600)
        // this.close()
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
a {
  color:teal;
  font-weight: 600;
  text-decoration: underline;
}
a:hover {
  color:orangered;
}
.row2 {
  margin-top: 20px;
  font-size: 14px;
  color: #999;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.sep {
  width: 1px;
  background: #ccc;
  height: 100%;
}
.row {
  color: black;
  margin-bottom: 12px;
  label {
    color: #666;
    text-align: left;
    font-size: 11px;
    margin-bottom: 0px;
  }
  input {
    border-radius: 7px;
    border-color: #ccc;
    color: #666;
  }
}
h3 {
  display:grid;
  text-align: center;
  width: 100%;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
}
p {
  margin-bottom: 12px;
}
.closeBtn {
  border:none;
  background: none;
  color:#666;
  width:30px;
  height:30px;
  font-size:16px;
  font-weight: normal;
  display:grid;
  place-items:center;
  float:right;
  font-family: var(--font3);
}
.closeBtn:hover {
  color: black;
}

.modalBg {
  font-family: var(--font3);
  user-select: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  display: grid;
  grid-template-rows: 3fr 1fr;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  .modalG {
    text-align:center;
    font-size: 14px;
    position: unset;
    top: 0;
    border-radius: 8px;
    background:#fff;
    width: calc(100vw - 20px);
    max-width: 400px;
    .modalContent {
      background: unset;
      padding: 12px;
      margin-top: 12px;
    }
  }
  .fullPage {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    .modalContent{
      padding: 0;
      height: 100%;
    }
  }
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
  transform:scale(1.2) translateY(3%);
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all .10s ease;
}
</style>
