<template>
  <div>
    <div class="error">
      <div v-if="message" v-html="message"></div>
      <div v-else>Bu işlemi yapmak için yetkiniz yok</div>
      <router-link v-if="user" :to="{ name: 'MemberShip' }" class="btn1 c8" style="">
        <i class="fa fa-shield-alt"></i>
        {{ $t('Üyelik Paketleri') }}
      </router-link>
      <a @click.prevent="showLogin" v-else class="btn1 c8" style="">
        <i class="fa fa-shield-alt"></i>
        {{ $t('Giriş Yapın') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message'],
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    showLogin () {
      const data = {
        title: 'Giriş Yapın',
        message: '',
        showLogin: true
      }
      this.$store.commit('setPromoteToMembership', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
  border: 1px solid red;
  background: rgba($color: #ff0000, $alpha: 0.2);
  border-radius: 6px;
  margin: 14px;
  text-align: center;
}
.c8 {
  background-color: goldenrod;
  text-align:center;
  margin: auto;
  margin-top: 10px;
  place-items:center; display:flex; justify-content:center;font-size:14px;
  width: 200px;
  color: white;
}
.c8:hover {
  background-color: rgb(232, 178, 43);
  text-align:center;
}
</style>
