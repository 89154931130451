<template>
  <div>
    <div class="profileCard">
      <div class="avatar">
        <img v-if="info.image" :src="cdn + JSON.parse(info.image.media).thumb">
        <img v-else :src="cdn + 'default.png'">
      </div>
      <div class="user">
        <h3>@{{ info.nick }}</h3>
      </div>
      <div class="actions" v-if="user">
        <div v-if="info.id !== user.id">
          <button class="btn1 c1" style="width:70px" v-if="!inList" @click="follow(info, '')">{{ $t('Follow')}}</button>
          <button class="btn1 c2" style="width:70px" v-else @click="follow(info, 'askFirst')" @mouseover="textChange('Takibi Bırak')" @mouseleave="textChange('Takip Ediliyor')">{{ $t(followText) }}</button>
        </div>
      </div>
      <div class="actions" v-else>
        <button class="btn1 c1" @click="follow(info, '')">{{ $t('Follow')}}</button>
      </div>
      <GlassModal v-if="confirm" @close="closeConfirm" style="color:black">
        <template v-slot:title>@{{ info.nick }} kullanıcısını takpiten çıkıllsın mı?</template>
        <template v-slot:body>
          <p>Artık bu kullanıcının yaptığı yorumlarda bildirim almayacaksınız</p>
          <div style="display:flex; gap:12px;margin-top:10px; padding-top:10px; border-top:1px solid #ccc">
            <button class="btn1 c1" @click="follow(info)">{{ $t('Takipten Çık') }}</button>
            <button class="btn1 c2" @click="closeConfirm">{{ $t('Vazgeç') }}</button>
          </div>
        </template>
      </GlassModal>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info', 'tip'],
  data () {
    return {
      followText: 'Takip Ediliyor',
      confirm: false
    }
  },
  computed: {
    cdn () {
      return this.$store.state.cdn
    },
    user () {
      return this.$store.state.user
    },
    inList () {
      // if in array
      if (this.$store.state.siteInfo.imFollowing.find(item => item === this.info.id)) {
        return true
      }
      return false
    }
  },
  methods: {
    closeConfirm: function () {
      this.confirm = false
    },
    follow: function (info, askFirst) {
      let permission = false
      if (this.tip === 'writer') {
        permission = this.$store.state.permissions.find(item => item.key === 'can_follow_writers')
      } else {
        permission = this.$store.state.permissions.find(item => item.key === 'can_follow_users')
      }
      if (!permission.canDo) {
        const data = {
          title: 'Takip Et',
          message: permission.reject_message,
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
        return
      }
      if (askFirst === 'askFirst') {
        this.confirm = 'wdw'
        // this.confirmFollow(info)
      } else {
        this.confirmFollow(info)
      }
    },
    confirmFollow: async function (info) {
      await this.axios.post('follow', info).then(response => {
        this.$store.commit('setImFollowing', response.data)
        if (this.confirm) {
          this.confirm = false
        }
      })
    },
    textChange (text) {
      this.followText = text
    },
    fakeFollow () {
      const data = {
        title: 'Takip Et',
        message: 'Bu kişiyi takip etmek için üye olmalısınız',
        showLogin: true
      }
      this.$store.commit('setPromoteToMembership', data)
    }
  }
}
</script>

<style scoped lang="scss">
.profileCard {
  display: grid;
  grid-template-columns: 60px auto 80px;
  gap: 4px;
  margin-bottom: 8px;
  align-items: center;
  padding: 11px;
  border-radius: 12px;
  background: rgba($color: #fff, $alpha: 0.01);;
}
.profileCard:hover {
  background: rgba($color: #fff, $alpha: 0.06);;
}
.avatar {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  overflow: hidden;
  border: 2px solid rgba($color: #fff, $alpha: 0.5);
  img {
    width: 100%;
  }
}
h3 {
  color: azure;
}
.info {
  margin-top: 4px;
  font-size: 11px;
  color: #999;
  display: flex;
  gap: 6px;
}
.actions {
  display: grid;
  justify-items: right;
}
</style>
