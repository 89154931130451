<template>
  <div>
    <div v-if="user">
      <h2 style="font-weight:normal">{{ $t('Takip Ettiklerim') }}</h2>
      <div v-if="isLoaded" style="display:flex;flex-direction:column; justify-content:space-between; min-height:540px;">
        <div>
          <ProfileCard v-for="item in people" :key="item.id" :info="item.follower" />
        </div>
        <div class="tableFooter">
          <div>
            <b>{{ last_page }}</b> sayfada Toplam <b>{{ total }}</b> kayıt var
          </div>
          <ul class="pager">
            <li v-for="item,idx in links" :key="idx">
              <button @click="getPage(item.label)" class="c1" :class="{ activeButton: parseInt(item.label) === parseInt(activePage) }"
                v-html="item.label">
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.activeButton, .activeButton:hover {
  background: #f5f5f5;
  color: black !important;
}
</style>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  data () {
    return {
      people: [],
      isLoaded: false,
      links: [],
      total: 0,
      last_page: 0,
      activePage: 1
    }
  },
  mounted () {
    this.getFollowings()
  },
  methods: {
    getPage: function (page) {
      if (page === '&raquo;') {
        page = this.activePage + 1
      } else if (page === '&laquo;') {
        page = this.activePage - 1
      }
      if (page > 0 && page <= this.last_page) {
        this.activePage = page
        this.getFollowings()
      }
    },
    getFollowings: async function () {
      this.axios.get('im-following?page=' + this.activePage).then(response => {
        this.people = response.data.data
        this.links = response.data.links
        this.total = response.data.total
        this.last_page = response.data.last_page
        this.isLoaded = true
      })
    }
  }
}
</script>
