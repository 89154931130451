<template>
  <div>
    <div v-if="$store.state.width > 768">
      <div class="hero">
        <div></div>
        <div class="overlay" :style="'height: ' + heroHeight" :title="news.title">
          <img v-if="news.cover_image" :src="cdn + JSON.parse(news.image.media).medium" :title="news.title" :alt="news.title" />
        </div>
      </div>
      <div class="container">
        <div class="heroCaption" :style="'height:' + heroHeight +'; margin-top:-' + heroHeight +';'">
          <div class="content">
            <h1>{{ news.title }}</h1>
            <h2>{{ news.description }}</h2>
            <p v-if="news.date">{{ news.date }}</p>
            <p v-else>{{ news.created_at }}</p>
            <div class="shareButtons" v-if="news">
                <button @click="scrollToComments"><i class="fa fa-comment-alt"></i><span>Yorum Yap</span></button>
                <ShareNetwork
                  network="facebook"
                  :url="url"
                  :title="news.title"
                  :description="news.description"
                  :media="cdn + JSON.parse(news.image.media).medium"
                  hashtags="'Artkaf'"
                >
                  <button><i class="fab fa-facebook"></i></button>
                </ShareNetwork>
                <ShareNetwork
                  network="twitter"
                  :url="url"
                  :title="news.title"
                  :description="news.description"
                  :media="cdn + JSON.parse(news.image.media).medium"
                  hashtags="günceldemir"
                >
                  <button><i class="fab fa-twitter"></i></button>
                </ShareNetwork>
                <ShareNetwork
                  network="linkedin"
                  :url="url"
                  :title="news.title"
                  :description="news.description"
                  :media="cdn + JSON.parse(news.image.media).medium"
                  hashtags="günceldemir"
                >
                  <button><i class="fab fa-linkedin"></i></button>
                </ShareNetwork>
                <ShareNetwork
                  network="whatsapp"
                  :url="url"
                  :title="news.title"
                  :description="news.description"
                  :media="cdn + JSON.parse(news.image.media).medium"
                  hashtags="günceldemir"
                >
                  <button><i class="fab fa-whatsapp"></i></button>
                </ShareNetwork>
              </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="homeLayout" style="margin-top:20px">
          <div class="left">
            <Promo :type="'leaderboard'" />
            <div class="newsDetail">
              <div style="width:100%">
                <div v-if="!loading">
                  <BreadCrumb :info="news.bread_crumb" :jsonld="news.Bcjsonld" />
                  <div class="ql-editor" v-html="news.content" v-if="!news.cantSee"></div>
                  <MemberError v-else :message="news.errorMessage" />
                </div>
                <LoadingPlaceholder class="htmlContent" :pCount="15" v-else />
                <Promo :type="'leaderboard'" />
                <CommentBar ref="comments" v-if="commentBar && !news.cantSee" :info="commentBar" :imageSize="'medium'"  />
              </div>
            </div>
          </div>
          <div class="homeSide">
            <Promo :type="'medium-rectangle'" />
            <CommentBar v-if="$store.state.commentBar" :info="$store.state.commentBar" />
            <Promo :type="'medium-rectangle'" />
            <div class="caption">
              <h2>Son Haberler</h2>
            </div>
            <NewsCard2 :DetailRoute="'NewsDetail'" :noPrefetch="true" v-for="item in news.latest" :key="item.id" :info="item" />
            <Promo :type="'medium-rectangle'" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="news">
        <div style="">
          <Promo :type="'mobile-leaderboard'" />
          <div class="imgContainerMobile">
            <img v-if="news.cover_image" :src="cdn + JSON.parse(news.image.media).small" :title="news.title" :alt="news.title" />
            <img v-else :src="cdn + JSON.parse(news.image.media).small" :title="news.title" :alt="news.title" />
            <div class="grad"></div>
          </div>
          <div class="heroMobile">
            <BreadCrumb style="margin-bottom:-8px;" :info="news.bread_crumb" :jsonld="news.Bcjsonld" />
            <h1>{{ news.title }}</h1>
            <div class="flex1" style="font-size:11px;margin-top:18px">
              <p v-if="news.date">{{ news.date }}</p>
              <p v-else>{{ news.created_at }}</p>
              <p v-if="news.author"><i class="fa fa-feather"></i> {{ news.author.name }}</p>
              <p><i class="fa fa-eye"></i> {{ news.view_count }}</p>
              <p><i class="fa fa-comments"></i> {{ news.comments_count }}</p>
              <button @click="share" class="btn2 c3"><i class="fa fa-share"></i> Paylaş</button>
            </div>
            <div style="margin-top:12px;margin-bottom:12px; line-height:1.5; border-top:1px solid black; padding-top: 12px">{{ news.description }}</div>
            <Promo :type="'medium-rectangle'" style="margin-bottom: 12px" />
            <div class="ql-editor" v-html="news.content" v-if="!news.cantSee"></div>
            <MemberError v-else :message="news.errorMessage" />
          </div>
        </div>
        <CommentBar style="padding: 14px" ref="comments" v-if="commentBar && !news.cantSee" :info="commentBar" :imageSize="'medium'"  />
      </div>
    </div>
  </div>
</template>

<script>
const path = window.location.pathname
export default {
  data () {
    return {
      heroHeight: '330px',
      cdn: this.$store.state.cdn,
      loading: false,
      commentBar: null,
      url: this.$store.state.appUrl + path
    }
  },
  mounted () {
    this.getNewsDetail()
  },
  watch: {
    '$route' (to, from) {
      this.getNewsDetail()
    }
  },
  beforeDestroy () {
    this.$store.dispatch('setNewsDetail', {})
  },
  computed: {
    news () {
      return this.$store.state.articleDetail
    }
  },
  methods: {
    share: function () {
      setTimeout(() => {
        this.$root.share(this.news.title, this.news.description, this.url, 'Paylaş')
      }, 200)
    },
    scrollToComments: function () {
      const el = this.$refs.comments
      this.$nextTick(() => {
        el.$el.scrollIntoView(
          { behavior: 'smooth', block: 'start' }
        )
      })
    },
    getNewsDetail: async function () {
      const slug = this.$route.params.slug
      this.loading = true
      await this.axios.get('/article-detail/' + slug).then(response => {
        setTimeout(() => {
          this.$store.dispatch('setArticleDetail', response.data)
          this.commentBar = {
            title: 'Yorumlar',
            commentType: 3,
            contentId: response.data.id
          }
          this.loading = false
        }, 0)
      })
    }
  }
}
</script>

<style>
.htmlContent {
  font-size: 16px;
  line-height: 22px;
  color: #cccccc;
}
.htmlContent p{
  margin-bottom: 22px;
}
.htmlContent h2 {
  font-size: 18px;
}
.htmlContent a {
  color: #fff;
}
.htmlContent img {
  width: calc(100% - 40px);
  margin: 20px;
  border-radius: 4px;
}
</style>

<style scoped lang="scss">
.imgContainerMobile {
  img {
    width: 100vw;
    z-index: 10;
  }
  .grad {
    background: linear-gradient(to bottom, rgb(23, 25, 35, 0) 0%,rgb(23, 25, 35, 1) 90%);
    height: 70px;
    width: 100%;
    position: absolute;
    margin-top: -70px;
    z-index: 11;
  }
}
.heroMobile {
  padding: 10px;
  width:100vw;
  margin-top: -12px;
  h1 {
    font-size: 22px !important;
  }
  // background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
}
.newsDetail {
  display: flex;
  gap: 20px;
}
.shareButtons {
  display: flex;
  margin-top: 20px;
  border-top: 1px solid grey;
  button {
    background: none;
    border: none;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.3s;
    i, span {
      color: #6b6b6b;
      font-size: 22px;
    }
    span {
      font-size: 12px;
    }
  }
  button:hover {
    i, span {
      color: white;
    }
  }
}
.hero {
  background:black;
  overflow:hidden;
  display: flex;
  justify-content: space-between;
}
.heroCaption {
  z-index:3;
  position:absolute;
  display:grid;
  place-items:center;
  .content {
    min-width:500px;
    max-width: 1000px;
  }
  h1 {
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 8px;
  }
  p {
    font-size: 13px;
  }
}
.overlay {
  position: relative;
  display: inline-block;
  width: 600px;
}
.overlay img {
  display: block;
  width: 100%;
}
.overlay:after {
  position: absolute;
  content:"";
  height:100%;
  width:100%;
  top:0;
  left:0;
  background: linear-gradient(to left, rgba(0,0,0,0) 20%,rgba(0,0,0,1) 100%); /* W3C */
}
.container {
  width: 1200px;
}
.promo {
  margin: auto;
  text-align: center;
  padding: 15px
}
.newsLayout {
  display: grid;
  grid-template-columns: 270px auto;
  gap: 30px;
}
.news-card {
  border-radius: 5px;
  border-bottom: 2px solid #000;
  padding-bottom: 20px;
  .text {
    padding: 20px;
  }
  h3 {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 12px;
  }
  img {
    border-radius: 4px;
    aspect-ratio: 4/3;
  }
}
</style>
