import Vue from 'vue'
import store from './store'
var vm = new Vue({
  store
})
export const fnc = {
  seo: (url) => {
    return url.toString().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
      .replace(/&/g, '-and-')
      .replace(/ı/g, 'i')
      .replace(/[^a-z0-9-]/g, '')
      .replace(/-+/g, '-')
      .replace(/^-*/, '')
      .replace(/-*$/, '')
  },
  getWidth: () => {
    return window.innerWidth
  },
  getHeight: () => {
    return document.body.scrollHeight
  },
  pageRange: (current, { min = 1, total = 20, length = 7 } = {}) => {
    if (length > total) length = total
    let start = current - Math.floor(length / 2)
    start = Math.max(start, min)
    start = Math.min(start, min + total - length)
    return Array.from({ length: length }, (el, i) => start + i)
  },
  phone: (str) => {
    if (!str) {
      return
    }
    var cleaned = ('' + str).replace('+90', '').replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (match) {
      return '(0' + match[1] + ') ' + match[2] + ' ' + match[3] + ' ' + match[4]
    }
    return str
  },
  money: (str) => {
    if (!str) {
      return
    }
    var cleaned = ('' + str).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (match) {
      return '$' + match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4]
    }
    return str
  },
  pageView: (t) => {
    setTimeout(() => {
      const info = { page_path: location.pathname, page_title: document.title, page_location: location.href }
      t.$gtag.pageview(info)
    }, 500)
  },
  prefetch: (info, DetailRoute, noPrefetch) => {
    if (noPrefetch) {
      return
    }
    if (DetailRoute === 'NewsDetail') {
      if (vm.$store.state.newsDetail.id !== info.id) {
        vm.$store.dispatch('setNewsDetail', info)
      }
    } else {
      if (vm.$store.state.articleDetail.id !== info.id) {
        vm.$store.dispatch('setArticleDetail', info)
      }
    }
  },
  cur: (str) => {
    const c = {
      USD: 'en',
      EUR: '€',
      GBP: '£',
      TRY: 'tr'
    }
    return c[str]
  },
  curSign: (str) => {
    const c = {
      USD: '$',
      EUR: '€',
      GBP: '£',
      TRY: '₺'
    }
    return c[str]
  },
  nl2br: (str) => {
    // wrap every new line in <p> tag
    if (str) {
      return '<p>' + str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<p>$2')
    } else {
      return ''
    }
  },
  date: (str) => {
    if (!str) {
      return ''
    }
    const d = new Date(str)
    const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
    const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
    const year = d.getFullYear()
    return day + '.' + month + '.' + year
  },
  date2: (str) => {
    if (!str) {
      return ''
    }
    const d = str
    const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
    // pad zero
    const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
    const year = d.getFullYear()
    return year + '-' + month + '-' + day
  }
}
