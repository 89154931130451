<template>
  <ul class="pager">
    <li v-for="item,idx in $parent.links" :key="idx">
      <router-link v-if="canGo(item.label)" :to="pagerLink(item.label)" class="btn2" :class="{ c4: parseInt(item.label) === parseInt($parent.activePage) }"
        v-html="item.label">
      </router-link>
      <div class="btn2 c0" v-else v-html="item.label"></div>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.c0 {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}
.pager {
  gap: 8px;
  a {
    padding-left:8px;
    padding-right:8px;
  }
}
</style>

<script>
export default {
  methods: {
    canGo: function (page) {
      if (page === '&raquo;') {
        if (this.$parent.activePage < this.$parent.links.length - 2) {
          return true
        }
      } else if (page === '&laquo;') {
        return this.$parent.activePage > 1
      } else {
        return true
      }
    },
    pagerLink: function (page) {
      if (page === '&raquo;') {
        page = this.$parent.activePage + 1
      } else if (page === '&laquo;') {
        page = this.$parent.activePage - 1
      }
      if (parseInt(page) === 1) {
        return { name: this.$route.name, params: this.$route.params }
      } else {
        return { name: this.$route.name, query: { page: page }, params: this.$route.params }
      }
    }
  }
}
</script>
