<template>
  <div>
    <div class="container">
      <div class="mt20" style="min-height:calc(400px)">
        <NormalPage v-if="pageType === 'p'" :page="page" />
        <Faq v-if="pageType === 's'" />
        <Dictionary v-if="pageType === 'di'" />
        <PageCards v-if="pageType === 'cm'" />
      </div>
    </div>
  </div>
</template>

<script>
import NormalPage from '@/components/NormalPage'
import Faq from '@/components/Faq'
import Dictionary from '@/components/Dictionary'
import PageCards from '@/components/PageCards'
export default {
  components: { NormalPage, Faq, Dictionary, PageCards },
  data () {
    return {
      page: null
    }
  },
  computed: {
    cdn () {
      return this.$store.state.cdn
    },
    pageType () {
      return this.$route.params.type
    },
    slug () {
      return this.$route.params.slug
    }
  }
}
</script>
