<template>
  <div style="display:flex;flex-direction:column; justify-content:space-between; min-height: 100vh">
    <Header />
    <router-view />
    <Footer v-if="$route.name !== 'MemberShip'" />
    <div v-else></div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      title: 'Artkaf - Artvin ve Kafkas Dernekleri Federasyonu',
      transitionName: 'slide'
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isLoaded () {
      return this.$store.state.isLoaded
    },
    mainRoute () {
      return this.$store.state.mainRoute
    }
  },
  mounted: function () {
    // console.log('ENv: ' + process.env.VUE_APP_TITLE)
    this.$store.dispatch('setMainRoute', 'Index')
  },
  watch: {
    $route (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide' : 'slide'
    }
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: this.$store.state.baseUrl,
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: this.$store.state.baseUrl + '/tr/ara/{search_term_string}'
        },
        'query-input': 'required name=search_term_string'
      }
    }
  }
}
</script>

<style lang="scss">
.container {
  width:1200px;
  margin: auto;
}
.homeLayout {
  display: grid;
  grid-template-columns: auto 350px;
  gap: 30px;
  min-height: 900px;
}
.mainLayout {
  display: grid;
  gap: 20px;
}
.homeSide {
  background: rgba($color: #fff, $alpha: 0.04);
  padding: 12px;
  border-radius: 14px;
  margin-bottom: 20px;
}
.uppercase {
  text-transform: uppercase;
}
.caption{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: var(--font3);
  margin-bottom: 20px;
  min-height: 42px;
  h1 {
    font-weight: normal;
    font-size: 18px;
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
  }
  a {
    font-size: 12px;
    padding: 3px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  a:hover {
    color: #fff;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: calc(100% - 0px);
    margin: auto;
  }
  .homeLayout, .mainLayout {
    grid-template-columns: 1fr;
  }
}
</style>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.15s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: 100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
