<template>
  <div class="card">
    <router-link @click.native="goExt" v-if="title" :to="go" :class="{ 'noCursor': go === '' && !external }">
      <img v-if="image" :src="image" :alt="title">
      <div class="card-header" v-if="title">
        <h2>{{ title }}</h2>
      </div>
      <div class="card-body" v-if="body" v-html="$fnc.nl2br(body)"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['title', 'body', 'image', 'to', 'external'],
  computed: {
    go () {
      if (this.external) {
        return ''
      } else {
        return this.to
      }
    }
  },
  methods: {
    goExt () {
      if (!this.external) {
        return
      }
      window.open(this.external, '_blank')
    }
  }
}
</script>
