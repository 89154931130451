<template>
  <div>
    <div class="tContainer bg v100">
      <div class="container" style="display:grid;place-items:center">
        <i class="fa fa-minus-circle" style="background:#fff;border-radius:50%;"></i>
        <h1>{{ $t('Error') }} :/</h1>
        <h2>{{ $t(message) }}</h2>
        <p>
          <router-link :to="{ name: redirect }">
            {{ $t(redirectMessage )}}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tContainer {
  padding-top:50px;
  padding-bottom:50px;
}
i {
  font-size: 100px;
  color: red;
  border: 4px solid white;
}
h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size:18px;
}
p {
  margin-bottom: 8px;
  text-align: center;
}
a {
  color: blue;
  text-decoration: underline;
}
a:hover {
  color: var(--color1);
}
</style>

<script>
export default {
  components: { },
  props: ['message', 'redirect', 'redirectMessage'],
  metaInfo () {
    return {
      title: this.$i18n.t(this.message),
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  }
}
</script>
