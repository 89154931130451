<template>
  <div>
    <div class="card">
      <div class="profileCard">
        <div class="avatar">
          <img :src="cdn + 'default.png'">
        </div>
        <div class="user">
          <h2>{{ user.name }}</h2>
        </div>
      </div>
    </div>
    <GlassModal v-if="avatarUploader" @close="closeAvatarUploader" style="color:black">
      <template slot="title">{{ $t('Profil Resmini Güncelle') }}</template>
      <template slot="body">
        <form method="dialog">
          <div class="row">
            <div class="col-12">
              <label style="color:black">{{ $t('Profil Resmi') }} <span class="red">*</span></label>
              <input type="file" @change="selectImage" accept="image/*">
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-12" style="display:flex; gap:12px" v-if="!uploading">
            <button class="btn1 c1" @click="uploadAvatar">{{ $t('Yükle') }}</button>
            <button class="btn1 c2" @click="closeAvatarUploader">{{ $t('Vazgeç') }}</button>
          </div>
          <div class="row" v-else>
            <div class="loading btn1"></div>
          </div>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      avatarUploader: false,
      newImage: '',
      uploading: false
    }
  },
  computed: {
    cdn () {
      return this.$store.state.cdn
    },
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    selectImage (e) {
      this.newImage = e.target.files[0]
    },
    uploadAvatar: async function () {
      this.uploading = true
      if (this.newImage) {
        const formData = new FormData()
        formData.append('cover', this.newImage)
        await this.axios.post('/avatar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.$store.commit('setUser', response.data.user)
          this.closeAvatarUploader()
          this.uploading = false
        }).catch(error => {
          this.$toast.error(error.response.data.message)
          this.uploading = false
        })
      }
    },
    showAvatarUploader () {
      this.avatarUploader = true
    },
    closeAvatarUploader () {
      this.avatarUploader = false
    }
  }
}
</script>

<style scoped lang="scss">
.camera {
  position: absolute;
  margin-top: -20px;
  width: 24px;
  height: 24px;
  background: #252525;
  color: #fff;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card {
  background: #e3e2e2;
  padding: 11px;
  box-shadow: unset;
}
.profileCard {
  display: grid;
  grid-template-columns: 40px auto;
  gap: 24px;
  margin-bottom: 0px;
  align-items: center;
}
.avatar {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  overflow: hidden;
  border: 2px solid rgba($color: #fff, $alpha: 1);
  img {
    width: 100%;
  }
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 13px;
  color: azure;
}
.info {
  margin-top: 4px;
  font-size: 11px;
  color: #999;
  display: flex;
  gap: 6px;
}
.actions {
  display: grid;
  justify-items: right;
}
</style>
