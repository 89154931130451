import { render, staticRenderFns } from "./PageCards.vue?vue&type=template&id=029f9046&scoped=true&"
import script from "./PageCards.vue?vue&type=script&lang=js&"
export * from "./PageCards.vue?vue&type=script&lang=js&"
import style0 from "./PageCards.vue?vue&type=style&index=0&id=029f9046&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "029f9046",
  null
  
)

export default component.exports