<template>
  <div>
    <div>
      <div class="container">
        <Promo :type="'leaderboard'" v-if="$store.state.width > 768" style="margin-top:20px" />
        <Promo :type="'mobile-leaderboard'" v-else style="margin-top:15px"/>
        <div class="mainLayout">
          <div class="homeLayout">
            <div v-if="prices">
              <div class="testTab" style="">
                <BoardTab class="bt" :isArchive="true" :item="item" v-for="item in prices" :key="item.id" />
              </div>
              <Promo :type="'leaderboard'" v-if="$store.state.width > 768" style="margin-top:20px;margin-bottom: 20px" />
              <Promo :type="'medium-rectangle'" v-else style="margin-top:20px;margin-bottom: 20px" />
            </div>
            <div class="homeSide" v-if="$store.state.width > 768">
              <Promo :type="'medium-rectangle'" style="margin-top:20px" />
              <CommentBar :info="$store.state.commentBar" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoardTab from '@/components/BoardTab.vue'
export default {
  components: {
    BoardTab
  },
  data () {
    return {
      priceData: [],
      firstRow: false,
      secondRow: false,
      show: false,
      activeTab1: 0,
      activeTab2: 0,
      activeTab3: 0
    }
  },
  computed: {
    prices () {
      return this.$store.state.allPrices
    }
  },
  beforeMount () {
    this.getPrices()
  },
  methods: {
    getPrices: async function () {
      await this.axios.get('prices').then(response => {
        // this.prices = response.data
        // dispatch all prices
        this.$store.commit('setAllPrices', response.data)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bt {
  margin-bottom:30px;border:1px solid #444;
}
@media screen and (max-width: 768px) {
  .bt {
    border:none;
  }
}
.testTab {
  .block {
    padding:0px;border:1px solid #444;margin-bottom:30px;
  }
  button {
    width: 100%;
    border-radius: 5px;
    display: block;
    padding-top:4px;
    padding-bottom:4px;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    background: none;
    border: none;
    color: white;
    background-color: rgba($color: #fff, $alpha: .05);
  }
}
.activeTab {
  transition: all 0.3s;
  button {
    background-color: rgba($color: #fff, $alpha: .2) !important;
    color: #fff!important;
  }
}
.tabTitle {
  font-size: 14px;
  font-weight: normal;
  padding: 6px;
  display: inline;
}
.tabTitles {
  list-style: none;
  display: flex;
  gap: 10px;
  border-radius: 6px;
  padding:6px;
  flex-wrap: nowrap;
  div {
    // border-right: 1px solid #444444;
    display: grid;
    white-space: nowrap;
    place-items: center;
    width: 100%;
    button {
      width: 100%;
      border-radius: 5px;
      display: block;
      padding-top:4px;
      padding-bottom:4px;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;
      background: none;
      border: none;
      color: white;
      background-color: rgba($color: #fff, $alpha: .05);
    }
    .active {
      color:#fff;
    }
  }
  li:last-child {
    border-right: none;
  }
}
.tabs {
  display: flex;
  gap: 12px;
  border: 1px solid #ccc;
  height: 22px;
}

.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.mainNews {
  display: grid;
  grid-template-columns: 11fr 9fr;
  gap: 20px;
}
.banner {
  width: 930px;
  height: 180px;
  margin: auto;
  background-size: cover !important;
}
.firstBanner {
  height: 200px;
}
.mainLayout {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .homeLayout, .mainLayout {
    grid-template-columns: 1fr;
  }
  .firstBanner {
    display:none;
  }
}
</style>
