<template>
  <div class="pageLayout">
    <LeftSide v-if="$store.state.width > 768" />
    <div class="pRight">
      <h1>Sıkça Sorulan Sorular</h1>
      <div class="ql-editor" v-html="cat.content" v-if="cat.content"></div>
      <div>
        <div class="item" v-for="item in faqs" :key="item.id">
          <h2 @click="selectAnswer(item.id)">
            <span>{{ item.title }}</span>
            <i v-if="item.id !== activeAnswer" class="fa fa-angle-down"></i>
            <i v-else class="fa fa-times"></i>
          </h2>
          <div class="answer" :id="'answer' + item.id">
            <div class="ql-editor" v-html="item.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import LeftSide from '@/small-components/LeftSide'
export default {
  props: ['page', 'hideTitle'],
  components: { LeftSide },
  data: function () {
    return {
      cdn: this.$store.state.cdn,
      faqs: [],
      cat: {},
      activeAnswer: null
    }
  },
  mounted: function () {
    this.getFaq()
  },
  methods: {
    selectAnswer: function (id) {
      const answers = document.getElementsByClassName('answer')
      const answer = document.getElementById('answer' + id)
      if (this.activeAnswer === id) {
        this.activeAnswer = null
        answer.style.height = '0px'
        answer.style.paddingTop = '0px'
        answer.style.paddingBottom = '0px'
        return
      }
      this.activeAnswer = id
      for (let i = 0; i < answers.length; i++) {
        answers[i].style.height = '0px'
        answers[i].style.paddingTop = '0px'
        answers[i].style.paddingBottom = '0px'
      }
      setTimeout(() => {
        answer.style.height = answer.scrollHeight + 20 + 'px'
        answer.style.padding = '10px'
      }, 10)
    },
    getFaq: async function () {
      await this.axios.get('faq').then(response => {
        this.faqs = response.data.items
        this.cat = response.data.cat
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #999;
  padding: 0;
  margin:0;
  margin-bottom: 15px;
  h2 {
    display:flex;
    justify-content:space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid #999;
  }
  .answer {
    height: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    padding-left:10px;
    padding-right: 10px;
    .ql-editor {
      padding-left:15px;
      padding-right:10px;
      border-left: 5px solid #ccc;
    }
  }
}
</style>
