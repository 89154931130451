<template>
  <div>
    <div v-if="user">
      <h2 style="font-weight:normal">{{ $t('Yorumlarınız') }}</h2>
      <div v-if="isLoaded">
        <div v-if="comments.length > 0" style="display:flex;flex-direction:column; justify-content:space-between; min-height:540px;">
          <div>
          <CommentCard v-for="item in comments" :key="item.id" :info="item" />
          </div>
          <div class="tableFooter">
            <ul class="pager">
              <li v-for="item,idx in links" :key="idx">
                <button @click="getPage(item.label)" class="c1" :class="{ activeButton: parseInt(item.label) === parseInt(activePage) }"
                  v-html="item.label">
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          Henüz hiç yorum yapılmamış.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  data () {
    return {
      comments: [],
      isLoaded: false,
      links: [],
      total: 0,
      last_page: 0,
      activePage: 1
    }
  },
  mounted () {
    if (!this.user) {
      this.$router.push({ name: 'Login' })
    } else {
      this.getComments()
    }
  },
  methods: {
    getPage: function (page) {
      if (page === '&raquo;') {
        page = this.activePage + 1
      } else if (page === '&laquo;') {
        page = this.activePage - 1
      } else {
        page = parseInt(page)
      }
      if (page > 0 && page <= this.last_page) {
        this.activePage = page
        this.getComments()
      }
    },
    getComments: async function () {
      this.axios.get('user-comments/' + this.user.id + '?page=' + this.activePage).then(response => {
        this.comments = response.data.data
        this.links = response.data.links
        this.total = response.data.total
        this.last_page = response.data.last_page
        this.isLoaded = true
      })
      console.log('gettings comments')
    }
  }
}
</script>
