<template>
  <div :class="cls">
    <div v-if="!msg">
      <h2 v-if="form.title">{{ form.title }}</h2>
      <p v-if="form.description" v-html="form.description"></p>
      <form :id="'simpleForm' + form.id" v-if="!formSending" style="min-height:400px" autocomplete="nopese" method="dialog">
        <div v-for="(item,idx) in formFields" :key="idx" class="row">
          <label>{{ item.label }} <span v-if="item.req" style="color:red">*</span></label>
          <div v-if="item.type === 'text' || item.type === 'number' || item.type === 'email'">
            <input @keypress.enter="gonder" :required="item.req" autocomplete="off" :type="item.type" :name="'element[' + item.label +']'" :placeholder="item.label">
          </div>
          <div v-if="item.type === 'select'">
            <select :required="item.req" :name="'element[' + item.label +']'">
              <option value="">Lütfen Seçin</option>
              <option :value="op" v-for="op in item.options" :key="op">{{ op }}</option>
            </select>
          </div>
          <div v-if="item.type === 'citySelect'">
            <select :required="item.req" :name="'element[' + item.label +']'">
              <option value="">Lütfen Seçin</option>
              <option :value="op.title" v-for="op in $store.state.siteInfo.cities" :key="op.id">{{ op.title }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <label>Mesajınız</label>
          <textarea :required="false" name="mesaj" rows="6" style="height:130px" placeholder="Mesajınız"></textarea>
        </div>
        <div class="row" style="display:flex; gap: 12px">
          <a @click="setPermission('kvkk')" style="padding:10px">
            <i :class="[kvkk ? 'far fa-check-square' : 'far fa-square']"></i>
          </a>
          <a @click="showContract(kvkkInfo.params.slug)">{{ $t(kvkkInfo.title)}}</a>
        </div>
        <div class="flx">
          <div class="row">
            <button type="button" @click="gonder">{{ form.button_title }}</button>
          </div>
          <div class="row" v-if="form.title === 'BAYİLİK BAŞVURU FORMU'">
            <router-link :to="{ name: 'Login' }" class="btn">GİRİŞ YAP</router-link>
          </div>
        </div>
        <GlassModal v-if="page" @close="closePage" :width="'900px'" style="color:black;">
          <template slot="title">{{ page.title }}</template>
          <template slot="body"><div class="ql-editor" style="height:calc(100vh - 140px); overflow:auto; text-align:left" v-html="page.content"></div></template>
        </GlassModal>
      </form>
      <div v-else id="formPlaceholder" :style="'height:' + formHeight + 'px;min-height:400px;display:grid; place-items:center'">
        <img src="@/assets/loading-pulse.svg">
      </div>
    </div>
    <div v-else :style="'height:' + formHeight + 'px;min-height:400px; display:grid;place-items:center'">
      <div v-if="mailSonuc" class="sonuc">
        <i class="success far fa-check-circle"></i>
        <h1>İşlem Başarılı</h1>
        <p>{{ msg }}</p>
        <p>Müşteri temsilcimiz en kısa sürede size dönüş yapacaktır.</p>
        <router-link :to="{ name: 'Index' }">Anasyfaya Dön</router-link>
      </div>
      <div v-else class="sonuc">
        <i class="fail fa fa-ban"></i>
        <h1>İşlem Başarısız</h1>
        <p>{{ msg }}</p>
        <a @click="resetForm">Tekrar Deneyin</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flx {
  display: flex;
  .row {
    margin-right: 12px;
  }
  .row:last-child {
    margin-right: 0;
  }
}
.sonuc {
  display:grid;
  width: 100%;
  place-items: center;
  gap: 20px;
  text-align: center;
  a {
    font-size:12px; color:blue;
    text-decoration: underline;
  }
}
.success {
  font-size: 100px;
  color: teal;
}
.fail {
  font-size: 100px;
  color: red;
}
.row {
  display: grid;
  margin-bottom: 12px;
  label {
    font-size: 11px;
    margin-bottom: 2px;
    padding-left: 4px;
    color: var(--color2);
    font-weight: bold;
  }
  input, select, button, .btn, textarea {
    border: 1px solid var(--color1);
    width: 100%;
    padding: 8px;
    height: 34px;
    font-size: 13px;
    border-radius: 4px;
    background:#2b2d37;
    color: white;
  }
  button {
    padding-left:14px;
    padding-right:14px;
    font-size: 14px;
  }
  .btn {
    padding-left:14px;
    padding-right:14px;
    background-color: var(--color2);
    font-weight:bold;
    color: #fff;
    border: none;
    display:grid;
    place-items: center;
    font-size: 14px;
  }
  button {
    background: var(--color1);
    color:#fff;
    font-weight: bold;
  }
}
</style>

<script>
import axios from 'axios'
export default {
  props: ['form', 'cls'],
  data () {
    return {
      formFields: [],
      formSending: false,
      formHeight: 100,
      msg: '',
      mailSonuc: false,
      kvkkInfo: this.$store.state.siteInfo.contracts.kvkk,
      kvkk: false,
      page: false
    }
  },
  beforeMount () {
    this.init()
  },
  watch: {
    $route () {
      this.init()
      this.resetForm()
    }
  },
  methods: {
    setPermission: function (per) {
      this[per] = !this[per]
    },
    showContract: async function (slug) {
      await this.axios.get('contract/' + slug).then(res => {
        this.page = res.data.page
      })
    },
    closePage: function () {
      this.page = false
    },
    gonder: async function () {
      if (!this.kvkk) {
        this.$toast.error('Kvkk Metnini Onaylamanız Gerekmektedir.')
        return
      }
      //
      const myForm = document.getElementById('simpleForm' + this.form.id)
      // check required fields in myForm
      let formValid = true
      for (let i = 0; i < myForm.length; i++) {
        if (myForm[i].required && myForm[i].value === '') {
          formValid = false
          const f = myForm[i].name.replace('element[', '').replace(']', '')
          this.$toast.error('Lütfen ' + f + ' alanını doldurunuz.')
          myForm[i].focus()
          return
        }
      }
      console.log(formValid)
      this.formSending = true
      const formData = new FormData(myForm)
      const data = {}
      for (const [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }
      const payload = {
        user: this.$store.state.user,
        data: data,
        form_id: this.form.id,
        form_title: this.form.title
      }
      await axios.post('send-simple-form/' + this.form.id, payload).then(response => {
        this.success = true
        this.formSending = false
        this.mailSonuc = true
        this.msg = response.data.message
      }).catch(err => {
        this.$toast.error(err.response.data.message)
        this.formSending = false
      })
    },
    resetForm: function () {
      this.msg = ''
      this.mailSonuc = false
    },
    init: function () {
      if (typeof this.form.fields === 'string') {
        this.formFields = JSON.parse(this.form.fields)
      } else {
        this.formFields = this.form.fields
      }
      setTimeout(() => {
        this.formHeight = document.getElementById('simpleForm' + this.form.id).offsetHeight
      }, 200)
    }
  }
}
</script>
