<template>
  <div>
    <div>
      <div class="container">
        <Promo v-if="$store.state.width > 768" :type="'leaderboard'" style="margin-top:20px" />
        <div class="mainLayout">
          <div>
            <div class="homeLayout">
              <div>
                <div>
                  <div class="caption">
                    <h1 style="text-transform:uppercase" v-if="title">{{ title }} {{ $t('FİYAT ARŞİVİ') }}</h1>
                  </div>
                  <PriceRow :isArchive="true" v-if="price" :row="price" />
                  <div v-if="!errorMessage" class="" style="margin-top:20px; margin-bottom:20px">
                    <div v-if="priceArchive.data">
                      <div style="border: 1px solid #ccc; padding: 10px;margin-top:15px; margin-bottom: 15px;">
                        <div style="font-size:13px">
                          {{ $fnc.date(priceArchive.data[0].x) }} - {{ $fnc.date(priceArchive.data[priceArchive.data.length - 1].x) }} Tarihleri arası fiyat arşivi
                        </div>
                        <div style="margin-top:12px;margin-bottom:4px">
                          <div style="display:flex; justify-content:space-between; margin-bottom:8px">
                            <div>{{ $t('En Yüksek') }}</div>
                            <div>{{ highest.y }} {{ $fnc.curSign(price.currency) }}</div>
                          </div>
                          <div style="display:flex; justify-content:space-between">
                            <div>{{ $t('En Düşük') }}</div>
                            <div>{{ lowest.y }} {{ $fnc.curSign(price.currency) }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="arRow arHead" style="color:white">
                      <div class="c"><i class="fa fa-calendar-alt"></i> {{ $t('DATE') }}</div>
                      <div class="c right">{{ price.name }}</div>
                      <div class="c right">{{ priceArchive2.name }} <a class="hidden" :href="downloadLink" target="_black"><i class="fa fa-file-excel"></i></a></div>
                    </div>
                    <div v-for="(item,idx) in priceArchive.data" :key="idx" class="arRow">
                      <div class="c">{{ $fnc.date(item.x) }}</div>
                      <div class="c right">{{ item.y }} {{ $fnc.curSign(price.currency) }}</div>
                      <div class="c right" v-if="priceArchive2.data[idx]">{{ priceArchive2.data[idx].y }} {{ $fnc.curSign(priceArchive2.currency) }}</div>
                      <div class="c right" v-else>----</div>
                    </div>
                  </div>
                </div>
                <Promo v-if="$store.state.width > 768" :type="'leaderboard'" style="margin-top:20px;margin-bottom: 20px" />
              </div>
              <div class="homeSide" v-if="$store.state.width > 768">
                <Promo :type="'medium-rectangle'" style="margin-top:20px" />
                <CommentBar :info="$store.state.commentBar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceRow from '@/components/PriceRow'
export default {
  components: { PriceRow },
  data () {
    return {
      price: false,
      priceData: [],
      news: [],
      firstRow: false,
      secondRow: false,
      show: false,
      activeTab1: 0,
      activeTab2: 0,
      activeTab3: 0,
      fuck: false,
      graphOn: false,
      errorMessage: false,
      activeGraph: 0,
      priceArchive: [],
      priceArchive2: [],
      title: '',
      activeDay: 45,
      byDate: false,
      startDate: '',
      endDate: ''
    }
  },
  beforeMount () {
    this.getPrice()
  },
  computed: {
    highest () {
      return this.priceArchive.data.reduce((a, b) => {
        return a.y > b.y ? a : b
      })
    },
    lowest () {
      return this.priceArchive.data.reduce((a, b) => {
        return a.y < b.y ? a : b
      })
    },
    price2name () {
      return this.priceArchive2.name
    },
    activeComp () {
      return this.priceArchive2.id
    },
    downloadLink: function () {
      // build url based on
      const actDay = '&days=' + this.activeDay
      let bydate = ''
      if (this.byDate === true) {
        bydate = '&byDate=true&startDate=' + this.startDate + '&endDate=' + this.endDate
      } else {
        bydate = ''
      }
      return this.axios.defaults.baseURL + 'price/graph/' + this.price.id + '?id=' + this.price.id + '&activeComp=' + this.activeComp + '&forCsv=true' + actDay + bydate
    }
  },
  methods: {
    getPrice: async function () {
      const id = parseInt(this.$route.params.id)
      await this.axios.get('price/details/' + id).then(response => {
        this.price = response.data[0]
        if (this.price.current_price.cantSee) {
          this.graphOn = false
          this.activeGraph = this.price.id
          this.errorMessage = this.price.current_price.message
        } else {
          this.errorMessage = false
          this.graphOn = true
          this.activeGraph = this.price.id
        }
        this.title = this.price.name
      })
    }
  }
}
</script>

<style scoped lang="scss">
.arRow {
  display:grid;
  grid-template-columns: auto 110px 80px;
  gap:20px;
  border-bottom: 1px solid #666;
  color: #ccc;
  font-weight: bold;
  padding: 12px;
  font-size: 13px;
  .c {
    font-weight: normal;
    gap: 8px;
  }
}
.arRow:nth-child(odd) {
  background: rgba($color: #fff, $alpha: .05);
}
.arHead {
  background: var(--color2)!important;
  padding: 8px;
  .c {
    text-transform: uppercase;
  }
}
.mainLayout {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .homeLayout, .mainLayout {
    grid-template-columns: 1fr;
  }
  .firstBanner {
    display:none;
  }
}
</style>
