<template>
  <div>
    <div v-if="user">
      <h2 style="font-weight:normal">{{ $t('Bildirimlerim') }}</h2>
      <div v-if="isLoaded" style="display:flex;flex-direction:column; justify-content:space-between; min-height:540px;">
        <div>Bildirimler  burada yer alacak</div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  data () {
    return {
      notifications: [],
      isLoaded: true,
      links: [],
      total: 0,
      last_page: 0,
      activePage: 1
    }
  }
}
</script>
