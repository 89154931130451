<template>
  <div class="c6">
    <div class="container">
      <div class="simpleHeader">
        <router-link :to="{ name: 'Index' }" class="logo" :title="$t('Anasayfa')"></router-link>
        <router-link v-if="!user" class="btn1 c2" :to="{ name: 'Login'}">GİRİŞ YAP</router-link>
        <router-link v-else class="btn1 c2" :to="{ name: 'ProfileSettings'}">
          {{ user.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  }
}
</script>

<style scoped lang="scss">
.c6 {
  background: var(--color1);
}
.simpleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: calc(env(safe-area-inset-top) + 10px);
  padding-bottom: 10px;
}
.logo {
  width: 160px;
  height: 50px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.png') center no-repeat;
  background-size: contain;
}
</style>
