<template>
  <div class="promo" v-if="showAds">
    <a @click="gotoPromo" :style="'height:' + height">
      <img @load="promoLoaded" :src="image" loading="lazy" />
    </a>
  </div>
</template>

<script>
export default {
  props: ['type'],
  data () {
    return {
      cdn: this.$store.state.cdn,
      adId: false,
      link: false,
      image: '',
      height: '',
      isReported: false,
      isLoaded: false
    }
  },
  computed: {
    showAds () {
      return this.$store.state.showAds
    },
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    user (val) {
      if (val) {
        window.removeEventListener('scroll', this.scroll, false)
      }
    },
    $route (to, from) {
      if (this.showAds) {
        window.addEventListener('scroll', this.scroll, false)
        this.getAd()
      } else {
        window.removeEventListener('scroll', this.scroll, false)
      }
    }
  },
  mounted () {
    this.getAd()
    if (this.showAds) {
      window.addEventListener('scroll', this.scroll, false)
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scroll, false)
  },
  methods: {
    gotoPromo: async function () {
      if (!this.link) {
        return
      }
      if (this.link.substring(0, 4) === 'http') {
        await this.axios.post('/promo-click', {
          adId: this.adId
        })
        window.open(this.link, '_blank')
      } else {
        if (this.$route.path !== this.link) {
          await this.axios.post('/promo-click', {
            adId: this.adId
          })
          this.$router.push(this.link)
        }
      }
    },
    getAd: async function () {
      const arr = [
        {
          type: 'leaderboard',
          image: 'https://picsum.photos/780/90/',
          height: '90px'
        },
        {
          type: 'medium-rectangle',
          image: 'https://picsum.photos/300/250/',
          height: '250px'
        },
        {
          type: 'half-page',
          image: 'https://loremflickr.com/300/600/',
          height: '600px'
        },
        {
          type: 'mobile-leaderboard',
          image: 'https://loremflickr.com/320/50/',
          height: '50px'
        },
        {
          type: 'large-mobile-banner',
          image: 'https://loremflickr.com/320/100/',
          height: '250px'
        }
      ]
      this.image = ''
      this.isReported = false
      this.isLoaded = false
      this.height = arr.find(item => item.type === this.type).height
      const tip = arr.find(item => item.type === this.type).type
      await this.axios.get('promo/' + tip).then(response => {
        if (response.data.error) {
          return
        }
        this.image = this.cdn + response.data.image
        this.link = response.data.link
        this.adId = response.data.id
      })
    },
    scroll: function () {
      if (this.$el.getBoundingClientRect().top < window.innerHeight && !this.isReported) {
        this.reportAd()
      }
    },
    promoLoaded: function () {
      this.isLoaded = true
      if (this.$el.getBoundingClientRect().top < window.innerHeight && !this.isReported) {
        this.reportAd()
      }
    },
    reportAd: async function () {
      const payload = {
        adId: this.adId
      }
      if (!this.adId) {
        return
      }
      if (this.isReported) {
        return
      }
      this.isReported = true
      await this.axios.post('/promo-count', payload).then(response => {
        this.isReported = true
      }).catch(error => {
        console.log(error)
        this.isReported = true
      })
      window.removeEventListener('scroll', this.scroll, false)
      this.isReported = true
    }
  }
}
</script>

<style scoped lang="scss">
.promo {
  display: grid;
  place-items: center;
  text-align: center;
  width: 100%;
}
</style>
