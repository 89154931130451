import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../components/Login.vue'
import NotFound from '../views/404.vue'
import MemberShip from '../views/MemberShip.vue'
import Home from '../components/Home.vue'
import Prices from '../components/Prices.vue'
import PriceDetails from '../components/PriceDetails.vue'
import Articles from '../components/Articles.vue'
import Comments from '../components/Comments.vue'
import NewsDetail from '../components/NewsDetail.vue'
import PageDetail from '../components/PageDetail.vue'
import ArticleDetail from '../components/ArticleDetail.vue'
import Tv from '../components/Tv.vue'
import Delegeler from '../components/Delegeler.vue'
import Dernekler from '../components/Dernekler.vue'
import Haberler from '../components/Haberler.vue'
import Indirim from '../components/Indirim.vue'
import i18n from '../i18n'
import Profile from '../components/Profile.vue'
import ProfileSettings from '../components/profile/Settings.vue'
import ProfileFollowers from '../components/profile/Followers.vue'
import ProfileFollowing from '../components/profile/Following.vue'
import ProfilePosts from '../components/profile/Posts.vue'
import ProfileComments from '../components/profile/Comments.vue'
import ProfileNotifications from '../components/profile/Notifications.vue'
import ProfileChangePassword from '../components/profile/ChangePassword.vue'
import ProfilePriceEntry from '../components/profile/PriceEntry.vue'

/*
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}
*/

Vue.use(VueRouter)

let locale = ''
const savedLocale = localStorage.getItem('lang')
if (savedLocale) {
  locale = savedLocale
} else {
  const bLocale = window.location.pathname.replace('/', '').trim().substring(0, 2)
  const langs = ['tr']
  if (langs.includes(bLocale)) {
    locale = bLocale
  } else {
    locale = 'tr'
  }
}
i18n.locale = locale

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      }
    ]
  }, {
    path: '/:lang',
    component: Index,
    children: [
      {
        path: '',
        name: 'Index',
        component: Home,
        meta: { transition: 'slide-left' }
      }, {
        path: i18n.t('dernekler'),
        name: 'Dernekler',
        component: Dernekler,
        meta: { transition: 'slide-left' }
      }, {
        path: i18n.t('delegeler'),
        name: 'Delegeler',
        component: Delegeler,
        meta: { transition: 'slide-left' }
      }, {
        path: i18n.t('tv'),
        name: 'Tv',
        component: Tv,
        meta: { transition: 'slide-left' }
      }, {
        path: i18n.t('prices'),
        name: 'Prices',
        component: Prices,
        meta: { transition: 'slide-left' }
      }, {
        path: i18n.t('prices') + '/:id',
        name: 'PriceDetails',
        component: PriceDetails,
        meta: { transition: 'slide-left' }
      }, {
        path: '/:lang/nc/:slug/',
        name: 'Haberler',
        component: Haberler,
        meta: { transition: 'slide-left' }
      }, {
        path: '/:lang/indirim-kulubu',
        name: 'Indirim',
        component: Indirim,
        meta: { transition: 'slide-left' }
      }, {
        path: '/:lang/ac/' + i18n.t('technical-analysis'),
        name: 'Analysis',
        component: Articles
      }, {
        path: '/:lang/nd/:category/:slug',
        name: 'NewsDetail',
        component: NewsDetail
      }, {
        path: '/:lang/mbs/' + i18n.t('membership'),
        name: 'MemberShip',
        component: MemberShip
      }, {
        path: '/:lang/ad/' + i18n.t('analys') + '/:slug',
        name: 'ArticleDetail',
        component: ArticleDetail
      }, {
        path: '/:lang/' + i18n.t('giris-yapin'),
        name: 'Login',
        component: Login
      }, {
        path: '/:lang/' + i18n.t('uye-olun'),
        name: 'Register',
        component: Login
      }, {
        path: '/:lang/' + i18n.t('sifrenizi-sifirlayin'),
        name: 'ForgetPassword',
        component: Login
      }, {
        path: '/:lang/rp',
        name: 'CompleteReset',
        component: Login
      }, {
        path: '/:lang/pg/:type/:slug',
        name: 'PageDetail',
        component: PageDetail
      }, {
        path: '/:lang/pg/:type/:slug/l/:letter',
        name: 'DictionaryLetter',
        component: PageDetail
      }, {
        path: '/:lang/pg/:type/:slug/w/:word',
        name: 'DictionaryWord',
        component: PageDetail
      }, {
        path: '/:lang/pr/' + i18n.t('profile') + '/',
        name: 'Profile',
        component: Profile,
        children: [
          {
            path: i18n.t('profile.settings'),
            name: 'ProfileSettings',
            component: ProfileSettings
          }, {
            path: i18n.t('profile.posts'),
            name: 'ProfilePosts',
            component: ProfilePosts
          }, {
            path: i18n.t('profile.comments'),
            name: 'ProfileComments',
            component: ProfileComments
          }, {
            path: i18n.t('profile.following'),
            name: 'ProfileFollowing',
            component: ProfileFollowing
          }, {
            path: i18n.t('profile.followers'),
            name: 'ProfileFollowers',
            component: ProfileFollowers
          }, {
            path: i18n.t('profile.notifications'),
            name: 'ProfileNotifications',
            component: ProfileNotifications
          }, {
            path: i18n.t('profile.priceList'),
            name: 'ProfilePriceList',
            component: ProfileNotifications
          }, {
            path: i18n.t('profile.changePassword'),
            name: 'ProfileChangePassword',
            component: ProfileChangePassword
          }, {
            path: i18n.t('profile.priceEntry'),
            name: 'ProfilePriceEntry',
            component: ProfilePriceEntry
          }
        ]
      }, {
        path: '/:lang/' + i18n.t('yorumlar'),
        name: 'Comments',
        component: Comments
      }
    ]
  }, {
    path: '/:catchAll(.*)',
    // path: '/:lang/404.html',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  },
  mode: 'history',
  linkExactActiveClass: 'active',
  // base: process.env.BASE_URL,
  // base: '' + window.location.pathname.replace(/\[^\/]+$/, ''),
  base: '',
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    console.log('noMatcjh')
    next({ name: '404', params: { lang: locale } })
  } else {
    to.params.lang = locale
    next()
  }
})

export default router
