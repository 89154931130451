<template>
  <div class="navContainer">
    <nav>
      <router-link :to="{ name: 'Home' }" :class="{ activeBtn: $route.name === 'Home'}">
        <div class="icon home">
          <i class="fa fa-home"></i>
        </div>
        <span>{{ $t('Anasayfa') }}</span>
      </router-link>
      <router-link :to="{ name: 'Haberler', params: { slug: 'haberler' } }" :class="{ activeBtn: $route.name === 'Haberler' }">
        <div class="icon home">
          <i class="fa fa-newspaper"></i>
        </div>
        <span>{{ $t('News') }}</span>
      </router-link>
      <router-link :to="{ name: 'Dernekler' }" :class="{ activeBtn: $route.name === 'Dernekler' }">
        <div class="icon home">
          <i class="fa fa-network-wired"></i>
        </div>
        <span>{{ $t('Dernekler') }}</span>
      </router-link>
      <router-link :to="{ name: 'Tv' }" :class="{ activeBtn: $route.name === 'Tv' }">
        <div class="icon home">
          <i class="fa fa-tv"></i>
        </div>
        <span>{{ $t('Artkaf.TV') }}</span>
      </router-link>
      <router-link :to="{ name: 'Indirim' }" :class="{ activeBtn: $route.name === 'Indirim' }">
        <div class="icon home">
          <i class="fa fa-gift"></i>
        </div>
        <span>{{ $t('İndirim Kulübü') }}</span>
      </router-link>
    </nav>
    <div class="actionsBg" id="bgMenu" @click="showSubMenu"></div>
  </div>
</template>

<script>
export default ({
  data () {
    return {
      subMenu: false
    }
  },
  watch: {
    '$route' (to, from) {
      if (this.subMenu) {
        this.showSubMenu()
      }
    }
  },
  methods: {
    showSubMenu () {
      this.subMenu = !this.subMenu
      const el = document.getElementById('actionsMenu')
      const bg = document.getElementById('bgMenu')
      bg.style.opacity = this.subMenu ? 1 : 0
      bg.style.pointerEvents = this.subMenu ? 'all' : 'none'
      el.style.bottom = this.subMenu ? '0' : '-100%'
      el.style.pointerEvents = this.subMenu ? 'all' : 'none'
    },
    showSubNews () {
      this.$router.push({ name: 'News', params: { slug: 'demir-celik-haberleri' } })
    },
    setPriceMode (mode) {
      this.$router.push({ name: 'Home' })
      this.$store.commit('setPriceMode', mode)
    }
  }
})
</script>

<style scoped lang="scss">
.actionsBg {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 8888;
  transition: all 0.2s;
  pointer-events: none;
}
.actionsMenu {
  pointer-events: none;
  transition: all 0.2s;
  position: fixed;
  bottom: -100%;
  right: 0;
  width: 100%;
  background: #fff;
  z-index: 999999;
  padding: 10px;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0 0;
  li {
    width: 100%;
    border-bottom: 1px solid #ccc;
    a {
      padding: 14px;
      text-align: center;
    }
    a:hover, a.active {
      color: var(--color1);
    }
  }
  li:last-child {
    border-bottom: none;
  }
}
.goBack {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 20px auto;
  align-items:center;
  font-weight:bold;
  background:var(--color1);
  color:white;
  font-size: 18px;
  padding-left:12px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100vw) !important;
  margin-left: -20px;
}
#mobileMenuTop {
  position: absolute;
  left: 20px;
  width: calc(100vw - 40px);
  top: 20px;
  transition:all 0.2s;
}
.subs {
  position: absolute;
  left: 100vw;
  width: calc(100vw - 40px);
  transition:all 0.2s;
}
.lvl1 {
  border-bottom: 1px solid #ccc;
}
.lvl1:last-child {
  border: none;
}
.lvl2 {
  padding-left: 12px;
  border-bottom: 1px solid #ccc;
}
.lvl2:last-child {
  border: none;
}
.mobileMenu {
  position:fixed;
  bottom: calc((60vh) * -1);
  left: 0;
  width: calc(100vw);
  overflow: hidden;
  height: calc(60vh);
  background: #fff;
  border-radius: 14px 14px 0 0;
  z-index: 20;
  transition: all 0.2s ease-out;
  overflow-y: scroll;
}
.overlay {
  background:rgba(0,0,0,0.7);width:100%;height:100%;position:absolute;z-index:12; top:0;
  overscroll-behavior: contain;
  transition: all 0.2s;
  opacity: 0;
}
.cartCount {
  margin-left:17px;
  margin-top:-38px;
  background:var(--color1);
  color:white;
  border:2px solid #fff;
  text-align:center;
  font-weight:bold;
  display:grid;place-items:center;
  width:18px;
  height:18px;
  border-radius:50%;
  font-size: 8px;
}
nav {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 52px;
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
  background: rgba(255,255,255,0.8);
  // background: rgba(0,0,0,0.3);
  border-top: 1px solid #fff;
  backdrop-filter: blur(10px);
  border-radius: 8px 8px 0 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  z-index: 100;
  a, button {
    text-align: center;
    font-size: 10px;
    width:100%;
    height:100%;
    border: none;
    background: none;
    span {
      color:#666;
    }
    .icon {
      width: 44px;
      height: 26px;
      display: grid;
      place-items: center;
      margin: auto;
      margin-top: 8px;
      i {
        color: #666;
        font-size: 20px;
      }
    }
  }
  .activeBtn {
    span, .icon i {
      color:var(--color1);
    }
  }
}
@media screen and (max-height:300px ) {
  .navContainer {
    display:none;
  }
}
</style>
