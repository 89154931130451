<template>
  <div>
    <div class="container">
      <div class="homeLayout" style="margin-top:20px" v-if="$store.state.width > 768">
        <div class="left">
          <Promo :type="'leaderboard'" />
          <div class="caption">
            <h1>TEKNİK ANALİZLER</h1>
          </div>
          <section>
            <div class="mainNews">
              <div class="rightNews" style="display:grid; grid-template-columns: 1fr 1fr; gap: 30px">
                <NewsCard4 :DetailRoute="'ArticleDetail'" v-for="item in articles.slice(0, 4)" :key="item.id" :info="item" />
              </div>
              <div class="rightNews">
                <NewsCard3 :DetailRoute="'ArticleDetail'" v-for="item in articles.slice(4)" :key="item.id" :info="item" />
              </div>
            </div>
          </section>
          <Promo :type="'leaderboard'" />
          <div style="padding-top: 8px; padding-bottom: 22px; display:grid; place-items:center">
            <SimplePager />
          </div>
        </div>
        <div class="homeSide">
          <Promo :type="'medium-rectangle'" />
          <div class="caption">
            <h2>Analiz Yazarları</h2>
          </div>
          <ProfileCard v-for="item in topWriters.data" :key="item.id" :info="item" :tip="'writer'" />
          <CommentBar :info="$store.state.commentBar" />
          <Promo :type="'medium-rectangle'" />
        </div>
      </div>
      <div v-else style="margin-top:4px">
        <Promo :type="'mobile-leaderboard'" style="margin-top: 15px; margin-bottom:4px;"/>
        <div class="caption">
          <h1 class="uppercase">Teknik Analizler</h1>
        </div>
        <div style="display:grid; grid-template-columns: 1fr; gap: 30px">
          <NewsCard4 :DetailRoute="'ArticleDetail'" v-for="item in articles.slice(0, 4)" :key="item.id" :info="item" />
        </div>
        <NewsCard3 :DetailRoute="'ArticleDetail'" v-for="item in articles.slice(4)" :key="item.id" :info="item" />
        <div style="padding-top: 8px; padding-bottom: 22px; display:grid; place-items:center">
          <SimplePager />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      articles: [],
      links: [],
      activePage: 1
    }
  },
  mounted () {
    this.getArticles()
    this.getTopWriters()
  },
  computed: {
    topWriters () {
      return this.$store.state.home.topWriters
    },
    DetailRoute () {
      if (this.$route.name === 'News') {
        return 'NewsDetail'
      } else {
        return 'ArticleDetail'
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.getArticles()
    }
  },
  methods: {
    getTopWriters: function () {
      this.axios.get('top-writers').then(response => {
        this.$store.commit('setTopWriters', response.data)
      })
    },
    getArticles: async function () {
      const page = this.$route.query.page || 1
      await this.axios.get('/articles' + '?page=' + page).then(response => {
        this.articles = response.data.data
        this.links = response.data.meta.links
        this.activePage = response.data.meta.current_page
      })
    }
  }
}
</script>

<style scoped lang="scss">
.rightNews {
  padding-top: 20px;
}
.newsLayout {
  display: grid;
  grid-template-columns: 270px auto;
  gap: 30px;
}
.news-card {
  border-radius: 5px;
  border-bottom: 2px solid #000;
  padding-bottom: 20px;
  .text {
    padding: 20px;
  }
  h3 {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 12px;
  }
  img {
    border-radius: 4px;
    aspect-ratio: 4/3;
  }
}
</style>
