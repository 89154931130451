<template>
  <div id="app">
    <router-view v-if="infoLoaded"/>
    <div v-if="!isLoaded" style="display:grid; place-items:center; height:100vh; position:fixed;width:100vw; height:100vh;top:0;left:0;background:black;">
      <div>
        <img src="@/assets/logo.png" alt="Artkaf" />
        <div class="loading" style="height:30px; margin-top:20px"></div>
      </div>
    </div>
    <button style="display:none" v-shortkey="['ctrl', 'c']" @shortkey="copyText" @click="copyText">Copy</button>
    <a id="upper" @click="scrollUp" class="floating-button"><i class="fa fa-angle-up"></i></a>
    <GlassModal :showLogin="promoteToMembership.showLogin" v-if="promoteToMembership" @close="closePromoter" style="color:black">
      <template v-slot:title>{{ promoteToMembership.title }}</template>
      <template v-slot:body>
        <p style="margin-bottom:12px" v-html="promoteToMembership.message"></p>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

export default {
  data () {
    return {
      isLoaded: false,
      infoLoaded: false,
      platform: 'web',
      scroll: 110,
      reportToAds: []
    }
  },
  metaInfo () {
    const title = this.$i18n.t('Artkaf')
    return {
      title: 'Hoşgeldiniz',
      titleTemplate: '%s - ' + title
    }
  },
  beforeMount () {
    // this.$toast.info('tes')
    this.first()
    const platform = localStorage.getItem('platform')
    if (platform === 'android') {
      // this.$root.rateApp()
    }
    if (platform === 'ios') {
      this.$root.hideStatusBar()
    }
  },
  mounted: function () {
    // this.$root.getDeviceInfo()
    window.addEventListener('resize', this.resize)
    window.addEventListener('scroll', this.setScroll)
    this.info()
    // this.$root.registerPush()
    const platform = localStorage.getItem('platform')
    console.log(platform)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('scroll', this.setScroll)
  },
  watch: {
    $route (to, from) {
      document.querySelector('body').style.overflow = 'auto'
      this.$store.dispatch('setMainRoute', '')
      this.$store.commit('setPromoteToMembership', false)
      if (this.$route.name !== 'Home') {
        this.info()
      }
    },
    user (val) {
      const tokenTime = localStorage.getItem('tokenTime')
      const now = new Date().getTime()
      if ((now - tokenTime) > 1 * 30 * 1000) {
        // this.setToken()
        console.log('fake')
      }
      // this.info()
    },
    pushToken (val) {
      if (val) {
        console.log('token updated')
        // this.setToken()
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    promoteToMembership () {
      return this.$store.state.promoteToMembership
    },
    pushToken () {
      console.log('token retrived from store')
      // return 'test'
      return this.$store.state.pushToken
    }
  },
  methods: {
    registerPush: function () {
      console.log('token registering')
      this.$toast.info('Token kaydediliyor...')
      this.$root.registerPush()
      // this.$root.statusBar('#002043')
    },
    copyText () {
      const t = false
      if (!t) {
        return false
      }
      const time = new Date().getTime()
      const str = 'günceldemir' + '\n' + 'https://güncel.demir' + time
      const el = document.createElement('input')
      el.value = str
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$toast.success(this.$t('Kopyalandı'))
    },
    closePromoter () {
      this.$store.commit('setPromoteToMembership', false)
    },
    first: function () {
      // get json file from assets
      const response = require('@/assets/first.json')
      this.$store.dispatch('setSiteInfo', response)
      this.$store.dispatch('setCommentBar', response.commentBar)
      const siteInfo = {
        priceList: response.priceList,
        permisssions: response.permisssions
      }
      this.$store.dispatch('setInfo', siteInfo)
      this.isLoaded = true
      this.infoLoaded = true
    },
    setToken: async function () {
      const payload = {
        token: this.pushToken,
        platform: localStorage.getItem('platform'),
        device: localStorage.getItem('device')
      }
      await this.axios.post('/settoken', payload).then(response => {
        console.log(response.data)
        console.log('token sent')
        // set localstorage time
        localStorage.setItem('tokenTime', new Date().getTime())
      })
    },
    info: async function () {
      // console.log('info')
    },
    setScroll: function () {
      this.scroll = window.scrollY
      const el = document.getElementById('upper')
      if (this.scroll > 200) {
        el.style.opacity = 1
        el.style.pointerEvents = 'all'
        if (this.$store.state.width > 768) {
          el.style.bottom = '25px'
        } else {
          el.style.bottom = '60px'
        }
      } else {
        el.style.opacity = 0
        el.style.pointerEvents = 'none'
        el.style.bottom = '0px'
      }
    },
    resize: function () {
      const size = { width: this.$fnc.getWidth(), height: this.$fnc.getHeight() }
      this.$store.dispatch('setSize', size)
    },
    scrollUp: function () {
      // smooth scroll to top
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style>
  @import './assets/css/main.css';
</style>

<style scoped lang="scss">
#app {
  margin-top: env(safe-area-inset-top, 20px);
}
.floating-button {
  position: fixed;
  bottom: 0px;
  right: 25px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  i {
    font-size: 20px;
    color: #171923;
  }
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: .2s ease;
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 768px) {
  .floating-button {
    right: 10px;
  }
}
</style>
