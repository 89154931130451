<template>
  <div>
    <div class="homePrices">
      <div class="tabContainer">
        <PriceRow :row="row" v-for="row in prices" :key="row.id" />
      </div>
    </div>
  </div>
</template>

<script>
import PriceRow from './PriceRow.vue'
export default {
  props: ['title', 'prices'],
  components: { PriceRow },
  data () {
    return {
      activeTab: 0,
      activeGraph: 0,
      graphOn: false,
      errorMessage: false,
      priceTab: [],
      options: {
        height: 250,
        title: {
          text: 'Artkaf'
        },
        subtitle: {
          text: 'Son 7 Kayıt'
        },
        chart: {
          background: '#1c1e28',
          type: 'area',
          labels: true,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [
                {
                  name: 'closeCraph',
                  title: 'Close Graph',
                  icon: '<i class="fas fa-times"></i>',
                  click: function (chart, options, e) {
                    this.showGraph(0)
                  }.bind(this)
                }
              ]
            },
            export: {
              csv: {
                enabled: false
              },
              png: {
                filename: 'Artkaf'
              }
            }
          }
        },
        stroke: {
          curve: 'straight',
          width: 2,
          colors: ['#00bcd4']
        },
        fill: {
          opacity: 0.4,
          colors: ['#034a6c', '#1c1e28'],
          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.9,
            opacityFrom: 0.7,
            opacityTo: 0.5,
            stops: [0, 90, 100]
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          theme: 'dark'
        },
        xaxis: {
          type: 'category',
          convertCatToNumber: false,
          categories: ['1ocak', '2ocak']
        },
        yaxis: {
          opposite: true,
          position: 'right'
        }
      },
      series: []
    }
  },
  methods: {
    showGraph: async function (id) {
      if (this.activeGraph === id) {
        id = 0
      }
      this.graphOn = false
      this.activeGraph = id
      this.series = []
      if (id) {
        await this.axios.get('price/graph/' + id).then(res => {
          this.series = res.data.data
          this.options.xaxis.categories = res.data.dates
          this.graphOn = true
        })
      }
    }
  }
}
</script>

<style>
.apexcharts-tooltip {
  box-shadow: unset !important;
  background: #000 !important;
  color: #f5f5f5 !important;
}
.apexcharts-menu-item {
  color: #000;
}
.apexcharts-menu-item.exportSVG, .apexcharts-menu-item.exportCSV {
  display: none;
}
</style>
<style scoped lang="scss">

.activeTab {
  transition: all 0.3s;
  a {
    background-color: rgba($color: #fff, $alpha: .2);
    color: #fff!important;
  }
}
.snapContainer{
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
  overflow-y: hidden;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: all 0.04s ease;
}
.item {
  scroll-snap-align: end;
  max-width: none;
  transition: all 0.1s;
}
.price {
  padding: 5px;
  border-radius: 3px;
  width: 65px;
  text-align: center;
  background: #3363a1;
}
.down {
  background: #2ebd85;
}
.up {
  background: #f6465d;
}
.homePrices {
  h1 {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 6px;
  }
  .tabTitles {
    list-style: none;
    display: flex;
    border: 1px solid #808080;
    border-radius: 6px;
    li {
      width: 100%;
      border-right: 1px solid #808080;
      display: grid;
      place-items: center;
      padding-top: 4px;
      padding-bottom: 4px;
      a {
        border-radius: 5px;
        display: block;
        padding-top:4px;
        padding-bottom:4px;
        font-size: 12px;
        padding-left: 8px;
        padding-right: 8px;
        width: calc(100% - 10px);
        text-align: center;
      }
      .active {
        color:#fff;
      }
    }
    li:last-child {
      border-right: none;
    }
  }
}
.priceRow {
  cursor: pointer;
  pointer-events: all;
  display:grid;
  width: 100%;
  grid-template-columns: auto auto 92px;
  font-size: 11px;
  text-transform: uppercase;
  border-bottom: 1px solid #4e5472;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 49px;
  overflow: hidden;
  .rate,.change {
    text-align: right;
  }
  .rate {
    display: flex;
    align-items: center;
    gap: 5px;
    place-content: flex-end;
  }
  .big {
    font-size: 14px;
  }
}
.priceRow:hover, .priceRow.active {
  background-color: rgba($color: #fff, $alpha: .022);
}
.priceRow:nth-child(even) {
  background: #222535;
}
.graph {
  pointer-events: all;
  border-bottom: 1px solid #4e5472;
  background: rgba($color: #fff, $alpha: .022);
}
.graphContainer {
  height: 350px;
}
@media screen and (max-width: 600px) {
  .graphContainer {
    height: 260px;
  }
}
</style>
