<template>
  <div>
    <div v-if="user">
      <h2 style="font-weight:normal">{{ $t('Fiyat Girişi') }}</h2>
      <div>
        <PriceRow v-for="item in prices" :key="item.id" :row="item" :mode="'priceEntry'" />
      </div>
    </div>
  </div>
</template>

<script>
import PriceRow from './../PriceRow.vue'
export default {
  components: { PriceRow },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  data () {
    return {
      prices: [],
      activeGraph: 0,
      graphOn: false,
      enableSort: false,
      isLoaded: false
    }
  },
  mounted () {
    this.getPrices()
  },
  methods: {
    getPrices: async function () {
      this.axios.get('user-prices').then(response => {
        this.prices = response.data.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        this.isLoaded = true
      })
    }
  }
}
</script>
