<template>
  <div class="news-card" v-if="info" @mouseover="$fnc.prefetch(info, DetailRoute)">
    <div class="image">
      <router-link v-if="info.cover_image" :to="{ name: DetailRoute, params: { slug: info.slug, category: info.category.slug }}">
        <img loading="lazy" :src="cdn + JSON.parse(info.image.media).small" width="100%" :alt="info.title" :title="info.title">
      </router-link>
      <img loading="lazy" v-else width="100%">
    </div>
    <router-link :to="{ name: DetailRoute, params: { slug: info.slug, category: info.category.slug }}" class="text">
      <h3>{{ info.title }}</h3>
      <p class="date" :title="info.created_at">{{ info.date }}</p>
      <p class="description" :title="info.description">{{ info.description }}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['info', 'DetailRoute'],
  computed: {
    cdn () {
      return this.$store.state.cdn
    }
  }
}
</script>

<style scoped lang="scss">
.news-card {
  display: grid;
  grid-template-columns: 8fr 10fr;
  gap: 15px;
  margin-bottom: 12px;
}
h3 {
  margin-bottom: 5px;
  margin-top: 4px;
  font-size: 15px;
}
.date {
  font-size: 11px;
  margin-bottom: 12px;
}
.image {
  border-radius: 2px;
  img {
    border-radius: 2px;
    width: 100%;
  }
}
</style>
