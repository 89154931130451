<template>
  <div style="min-height:600px">
    <div class="container" v-if="user">
      <div class="profileLayout">
        <div class="homeSide" v-if="$store.state.width > 768">
          <ProfileMenu />
        </div>
        <div class="content">
          <router-view />
        </div>
      </div>
    </div>
    <LoginInfo v-else />
  </div>
</template>

<style lang="scss" scoped>
.profileLayout {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 350px auto;
  gap: 20px;
  min-height: 600px;
}
@media screen and (max-width: 768px) {
  .profileLayout {
    grid-template-columns: 1fr;
  }
}
.content {
  padding-top: 15px;
}
</style>

<script>
import ProfileMenu from '@/components/ProfileMenu.vue'
export default {
  components: {
    ProfileMenu
  },
  props: ['info'],
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  metaInfo () {
    return {
      title: this.$i18n.t('Profilim'),
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  }
}
</script>
