<template>
  <div>
    <div class="block" :item="item">
      <h2 class="tabTitle">{{ item.name }}</h2>
      <div class="tabTitles">
        <div v-for="(item2, idx) in item.children_latest_prices" :key="item2.id" :class="{ activeTab: activeTab === item2.id }">
          <button @click="getSubs(item2, idx)">{{ item2.name }}</button>
        </div>
      </div>
      <div class="test" style="">
        <div>
          <div v-if="priceData.length > 0" class="subTitle" style="display:flex; align-items:center;; gap: 12px">
            <div v-if="item.children_latest_prices[index2].description === 'hasCities' && priceData.length > 0" class="selectBox">
              <select ref="city" @change="filterCities(index2)">
                <option value="">Şehir Seçin</option>
                <option v-for="item in item.children_latest_prices[index2].all_prices" :key="item.value">{{ item.city.name }}</option>
              </select>
            </div>
          </div>
          <PriceBoard :isArchive="isArchive" :prices="priceData" v-if="priceData.length > 0" />
          <div style="margin-bottom:30px" class="error" v-if="item.children_latest_prices[subTab].all_prices.error">
            <div v-html="item.children_latest_prices[subTab].all_prices.error"></div>
          </div>
        </div>
        <div v-for="item2 in item.children_latest_prices[index].children_latest_prices" :key="item2.id">
          <div v-if="item2.all_prices.length > 0" style="margin-bottom:30px">
            <div class="subTitle">{{ item2.name }}</div>
            <PriceBoard :isArchive="isArchive" :prices="item2.all_prices" style="" />
          </div>
          <div v-else style="margin-bottom:30px">
            <div class="subTitle">{{ item2.name }}</div>
            <div class="error" v-if="item2.all_prices.error">
              <div v-html="item2.all_prices.error"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceBoard from '@/components/PriceBoard2.vue'

export default {
  props: ['item', 'isArchive'],
  components: {
    PriceBoard
  },
  data () {
    return {
      activeTab: 0,
      index: 0,
      index2: 0,
      priceData: [],
      subTab: 0
    }
  },
  mounted () {
    this.activeTab = this.item.children_latest_prices[0].id
    this.getSubs(this.item.children_latest_prices[0], 0)
  },
  methods: {
    filterCities: function (idx) {
      const c = this.item.children_latest_prices[idx].all_prices
      const city = this.$refs.city.value
      if (city) {
        this.priceData = c.filter(item => item.city.name === city)
      } else {
        this.priceData = c
      }
    },
    getSubs: function (item, idx) {
      this.activeTab = item.id
      this.priceData = []
      const city = this.$refs.city
      this.subTab = idx
      if (city) {
        this.$refs.city.value = ''
      }
      if (item.all_prices.length > 0) {
        if (item.all_prices) {
          this.priceData = item.all_prices
          this.index2 = idx
        }
      } else {
        this.index = idx
      }
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
  border: 1px solid red;
  background: rgba($color: #ff0000, $alpha: 0.2);
  border-radius: 6px;
  margin-top: 30px;
  text-align: center;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background:#000
}
.selectBox{
  width: 180px;
  overflow: hidden;
  border: 1px solid #000;
  position: relative;
  padding: 4px 0;
  background: #000;
  display: inline-block;
}
.selectBox:after{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  top: 40%;
  right: 5px;
  content: "";
  z-index: 98;
 }
.selectBox select{
  width: 150px;
  border: 0;
  position: relative;
  z-index: 99;
  background: #000;
  color: #fff;
  margin-left: 4px;
}
.block {
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .block {
    padding: 0;
  }
}
.subTitle {
  display:block;
  // border-bottom: 1px solid red;
  font-size: 13px;
  padding-left: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: rgba($color: #fff, $alpha: .2);
  border-radius: 2px;
}
.activeTab {
  transition: all 0.3s;
  button {
    // background-color: rgba($color: #fff, $alpha: .2) !important;
    background: orangered !important;
    background: var(--color1) !important;
    // background: var(--primary2) !important;
    color: #fff!important;
  }
}
.tabTitle {
  font-size: 18px;
}
.tabTitles {
  list-style: none;
  display: flex;
  gap: 10px;
  border-radius: 6px;
  padding-top:8px;
  padding-bottom:8px;
  flex-wrap: nowrap;
  div {
    // border-right: 1px solid #444444;
    display: grid;
    white-space: nowrap;
    place-items: center;
    width: 100%;
    button {
      width: 100%;
      border-radius: 3px;
      display: block;
      padding-top:4px;
      padding-bottom:4px;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;
      background: none;
      border: none;
      color: white;
      background-color: rgba($color: #fff, $alpha: .05);
    }
    .active {
      color:#fff;
    }
  }
  li:last-child {
    border-right: none;
  }
}
</style>
