<template>
  <div class="loading-content">
    <div class="p" v-for="n in pCount" :key="n"></div>
  </div>
</template>

<script>
export default {
  props: ['pCount']
}
</script>

<style scoped>
.loading-content .p{
  width: 100%;
  height: 16px;
  border-radius: 4px;
  background: rgba(255,255,255,0.06);
  margin-bottom: 16px;
  filter: blur(1px);
}
</style>
