<template>
  <div class="pageLayout" style="min-height: calc(100vh - 100px)">
    <LeftSide v-if="$store.state.width > 768 && test === 'fuck'" />
    <div v-if="page" class="pRight">
      <div>
        <h1>{{ page.title }}</h1>
        <h2 v-if="page.description" class="description">{{ page.description }}</h2>
        <img loading="lazy" style="margin-bottom:12px; border-radius:8px; aspect-ratio: 78/19;"
        :style="'background-image:url(' + cdn + JSON.parse(page.image.media).small + '); background-size:cover'"
        :alt="page.title" :title="page.title" width="100%" v-if="page.image" :src="cdn + JSON.parse(page.image.media).large">
        <div class="ql-editor" v-html="page.content"></div>
        <SimpleForm v-if="page.form" :form="page.form" :cls="'pageBlock'" />
        <div v-else> </div>
        <div v-if="page.title === 'Yazarlar'" style="display:flex; flex-direction:column; justify-content: space-between;min-height:390px">
          <div>
            <ProfileCard v-for="item in writers.data" :key="item.id" :info="item" />
          </div>
          <div style="display:grid; place-items:center">
            <SimplePager />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pRight"></div>
  </div>
</template>

<script>
import SimpleForm from '@/small-components/SimpleForm'
import LeftSide from '@/small-components/LeftSide'
import SimplePager from '@/components/SimplePager'
export default {
  props: ['hideTitle'],
  components: { SimpleForm, LeftSide, SimplePager },
  data: function () {
    return {
      cdn: this.$store.state.cdn,
      page: null,
      writers: null,
      links: [],
      activePage: 1,
      test: 'noFuck'
    }
  },
  mounted: function () {
    this.getPage()
  },
  watch: {
    $route: function () {
      this.page = null
      this.getPage()
    }
  },
  methods: {
    getPage: async function () {
      const slug = this.$route.params.slug
      await this.axios.get('page/' + slug).then(response => {
        this.page = response.data
        if (this.page.title === 'Yazarlar') {
          this.getWriters()
        }
      })
    },
    getWriters: function () {
      const page = this.$route.query.page || 1
      this.axios.get('top-writers?page=' + page).then(response => {
        this.writers = response.data
        this.links = response.data.meta.links
        this.activePage = response.data.current_page
      })
    }
  }
}
</script>
