import { render, staticRenderFns } from "./FixedNavbar.vue?vue&type=template&id=2178a5ff&scoped=true&"
import script from "./FixedNavbar.vue?vue&type=script&lang=js&"
export * from "./FixedNavbar.vue?vue&type=script&lang=js&"
import style0 from "./FixedNavbar.vue?vue&type=style&index=0&id=2178a5ff&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2178a5ff",
  null
  
)

export default component.exports