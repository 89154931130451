<template>
  <div class="commentCard" v-if="!isDeleted">
    <div class="profileCard">
      <div class="avatar">
        <img v-if="info.user.image" :src="cdn + JSON.parse(info.user.image.media).thumb">
        <img v-else :src="cdn + 'default.png'">
      </div>
      <div class="user">
        <h3>@{{ info.user.nick }}</h3>
        <span class="date"><i class="fa fa-calendar-alt"></i>{{ info.date }}</span>
      </div>
      <CommentContext :info="info" :user="user" />
    </div>
    <div v-if="info.image" class="commentImage">
      <img loading="lazy" :src="cdn + JSON.parse(info.image.media)[imageSize]" />
    </div>
    <div class="comment" v-html="info.comment"></div>
    <div class="actions">
      <button class="btn1" @click="likeComment(info.id)">
        <i class="fas fa-thumbs-up"></i>
        <span>{{ $t('Like') }}</span>
        <span v-if="info.likes_count">({{ info.likes_count }})</span>
      </button>
      <button class="btn1" @click="replyToComment(info.id, info.user.id)">
        <i class="fas fa-reply"></i>
        <span>{{ $t('Reply') }}</span>
        <span v-if="info.all_replies_count > 0">({{ info.all_replies_count }})</span>
      </button>
    </div>
    <div class="writeComment" v-if="user && showReply && replyId === info.id">
      <i class="fa fa-camera" style="margin-right:12px;"></i><input @change="uploadFile" ref="file" type="file" accept="image/png, image/jpeg, image/gif" style="border:none;margin-bottom:12px" placeholder="Görsel Seçin" >
      <textarea v-model="newComment" placeholder="Yorumunuzu buraya yazın"></textarea>
      <div class="actions">
        <div class="contract">
          <div style="display:flex;align-items:center; gap:5px">
            Bu sayfalarda yer alan okur yorumları kişilerin kendi görüşleridir. Yazılanlardan gunceldemir.com sorumlu tutulamaz.</div>
        </div>
        <button @click="sendComment" class="btn1 c1">Gönder</button>
      </div>
    </div>
    <div class="replies" v-for="item in info.replies" :key="item.id">
      <div v-if="show" class="reply" :ref="'reply' + item.id">
        <div class="avatar">
          <img v-if="item.user.image" :src="cdn + JSON.parse(item.user.image.media).thumb">
          <img v-else :src="cdn + 'default.png'">
        </div>
        <div class="commnet">
          <div class="user">
            <div style="display:flex; align-items:center; justify-content:space-between">
              <div>
                <h3>@{{ item.user.nick }}</h3>
                <span class="date"><i class="fa fa-calendar-alt"></i>{{ item.date }}</span>
              </div>
              <CommentContext :isReply="true" :info="item" :user="user" />
            </div>
          </div>
          <div v-if="item.image" class="commentImage">
            <img loading="lazy" :src="cdn + JSON.parse(item.image.media)[imageSize]" />
          </div>
          <div style="display:grid;" class="comment">
            <span v-if="item.mentioned_user_id" style="margin-top:3px;color:teal"><i class="fa fa-bullhorn"></i> @{{ item.mentioned_user.nick }}</span>
            <span v-html="item.comment"></span>
          </div>
          <div class="actions">
            <button class="btn1" @click="likeComment(item.id, true)">
              <i class="fas fa-thumbs-up"></i>
              <span>{{ $t('Like') }}</span>
              <span v-if="item.likes_count">({{ item.likes_count }})</span>
            </button>
            <button class="btn1" @click="replyToComment(item.id, item.user.id)">
              <i class="fas fa-reply"></i>
              <span>{{ $t('Reply') }}</span>
            </button>
          </div>
          <div class="writeComment" v-if="user && showReply && replyId === item.id">
            <i class="fa fa-camera" style="margin-right:12px;"></i><input @change="uploadFile" ref="file" type="file" accept="image/png, image/jpeg, image/gif" style="border:none;margin-bottom:12px" placeholder="Görsel Seçin" >
            <textarea v-model="newComment" placeholder="Yorumunuzu buraya yazın"></textarea>
            <div class="actions">
              <div class="contract">
                <div style="display:flex;align-items:center; gap:5px">
                  Bu sayfalarda yer alan okur yorumları kişilerin kendi görüşleridir. Yazılanlardan gunceldemir.com sorumlu tutulamaz.</div>
              </div>
              <button @click="sendComment" class="btn1 c1">Gönder</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="info.all_replies_count > 3" style="margin-top:8px; font-size:12px;display:none;">Tüm yanıtları gör</div>
  </div>
</template>

<script>
import CommentContext from './CommentContext.vue'
export default {
  components: {
    CommentContext
  },
  props: ['info'],
  data () {
    return {
      show: true,
      showReply: false,
      newComment: '',
      image: null,
      commentType: this.info.type,
      contentId: this.info.content_id,
      error: false,
      parent_id: this.info.id,
      errorType: '',
      isDeleted: false,
      replyId: null,
      mentionedUser: null
    }
  },
  methods: {
    likeComment: async function (id, isReply = false) {
      const payload = {
        type: 1
      }
      if (this.user) {
        this.axios.post('/like-comment/' + id, payload).then(response => {
          if (!isReply) {
            this.info.likes_count = response.data.likes_count
          } else {
            this.info.replies.find(item => item.id === id).likes_count = response.data.likes_count
          }
        })
      } else {
        const data = {
          title: this.$t('Login'),
          message: this.$t('To like comments you must logged in'),
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
      }
    },
    uploadFile: function () {
      this.image = this.$refs.file.files
    },
    sendComment: async function () {
      if (this.user) {
        if (!this.newComment) {
          this.$toast.error('Yorumunuzu yazınız.')
        } else {
          let image = ''
          if (this.image) {
            image = this.image[0]
          }
          const payload = {
            comment: this.newComment,
            type: this.commentType,
            content_id: this.contentId,
            parent_id: this.parent_id,
            mentioned_user_id: this.mentionedUser,
            cover: image
          }
          const formData = new FormData()
          for (const key in payload) {
            formData.append(key, payload[key])
          }
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          await this.axios.post('/new-comment', formData, config).then(response => {
            this.$toast.success('Yorumunuz gönderildi.')
            this.newComment = ''
            const data = response.data
            this.$parent.getComments(data.content_id, data.type)
            this.image = null
            this.$refs.file.value = ''
            this.showReply = false
          }).catch(err => {
            let message = ''
            if (err.response.status === 413) {
              message = this.$t('Yüklediğiniz dosya boyutu çok büyük. En fazla 1MB olabilir.')
            } else {
              message = err.response.data.message
            }
            this.$toast.error(message)
          })
        }
      }
    },
    replyToComment (id, mentionetUser = null) {
      if (!this.user) {
        const data = {
          title: this.$t('Login'),
          message: this.$t('To reply comments you must logged in'),
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
      } else {
        this.showReply = !this.showReply
        if (id) {
          this.mentionedUser = mentionetUser
          this.replyId = id
        }
      }
    }
  },
  computed: {
    cdn () {
      return this.$store.state.cdn
    },
    user () {
      return this.$store.state.user
    },
    imageSize () {
      if (this.$parent.imageSize) {
        return this.$parent.imageSize
      } else {
        return 'small'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.commentImage {
  margin-top: 12px;
}
.writeComment {
  margin-top: 20px;
  margin-bottom: 20px;
  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #000;
    background: none;
    background: rgba(255,255,255,0.1);
    padding: 10px;
    color: white;
    resize: none;
  }
  .contract {
    font-size: 11px;
  }
  .actions {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
.commentImage{
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
  img {
    max-width: 100%;
    border-radius: 8px;
  }
}
.replies {
  padding-top: 20px;
  margin-top: 12px;
  border-top: 1px solid #ccc;
  .reply {
    display: grid;
    grid-template-columns: 45px auto;
    align-items: top !important;
  }
}
.profileCard {
  display: grid;
  grid-template-columns: 30px auto 30px;
  gap: 12px;
  margin-bottom: 8px;
  align-items: center;
}
.commentCard {
  background: rgba($color: #fff, $alpha: 0.03);
  margin-bottom: 20px;
  padding: 12px;
  border-radius: 12px;
}
.comment {
  font-size: 12px;
  line-height: 22px;
  color: #cccccc;
}
.commentCard:hover {
  background: rgba($color: #fff, $alpha: 0.06);;
}
.avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border: 2px solid rgba($color: #fff, $alpha: 0.5);
  img {
    width: 100%;
  }
}
h3 {
  font-size: 12px;
  color: azure;
}
.info {
  margin-top: 4px;
  font-size: 11px;
  color: #999;
  display: flex;
  gap: 6px;
}
.actions {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
  display: flex;
  gap: 10px;
  justify-items: right;
  font-size: 12px;
}
.btn1 {
  margin:0;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: unset;
  display: flex;
  gap: 5px;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.3);
  font-family: var(--font3);
  font-weight: normal;
  i {
    font-size: 13px;
  }
}
.btn1:hover {
  background: #000;
}
.date {
  font-size: 10px;
  display: flex;
  gap: 4px;
  margin-top: 4px;
  color: #999;
  i {
    font-size: 9px;
  }
}
</style>
