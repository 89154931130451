<template>
  <div>
    <div class="container" v-if="isReady">
      <div class="flex top">
        <div>
          <h1>{{ page.title }}</h1>
          <div class="main-descr" v-html="page.content"></div>
        </div>
        <div class="center">
          <img width="280px" :alt="page.title" :src="cdn + JSON.parse(page.image.media).small">
        </div>
      </div>
      <div class="flex mt20" style="">
        <div class="card" v-for="item in roles" :key="item.id">
          <div class="mb20">
            <div v-if="platform !== 'web'"><StoreProduct v-for="p in item.store_products" :key="p.id" :item="p" /></div>
            <h2 class="mt20">{{ item.name }}</h2>
            <div class="main-descr li2 mt20" v-html="item.content"></div>
          </div>
          <Pricer :item="item" v-if="platform === 'web'" />
        </div>
      </div>
      <div class="flex mt20">
        <div class="description" v-html="page.description"></div>
      </div>
      <div class="mt20 mb20 faqs">
        <h1>{{ faq.cat.name }}</h1>
        <div>
          <div class="item" v-for="item in faq.items" :key="item.id">
            <h2 @click="selectAnswer(item.id)">
              <span>{{ item.title }}</span>
              <i v-if="item.id !== activeAnswer" class="fa fa-angle-down"></i>
              <i v-else class="fa fa-times"></i>
            </h2>
            <div class="answer" :id="'answer' + item.id">
              <div class="ql-editor" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import store from '../store'
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2'
import Pricer from '@/components/Pricer.vue'
import StoreProduct from '@/components/StoreProduct.vue'
var vm = new Vue({
  store
})
export default {
  components: { Pricer, StoreProduct },
  data () {
    return {
      activeAnswer: null,
      showPricer: false,
      isReady: false,
      inap: {
        test: 'test'
      },
      faq: {
        items: [],
        cat: {
          name: 'Soru & Cevap'
        }
      }
    }
  },
  computed: {
    storeProducts () {
      return this.$store.state.storeProducts
    },
    cdn () {
      return this.$store.state.cdn
    },
    roles () {
      return this.$store.state.siteInfo.memberships
    },
    page () {
      return this.$store.state.siteInfo.membershipPage
    },
    platform () {
      return localStorage.getItem('platform') ?? 'web'
    }
  },
  beforeMount () {
    this.iap()
  },
  mounted () {
    this.getFaq()
  },
  methods: {
    buyProduct: function (id) {
      const store = InAppPurchase2
      store.order(id)
    },
    buyProductWeb: function (id) {
      this.$toast.success('ID: ' + id)
    },
    iap: function () {
      // store.verbosity = store.DEBUG
      if (this.platform === 'web') {
        this.isReady = true
        return
      }
      const store = InAppPurchase2
      const storeProducts = this.storeProducts
      if (!storeProducts) {
        this.roles.forEach(s => {
          s.store_products.forEach(e => {
            store.register([{ id: e.product_id, type: store[e.type] }])
          })
        })
        store.refresh()
        const x = store.products
        this.$store.dispatch('setStoreProducts', x)
        this.isReady = true
        this.checkProducts('first')
      } else {
        this.isReady = true
        this.checkProducts('second')
      }
    },
    checkProducts: function (f) {
      const store = InAppPurchase2
      const x = store.products
      x.forEach(s => {
        store.when(s.id).approved(function (product) {
          product.finish()
          const payload = {
            product: product
          }
          setTimeout(() => {
            vm.axios.post('check-subscribe', payload).then(response => {
              const data = response.data
              if (!data.notified) {
                const payload2 = {
                  id: data.id
                }
                vm.axios.post('mark-subscribe-notified', payload2).then(response2 => {
                  vm.$toast.success('Üyeliğiniz başarıyla aktif edildi.')
                  const now = new Date()
                  vm.$store.dispatch('setProductsChanged', now)
                })
              }
            })
          }, 1250)
        })
      })
    },
    checkOwnership: async function (product) {
      alert(product)
      const payload = {
        product: product
      }
      await this.axios.post('check-subscribe', payload).then(response => {
        this.ownership = response.data.data
      })
    },
    getFaq: async function () {
      const response = await this.axios.post('/membership-faq')
      this.faq = response.data
    },
    selectAnswer: function (id) {
      const answers = document.getElementsByClassName('answer')
      const answer = document.getElementById('answer' + id)
      if (this.activeAnswer === id) {
        this.activeAnswer = null
        answer.style.height = '0px'
        answer.style.paddingTop = '0px'
        answer.style.paddingBottom = '0px'
        return
      }
      this.activeAnswer = id
      for (let i = 0; i < answers.length; i++) {
        answers[i].style.height = '0px'
        answers[i].style.paddingTop = '0px'
        answers[i].style.paddingBottom = '0px'
      }
      setTimeout(() => {
        answer.style.height = answer.scrollHeight + 20 + 'px'
        answer.style.padding = '10px'
      }, 10)
    }
  }
}
</script>

<style lang="scss">
.buyButton {
   margin:auto; border-radius:32px;margin-top:12px;
}
.main-descr {
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    gap: 12px;
    li {
      color: #59627d;
      font-weight: 400;
      font-size: 14px;
      font-family: var(--font3);
      display: grid;
      grid-template-columns: 8px auto;
      grid-gap: 8px;
      padding: 0px;
      margin: 0px;
      margin-bottom: 8px;
      margin-right: 8px;
      align-items: center;
    }
    li::before {
      content: "";
      background-color: teal;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      display: inline-block;
      margin-top: 0px;
    }
  }
}
.li2 {
  ul {
    flex-direction: column;
    gap: 4px;
    li {
      display: grid;
      grid-template-columns: 8px auto;
      grid-gap: 8px;
      text-align: left;
      color: white;
    }
    li::before {
      background-color: orange;
    }
  }
}
@media screen and (max-width: 768px) {
  .main-descr {
    padding-left: 20px;
    padding-right: 20px;
    ul {
      display: grid;
      li {
        justify-content: center;
        display: flex;
        gap: 8px;
      }
      li::before {
        // display: none;
      }
    }
  }
  .li2 {
    ul {
      flex-direction: column;
      gap: 4px;
      li {
        display: grid;
        grid-template-columns: 8px auto;
        justify-content: unset;
        grid-gap: 8px;
        text-align: left;
        color: white;
      }
    }
  }
  .hiddenMobile {
    display: none !important;
  }
}

</style>

<style lang="scss" scoped>
.top {
  min-height: 300px; justify-content:center;gap: 50px;
}
h1 {
  font-size: 48px;
  font-family: var(--font3);
  letter-spacing: -1.4px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
  font-weight: 200;
}
.flex {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
}
.card {
  background: var(--color2);
  font-family: var(--font2);
  box-shadow: 0px 0px 0px 6px rgba(255,255,255 ,0.1);
  h2 {
    font-weight: 400;
  }
  padding: 20px;
  border-radius: 6px;
  width: 360px;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  transition: all 0.1s ease-out;
}
.card:hover {
  // highlight card
  box-shadow: 0px 0px 0px 6px rgba(255,255,255 ,0.4);
}
.cardActive {
  box-shadow: 0px 0px 0px 6px rgba(255,255,255 ,0.55) !important;
}
img {
  aspect-ratio: 4/3;
}
.description {
  text-align: center;
}
.faqs {
  border-radius: 12px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 90px;
  h1 {
    font-size: 22px;
  }
}
.item {
  border-bottom: 1px solid #999;
  padding: 0;
  margin:0;
  margin-bottom: 15px;
  h2 {
    font-size: 16px;
    display:flex;
    justify-content:space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid #999;
  }
  .answer {
    height: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    padding-left:10px;
    padding-right: 10px;
    .ql-editor {
      padding-left:15px;
      padding-right:10px;
      border-left: 5px solid #ccc;
    }
  }
}
@media screen and (max-width: 768px) {
  .top {
    min-height: unset;
    gap: unset;
    height: unset;
  }
  h1 {
    font-size: 32px;
    font-family: var(--font3);
    letter-spacing: -1.4px;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 200;
    margin-top: 24px;
  }
  .flex {
    display: grid;
  }
  .card {
    width: unset;
    width: calc(100vw - 30px);
    margin: auto;
    box-shadow: unset;
    padding: 0px;
    min-height: unset;
    padding-top: 20px;
    padding-bottom: 20px;

  }
  .card:hover {
    box-shadow: unset;
  }
  .faqs {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 90px;
    margin-top: 30px;
    border-top: 1px solid #606060;
    border-radius: 0;
    h1 {
      font-size: 22px;
    }
  }
}
</style>
