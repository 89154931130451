<template>
  <div class="cont">
    <div class="flex">
      <div class="priceBox" @click="choosePrice('month')" :class="{ active: activePrice === 'month' }" v-if="priceMonth > 0">
        <p class="pricer" style="margin-bottom:-5px">₺<span class="price">{{ parseFloat(priceMonth).toFixed(2) }}</span> / ay</p>
        <small class="pricer">(Aylık Ödeme)</small>
      </div>
      <div class="priceBox" @click="choosePrice('year')" :class="{ active: activePrice === 'year' }" v-if="priceYear > 0">
        <p class="pricer" style="margin-bottom:-5px">₺<span class="price">{{ parseFloat(priceYear / 12).toFixed(2) }}</span> / ay</p>
        <small class="pricer">(Yıllık Ödeme)</small>
        <small class="discount" v-if="discount > 0">%{{ discount }} İndirim</small>
      </div>
    </div>
    <div class="mt20" v-if="priceMonth > 0 || priceYear > 0">
      <button class="btn1 c5" style="margin:auto; border-radius:32px;margin-top:12px"><i class="fa fa-shield-alt"></i> Abone Olun</button>
    </div>
    <div v-else>
      Ücretsiz
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      platform: localStorage.getItem('platforn') ?? 'web',
      // platform: 'web',
      activePrice: 'month'
    }
  },
  methods: {
    choosePrice (type) {
      this.activePrice = type
    }
  },
  mounted () {
    if (this.discount > 0) {
      this.activePrice = 'year'
    }
  },
  computed: {
    discount () {
      const fark = parseFloat(parseFloat(this.priceMonth) * 12) - parseFloat(this.priceYear)
      return parseFloat((fark / (parseFloat(this.priceMonth) * 12) * 100).toFixed(0))
    },
    priceMonth () {
      if (this.platform === 'web') {
        return this.item.web_price
      }
      if (this.platform === 'ios') {
        return this.item.ios_price
      }
      if (this.platform === 'android') {
        return this.item.google_price
      }
      return 0
    },
    priceYear () {
      if (this.platform === 'web') {
        return this.item.web_price_year
      }
      if (this.platform === 'ios') {
        return this.item.ios_price_year
      }
      if (this.platform === 'android') {
        return this.item.google_price_year
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.btn1 {
  padding: 20px;
  font-size: 18px;
  justify-content: center;
  transition: all 0.34s;
}
.btn1:hover {
  background-color: var(--color6);
}
.priceBox {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  user-select: none;
  cursor: pointer;
  transition: all 0.1s;
  height: 80px;
  display: grid;
  align-items: center;
}
.priceBox:hover {
  border: 2px solid rgba(255, 255, 255, 0.3);
}
.active {
  border: 2px solid rgba(255, 255, 255, 0.4) !important;
}
.pricer {
  color: #979baa;
}
.price {
  font-size: 26px;
  color: white;
}
.cont {
  display: grid;
}
.flex {
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
}
.discount {
  background:orangered;
  color: white;
  width: 70px;
  margin: auto;
  padding: 0;
  margin-top: -3px;
  margin-bottom: 6px;
  border-radius: 5px;
  font-weight: normal;
}

</style>
