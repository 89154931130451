<template>
  <div>
    <div v-if="user">
      <h2 style="font-weight:normal">{{ $t('Parolanızı Değiştirin') }}</h2>
      <form method="dialog">
        <div class="row">
          <label>{{ $t('Yeni Parolanız') }}</label>
          <input v-model="password" type="password" autocomplete="new-password" :placeholder="$t('Yeni Parolanız')"/>
        </div>
        <div class="row" style="margin:0">
          <label>{{ $t('Yeni Parolanız Tekrar') }}</label>
          <input v-model="password_confirmation" type="password" autocomplete="new-password" :placeholder="$t('Yeni Parolanız Tekrar')"/>
        </div>
        <div class="row" style="margin-bottom:5px"><div></div><span class="subInfo">Parolanız güvenliğiniz için en az 8 karakter uzunluğunda olmalı, harf ve rakamlardan oluşmalıdır</span></div>
        <div class="row">
          <div></div>
          <button class="btn1 c1" @click="changePassword"><i class="fa fa-save"></i><span>{{ $t('Save') }}</span></button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
form {
  margin-top: 20px;
}
.row {
  grid-template-columns: 160px auto;
  gap: 10px;
  label {
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .row {
    grid-template-columns: auto;
    gap: 0;
    label {
      text-align: left;
    }
  }
}
</style>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  data () {
    return {
      password: '',
      password_confirmation: ''
    }
  },
  methods: {
    changePassword: async function () {
      const payload = {
        password: this.password,
        password_confirmation: this.password_confirmation
      }
      await this.axios.post('change-user-password/' + this.user.id, payload).then(response => {
        this.$toast.success(this.$t('Parolanız başarıyla değişti'))
        this.$store.commit('setUser', response.data.user)
        setTimeout(() => {
          this.$router.push({ name: 'ProfileSettings' })
        }, 400)
      })
    }
  }
}
</script>
