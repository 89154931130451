<template>
  <div>
    <form>
      <h1>{{ $t('Üye Olun') }}</h1>
      <div class="row">
        <label>{{ $t('Adınız Soyadınız') }}</label>
        <input @keypress.enter="$parent.register" name="name" v-model="$parent.registerForm.name" type="text" autocomplete="name" :placeholder="$t('Adınız Soyadınız')">
      </div>
      <div class="row">
        <label>{{ $t('E-Posta Adresiniz') }}</label>
        <input @keypress.enter="$parent.register" name="email" v-model="$parent.registerForm.email"  type="email" autocomplete="email" :placeholder="$t('E-Posta Adresiniz')">
      </div>
      <div class="row" v-if="$store.state.siteInfo.askPhone">
        <label>{{ $t('Cep Telefonunuz') }}</label>
        <input @keypress.enter="$parent.register" name="gsm" v-model="$parent.registerForm.gsm"  type="text" autocomplete="phone" :placeholder="$t('Cep Telefonunuz')">
      </div>
      <div class="row">
        <label>{{ $t('Parolanız') }}</label>
        <input @keypress.enter="$parent.register" name="password" v-model="$parent.registerForm.password" type="password" autocomplete="new-password" :placeholder="$t('Parolanız')">
      </div>
      <div class="row" style="display:flex; gap: 12px">
        <a @click="setPermission('membership')" style="padding: 10px">
          <i :class="[$parent.registerForm.membership ? 'far fa-check-square' : 'far fa-square']"></i>
        </a>
        <a @click="showContract(membershipInfo.params.slug)">{{ $t(membershipInfo.title)}}</a>
      </div>
      <div class="row" style="display:flex; gap: 12px">
        <a @click="setPermission('kvkk')" style="padding:10px">
          <i :class="[$parent.registerForm.kvkk ? 'far fa-check-square' : 'far fa-square']"></i>
        </a>
        <a @click="showContract(kvkkInfo.params.slug)">{{ $t(kvkkInfo.title)}}</a>
      </div>
      <div class="row">
        <button v-if="!$parent.isLoading" class="btn1 c2" @click="$parent.register" type="button"><i class="fa fa-lock"></i>{{ $t('Hesabımı Oluştur') }}</button>
        <button v-else class="btn1 c2" type="button"><span class="inlineLoading"></span>{{ $t('Lütfen Bekleyin') }}</button>
      </div>
      <div class="row center grey">veya</div>
      <AppleLogin :redirect="'ProfileSettings'" :appleClass="'whiteApple'" />
    </form>
    <GlassModal v-if="page" @close="closePage" :width="'900px'" style="color:black;">
      <template slot="title">{{ page.title }}</template>
      <template slot="body"><div class="ql-editor" style="height:calc(100vh - 140px); overflow:auto; text-align:left" v-html="page.content"></div></template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
import AppleLogin from '@/components/AppleLoginFire.vue'

export default ({
  components: {
    AppleLogin
  },
  props: ['info', 'from'],
  data () {
    return {
      name: '',
      email: '',
      pass: '',
      membership: false,
      kvkk: false,
      user: this.$store.state.user,
      membershipInfo: this.$store.state.siteInfo.contracts.membership,
      kvkkInfo: this.$store.state.siteInfo.contracts.kvkk,
      formError: '',
      page: false
    }
  },
  mounted () {
    // this.goBayilik()
  },
  methods: {
    goBayilik: function () {
      this.$router.push({ name: 'PageGroup', params: { parent: 'nettech-bayilik', id: '' } })
    },
    setPermission: function (per) {
      this.$parent.registerForm[per] = !this.$parent.registerForm[per]
    },
    closePage: function () {
      this.page = false
    },
    showContract: async function (slug) {
      console.log(slug)
      await this.axios.get('contract/' + slug).then(res => {
        this.page = res.data.page
      })
    },
    register: async function () {
      const payload = {
        name: this.name,
        email: this.email,
        pass: this.pass,
        user: this.user,
        contract: this.contract,
        messagePermission: this.messagePermission
      }
      await axios.post('/api/register.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          this.$router.push('/')
        } else {
          this.formError = err
          const field = response.data.field
          const labels = document.querySelectorAll('label')
          labels.forEach(l => {
            l.classList.remove('red')
          })
          if (field) {
            const lbl = document.querySelector('#register' + field + '> label')
            if (lbl) {
              lbl.classList.add('red')
            }
            const inp = document.querySelector('#register' + field + '> input')
            if (inp) {
              inp.select()
            }
          }
        }
      })
    }
  }
})
</script>
