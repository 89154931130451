<template>
  <div>
    <div @click.self="showGraph(row.id)" :class="{ active: row.id === $parent.activeGraph, priceRow2: isArchive }" class="priceRow" v-if="row.current_price">
      <div class="title" @click="showGraph(row.id)">
        <p class="big" v-if="row.city">
          <b>{{ row.city.name }}</b><br>
          <small style="font-weight:normal">{{ row.name }}</small>
        </p>
        <p class="big" v-else>
          {{ row.name }}<br>
        </p>
        <p>{{ row.current_price.created_at }}</p>
      </div>
      <div class="change" @click="showGraph(row.id)" v-if="!row.user_id || this.$parent.activeGraph === row.id">
        <p v-if="row.current_price.cantSee === false" class="big" style="display:flex;align-items:center;justify-content:flex-end">
          <span v-if="row.current_price.change > 0">+</span>
          <span v-else-if="row.current_price.change < 0">-</span>
          <span v-else></span>
          <span v-if="width > 400">{{ parseInt(Math.abs(row.current_price.change)) }}{{ ($fnc.curSign(row.currency)) }}</span>
          <span class="price" style="font-size:9px;padding:2px;width:30px;margin-left:8px;background:rgba(255,255,255,0.2)">% {{ row.current_price.change_percent }}</span>
        </p>
        <p v-if="row.current_price.cantSee === false">{{ row.description }}</p>
      </div>
      <div @click.self="showGraph(row.id)" v-else class="change" style="display:flex;justify-content:flex-end;align-items:center;">
        <button @click="showOfferDialog(row)" class="btn1 c5" style="height:27px;margin-top:0px">Teklif Al</button>
      </div>
      <div v-if="row.current_price.cantSee === true" v-html="row.current_price.message" class="bannedPrice"></div>
      <div class="rate" v-else>
        <p class="big price" @click="showGraph(row.id)" style="margin-right:4px;height:27px" :class="{ down: row.current_price.change < 0, up: row.current_price.change > 0, closed: row.current_price.is_open === 0 }">{{ $n(row.current_price.price, 'currency', $fnc.cur(row.currency)).slice(0, -3) }}</p>
        <div v-if="mode !== 'priceEntry'">
          <button v-if="!inList" style="height:27px" @click="addPriceToMyList(row)" class="btn1 c1"><i class="fa fa-plus"></i></button>
          <button v-else style="height:27px" @click="addPriceToMyList(row)" class="btn1 c2"><i class="fa fa-minus"></i></button>
        </div>
        <div v-else>
          <button @click="showPriceEntry(row)" class="btn1 c3" style="padding:3px">{{ $t('Fiyat Gir')}}</button>
        </div>
        <div v-if="!isArchive">
          <i v-if="row.id !== $parent.activeGraph" class="fa fa-angle-down" style="color:white; padding-left:5px; padding-right: 5px;" @click="showGraph(row.id)" ></i>
          <i v-else class="fa fa-angle-up" @click="showGraph(row.id)" style="color:white; padding-left:5px; padding-right: 5px;" ></i>
        </div>
      </div>
    </div>
    <div class="graph" style="min-height:120px;display:grid; place-items:center" v-if="$parent.errorMessage && $parent.activeGraph === row.id">
      <MemberError :message="$parent.errorMessage" />
    </div>
    <div class="graph" v-if="$parent.activeGraph === row.id && $parent.graphOn">
      <div>
        <div style="padding:8px; display:grid; gap:12px">
          <div v-if="!isArchive" style="display:flex; justify-content:space-between;align-items:center;">
            <router-link :to="{ name: 'PriceDetails', params: { parent: 'fiyat-detay', id: row.id + '-' + $fnc.seo(row.name) }}" style="width:98px" class="btn1 c3">
              <i class="fa fa-chart-area"></i>{{ $t('Arşive Git') }}
            </router-link>
            <button v-if="row.user_id" @click="showOfferDialog(row)" class="btn1 c5" style="height:27px;margin-top:0px">Teklif Al</button>
          </div>
          <div v-if="isArchive" style="display:flex; gap:12px;margin-top:6px">
            <div v-for="item in compList" :key="item.id">
              <button :class="{ c3: activeComp === item.price.id }" @click="selectActiveComp(item.price.id)" style="font-size:16px" class="btn2">{{ item.price.name }}</button>
            </div>
          </div>
          <div style="display:flex; gap: 12px">
            <button class="btn2" :class="{ c4: activeDay === day }" v-for="day in days" :key="day" @click="getDays(row.id, day, false)">{{ day }} Gün</button>
            <button v-if="isArchive" @click="showDatePicker" :class="{ c4: byDate }" class="btn2"><i class="fa fa-calendar"></i>Tarih Aralığı</button>
          </div>
        </div>
        <div class="graphContainer">
          <apexchart v-if="$parent.graphOn" height="100%" :options="options" :series="series"></apexchart>
        </div>
        <div style="display:flex; justify-content:flex-end">
          <button @click="share" class="btn1 c2" style="margin:5px;margin-bottom:12px;"><i class="fa fa-share"></i> {{ $t('Paylaş') }}</button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="priceEntry" @click.self="hidePriceEntry">
      <div class="" style="background:#fff;color:#000; padding:10px;border-radius:5px">
        <div style="width:320px">
          <h2>{{ priceEntry.name }} Fiyatını güncelleyin</h2>
          <div class="row">
            <label>Önceki Fiyat</label>
            <div style="padding:8px;width:160px;border-radius:8px; border:1px solid #f0f0f0; color:#666">{{ priceEntry.prev_price }}</div>
            <span>{{ priceEntry.currency }}</span>
          </div>
          <div class="row">
            <label>Güncel Fiyat</label>
            <input style="padding:8px; width:160px" type="text" v-model="priceEntry.price" placeholder="Fiyat girin">
            <span>{{ priceEntry.currency }}</span>
          </div>
          <div class="row">
            <label>Fiyat Açık / Kapalı</label>
            <input style="padding:8px; width:160px" type="checkbox" v-model="priceEntry.is_open">
          </div>
          <div class="row" style="border:none;margin:0">
            <button class="btn1 c2" @click="hidePriceEntry">{{ $t('Vazgeç') }}</button>
            <button class="btn1 c1" @click="saveNewPriceEntry">{{ $t('Kaydet') }}</button>
          </div>
        </div>
      </div>
    </div>
    <GlassModal v-if="offerDialog" @close="closeOfferDialog" style="color:black">
      <template slot="title">{{ $t('Fiyat Teklifi Alın') }}</template>
      <template slot="body">
        <div class="offerActions" v-if="offerDialog.merchant">
          <button @click="getOffer('wa')" class="whatsapp" v-if="offerDialog.merchant.whatsapp">
            <div class="contact"><span>Whatsapp</span><span>{{ $fnc.phone(offerDialog.merchant.whatsapp) }}</span></div>
            <i class="fab fa-whatsapp"></i>
          </button>
          <button @click="getOffer('ph')" class="phone" v-if="offerDialog.merchant.phone">
            <div class="contact"><span>Telefon</span><span>{{ $fnc.phone(offerDialog.merchant.phone) }}</span></div>
            <i class="fa fa-phone-alt"></i>
          </button>
          <button @click="getOffer('em')" class="email" v-if="offerDialog.merchant.email">
            <div class="contact"><span>E-Posta</span><span>{{ offerDialog.merchant.email }}</span></div>
            <i class="fa fa-envelope"></i>
          </button>
        </div>
        <div v-else>
          Henüz tüccar tanımı yapılmamış. Lütfen daha sonra tekrar deneyin.
        </div>
      </template>
    </GlassModal>
    <GlassModal v-if="datePicker" @close="hideDatePicker" style="color:black">
      <template slot="body">
        <form style="display:grid; grid-template-columns:1fr 1fr; grid-gap:12px;">
          <div style="text-align:left;width: 100%" class="row">
            <label style="color: #666">{{ $t('Start Date') }}</label>
            <input style="color:#666;min-width:95%" type="date" v-model="startDate">
          </div>
          <div style="text-align:right; width:100%;" class="row">
            <label style="color: #666">{{ $t('End Date') }}</label>
            <input style="color:#666;min-width:95%" type="date" v-model="endDate">
          </div>
        </form>
        <div class="rows">
          <button @click="getByDate" class="btn1 c1">{{ $t('Filter') }}</button>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
const date = new Date()
const days = 720
const tr = require('apexcharts/dist/locales/tr.json')

export default {
  props: ['row', 'mode', 'isArchive'],
  data () {
    return {
      width: 0,
      offerDialog: false,
      priceListConfirm: false,
      message: '',
      priceEntry: false,
      days: [5, 15, 30],
      activeDay: 45,
      datePicker: false,
      startDate: this.$fnc.date2(new Date(date.getTime() - (days * 24 * 60 * 60 * 1000))),
      endDate: this.$fnc.date2(new Date(date.getTime())),
      byDate: false,
      activeComp: 0,
      options: {
        height: 250,
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          },
          offsetY: 5,
          itemMargin: {
            horizontal: 10,
            vertical: 10
          }
        },
        chart: {
          locales: [tr],
          defaultLocale: 'tr',
          background: '#1c1e28',
          type: 'area',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              export: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [
                {
                  name: 'closeCraph',
                  title: this.$t('Grafiği Kapat'),
                  icon: '<i class="fas fa-times"></i>',
                  click: function (chart, options, e) {
                    this.showGraph(0)
                  }.bind(this)
                }
              ]
            },
            export: {
              csv: {
                filename: 'test',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value'
              }
            }
          }
        },
        stroke: {
          curve: 'straight',
          width: 2,
          colors: ['#00bcd4', '#00E396']
        },
        fill: {
          opacity: 0.4,
          colors: ['#034a6c', '#1c1e28'],
          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.9,
            opacityFrom: 0.7,
            opacityTo: 0.5,
            stops: [0, 90, 100]
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          fillSeriesColor: false,
          shared: true
        },
        xaxis: {
          type: 'datetime',
          convertCatToNumber: false,
          categories: ['1ocak'],
          labels: {
            show: true,
            style: {
              colors: '#ccc',
              // fontSize: '12px',
              // fontFamily: 'Helvetica, Arial, sans-serif',
              // fontWeight: 400,
              // cssClass: 'apexcharts-xaxis-label',
              textShadow: true
            }
          }
        },
        yaxis: [
          {
            axisTicks: {
              show: false
            },
            showAlways: false,
            decimalsInFloat: 0,
            opposite: true,
            labels: {
              show: true,
              style: {
                colors: '#00bcd4',
                textShadow: true
              }
            }
          },
          {
            opposite: false,
            labels: {
              show: false,
              style: {
                colors: '#00E396',
                textShadow: true
              }
            }
          }
        ]
      },
      series: []
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    inList () {
      // if in array
      if (this.$store.state.siteInfo.myPrices.find(item => item === this.row.id)) {
        return true
      }
      return false
    },
    compList () {
      return this.$store.state.siteInfo.compList
    }
  },
  mounted () {
    this.width = this.$el.offsetWidth
    if (this.isArchive) {
      this.options.chart.toolbar = false
      this.options.yaxis[1].labels.show = true
      this.days = [15, 30, 60, 90, 180]
      this.activeComp = this.compList[0].price.id
      this.getDays(this.row.id, 90)
    }
  },
  methods: {
    showOfferDialog: async function (price) {
      const payload = {
        priceId: price.id,
        merchantId: price.user_id
      }
      await this.axios.post('merchant-info', payload).then(response => {
        this.offerDialog = response.data
      })
    },
    closeOfferDialog: function () {
      this.offerDialog = false
    },
    getOffer: async function (type) {
      const merchant = this.offerDialog.merchant
      const price = this.offerDialog.price
      const message = 'Merhaba ' + price.name + ' hakkında fiyat teklifi almak istiyorum'
      await this.axios.post('get-offer', {
        priceId: price.id,
        merchantId: merchant.id,
        type: type
      })
      if (type === 'wa') {
        // "https://wa.me/whatsappphonenumber/?text=urlencodedtext
        window.location.replace('https://wa.me/' + merchant.whatsapp + '?text=' + encodeURIComponent(message))
      } else if (type === 'ph') {
        // redirect to phone
        window.location.replace('tel:' + merchant.phone)
      } else if (type === 'em') {
        // send email
        window.location.replace('mailto:' + merchant.email + '?subject=Fiyat Teklifi&body=' + encodeURIComponent(message))
      }
      this.closeOfferDialog()
    },
    share: function () {
      const path = window.location.pathname
      const url = this.$store.state.appUrl + path
      this.$root.share(this.row.name, this.row.name, url, 'Paylaş')
    },
    selectActiveComp (id) {
      this.activeComp = id
      this.getDays(this.row.id, this.activeDay, this.byDate)
    },
    getByDate () {
      this.byDate = true
      this.$parent.byDate = true
      this.$parent.startDate = this.startDate
      this.$parent.endDate = this.endDate
      this.getDays(this.row.id, this.activeDay, true)
      this.datePicker = false
      this.activeDay = false
    },
    showDatePicker () {
      this.datePicker = true
    },
    hideDatePicker () {
      this.datePicker = false
    },
    goArchive () {
      console.log(this.$parent.activeGraph)
      // this.$router.push({ name: 'archive', params: { id: this.row.id } })
    },
    saveNewPriceEntry: async function () {
      const payload = {
        id: this.priceEntry.id,
        price: this.priceEntry.price,
        is_open: this.priceEntry.is_open
      }
      await this.axios.post('add-new-price-entry', payload).then(response => {
        this.hidePriceEntry()
        this.$parent.getPrices()
        this.$toast.success('Fiyat güncellendi')
      }).catch(error => {
        this.$toast.error(error.response.data.message)
      })
    },
    hidePriceEntry: function () {
      this.priceEntry = false
    },
    showPriceEntry: function (item) {
      // this.priceEntry = item
      this.priceEntry = {
        name: item.name,
        id: item.id,
        price: item.current_price.price,
        prev_price: item.current_price.prev_price,
        is_open: item.current_price.is_open,
        currency: item.currency
      }
      console.log(this.priceEntry)
    },
    showGraph: async function (id) {
      if (this.$parent.activeGraph === id) {
        id = 0
      }
      if (this.isArchive) {
        return
      }
      this.$parent.graphOn = false
      this.$parent.activeGraph = id
      this.series = []
      this.$parent.errorMessage = false
      const cantSee = this.row.current_price.cantSee
      if (cantSee) {
        this.$parent.errorMessage = this.row.current_price.message
        return
      }
      if (id) {
        await this.axios.get('price/graph/' + id).then(res => {
          this.series = res.data.data
          this.$parent.graphOn = true
        })
      }
    },
    getDays: async function (id, days, byDate) {
      this.activeDay = days
      this.$parent.activeDay = days
      // this.$parent.graphOn = false
      this.series = []
      const payload = {
        id: id,
        days: days,
        byDate: byDate
      }
      if (byDate) {
        payload.startDate = this.startDate
        payload.endDate = this.endDate
      }
      if (this.activeComp) {
        payload.activeComp = this.activeComp
      }
      await this.axios.get('price/graph/' + id, { params: payload }).then(res => {
        console.log(res.data)
        if (res.data.cantSee) {
          return
        }
        this.series = res.data.data
        this.options.xaxis.categories = res.data.dates
        this.$parent.graphOn = true
        this.$parent.priceArchive = res.data.data[0]
        this.$parent.priceArchive2 = res.data.data[1]
        if (!byDate) {
          this.byDate = false
          this.$parent.byDate = false
        }
      })
    },
    addPriceToMyList: async function (row) {
      await this.axios.post('add-to-my-price-list/' + row.id).then(response => {
        this.message = response.data
        // set my prices
        this.updateMyPrices()
        this.$store.commit('setMyPrices', response.data.prices)
      }).catch(error => {
        const data = {
          title: 'Fiyat listeme ekle',
          message: error.response.data.message,
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
      })
    },
    updateMyPrices: async function () {
      await this.axios.get('my-prices').then(response => {
        this.$store.commit('setMyPricesList', response.data)
      })
    },
    hideModal: function () {
      this.priceListConfirm = false
    }
  }
}
</script>

<style scoped lang="scss">
.bannedPrice {
  text-align: center;
  text-transform: none;
  padding: 3px;
  font-weight: normal;
}
.offerActions {
  display:grid;
  gap: 8px;
  button {
    border:none;
    background: #222535;
    padding: 7px;
    color: #fff;
    font-size:14px;
    border-radius: 5px;
    display:grid;
    grid-template-columns: auto 30px;
    text-align: left;
    gap: 4px;
    align-items: center;
    .contact {
      display:grid;
      gap: 10px;
      grid-template-columns: 80px auto;
      span:first-child{
        text-align: right;
      }
    }
    i {
      text-align: center;
      font-size: 17px;
      background: #fff;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: #222535;
      display: grid;
      align-items: center;
      justify-items: center;
    }
  }
  .whatsapp {
    // whatsapp green
    background: #0ebb67;
    i {
      color: #0ebb67;
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 18px;
    margin-bottom: 18px;
  }
  .row {
    width: 100%;
    display: flex;
    gap: 12px;
    font-size: 13px;
    padding-bottom: 14px;
    margin-bottom: 14px;
    border-bottom:1px solid #ccc;
    align-items: center;
  }
}
.item {
  scroll-snap-align: end;
  max-width: none;
  transition: all 0.1s;
}
.price {
  padding: 5px;
  border-radius: 3px;
  width: 65px;
  text-align: center;
  background: #3363a1;
}
.down {
  background: #2ebd85;
}
.up {
  background: #f6465d;
}
.closed {
  background-color: grey;
}
.priceRow {
  cursor: pointer;
  user-select: none;
  display:grid;
  width: 100%;
  grid-template-columns: auto auto 142px;
  font-size: 11px;
  text-transform: uppercase;
  border-bottom: 1px solid #4e5472;
  align-items: center;
  // padding-top: 10px;
  // padding-bottom: 10px;
  height: 62px;
  overflow: hidden;
  .rate,.change {
    text-align: right;
  }
  .rate {
    display: flex;
    align-items: center;
    gap: 5px;
    place-content: flex-end;
  }
  .big {
    font-size: 14px;
  }
}
.priceRow2 {
  cursor: default;
}
.priceRow:hover, .priceRow.active {
  background-color: rgba($color: #fff, $alpha: .022);
}
.priceRow:nth-child(even) {
  background: #222535;
}
.graph {
  pointer-events: all;
  border-bottom: 1px solid #4e5472;
  background: rgba($color: #fff, $alpha: .022);
}
.graphContainer {
  height: 350px;
}
</style>
