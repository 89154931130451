<template>
  <div class="news-card" v-if="info" @mouseover="$fnc.prefetch(info, DetailRoute)">
    <div class="image">
      <router-link v-if="info.cover_image" :to="{ name: DetailRoute, params: { slug: info.slug, category: info.category.slug }}"
      :style="'background:url(' + cdn + JSON.parse(info.image.media).small +') no-repeat center;background-size:cover;display:block;'">
      </router-link>
    </div>
    <router-link :to="{ name: DetailRoute, params: { slug: info.slug, category: info.category.slug }}" class="text">
      <h3>{{ info.title }}</h3>
      <small v-if="!info.date">{{ $fnc.date(info.created_at) }}</small>
      <small v-else>{{ (info.date) }}</small>
      <p class="description" :title="info.description">{{ info.description }}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['info', 'DetailRoute'],
  computed: {
    cdn () {
      return this.$store.state.cdn
    }
  }
}
</script>

<style scoped lang="scss">
.news-card {
  overflow: hidden;
  height: calc(100% - 14px);
  border-radius: 3px;
  background: none;
}
.image {
  position: relative;
  a {
    height: 300px;
  }
}
.text {
  display: block;
  position: relative;
  padding: 10px;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  margin-top: -172px;
  height: 176px;
  border-radius: 4px;
  width: 100%;
  h3 {
    margin-bottom: 5px;
    margin-top: 50px;
  }
  p {
    height: 48px;
    line-height: 22px;
    overflow: hidden;
  }
}
</style>
