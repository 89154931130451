<template>
  <div>
    <button class="btn1" style="background:none" @click="showInlineMenu"><i class="fas fa-ellipsis-h"></i></button>
    <div class="inlineMenu" v-if="inlineMenu">
      <div class="rows" v-if="user">
        <div class="itemC" v-if="info.user.id !== user.id">
          <div @click="followUser" class="item" v-if="!inList" style="border-bottom: 1px solid #666;">
            <i class="fa fa-user-plus"></i> {{ $t('Kullanıcıyı Takip Et') }}
          </div>
          <div @click="unFollowUser" class="item" v-else style="border-bottom: 1px solid #666;">
            <i class="fa fa-user-minus"></i> {{ $t('Takipten Çık') }}
          </div>
          <div v-if="unfollowConfirm" class="confirmDelete" style="display:flex; gap:12px;padding-top:8px;margin-bottom:4px">
            <button @click="followUser(true)" class="btn1">{{ $t('İşlemi Onayla') }}</button>
            <button @click="unFollowUser" class="btn1 c2">{{ $t('Vazgeç') }}</button>
          </div>
        </div>
        <div class="item" v-if="info.user.id !== user.id" @click="reportComment">
          <i class="fa fa-flag"></i> {{ $t('Yorumu Şikayet Et') }}
        </div>
        <div v-if="commentReportOptions" class="confirmDelete" style="display:flex; flex-direction:column; gap:3px;padding-top:8px">
          <button v-for="item in commentReportOptions" :key="item.id" @click="reportCommentConfirm(item)" class="btn1">{{ item.title }}</button>
        </div>
        <div class="item" v-if="info.user.id === user.id" @click="showConfirmDelete">
          <i class="fa fa-trash-alt"></i> {{ $t('Yorumu Sil') }}
        </div>
        <div v-if="deleteConfirmation" class="confirmDelete" style="display:flex; gap:12px;padding-top:8px">
          <button @click="deleteComment" class="btn1">{{ $t('Silmeyi Onayla') }}</button>
          <button @click="closeConfirmDelet" class="btn1 c2">{{ $t('Vazgeç') }}</button>
        </div>
      </div>
      <div v-else class="rows">
        <div class="item" @click="followUser">
          <i class="fa fa-user-plus"></i> {{ $t('Kullanıcıyı Takip Et') }}
        </div>
        <div class="item" @click="reportComment">
          <i class="fa fa-flag"></i> {{ $t('Yorumuu Şikayet Et') }}
        </div>
      </div>
    </div>
    <div class="inlineBackdrop" v-if="inlineMenu" @click="closeInlineMenu"></div>
  </div>
</template>

<script>
export default {
  props: ['info', 'user', 'isReply'],
  data () {
    return {
      inlineMenu: false,
      deleteConfirmation: false,
      commentReportOptions: false,
      unfollowConfirm: false
    }
  },
  computed: {
    inList () {
      // if in array
      if (this.$store.state.siteInfo.imFollowing.find(item => item === this.info.user.id)) {
        return true
      }
      return false
    }
  },
  methods: {
    showConfirmDelete () {
      this.deleteConfirmation = true
    },
    closeConfirmDelet () {
      this.deleteConfirmation = false
    },
    reportCommentConfirm: async function (item) {
      this.commentReportOptions = false
      await this.axios.post('/report-comment', {
        comment_id: this.info.id,
        reason_id: item.id,
        reason: item.title
      }).then(response => {
        this.$toast.success(response.data.message)
        this.inlineMenu = false
      }).catch(error => {
        this.$toast.error(error.response.data.message)
      })
    },
    deleteComment: async function () {
      await this.axios.delete('/delete-comment/' + this.info.id, {
        id: this.info.id
      }).then(response => {
        this.deleteConfirmation = false
        this.inlineMenu = false
        if (!this.isReply) {
          this.$parent.isDeleted = true
        } else {
          const re = 'reply' + this.info.id
          const el = this.$parent.$refs[re]
          el[0].style.display = 'none'
          console.log(el[0])
          // console.log('fuck')
        }
      }).catch(error => {
        this.$toast.error(error.response.data.message)
      })
    },
    followUser: async function (isUnfollow) {
      if (!this.user) {
        const data = {
          title: 'Takip et',
          message: 'Bu kişiyi takip etmek için üye olmalısınız',
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
      } else {
        const payload = {
          id: this.info.user.id
        }
        await this.axios.post('follow', payload).then(response => {
          this.$store.commit('setImFollowing', response.data)
          this.unfollowConfirm = false
          this.inlineMenu = false
          if (isUnfollow === true) {
            this.$toast.success(this.$t('Takipten Çıkıldı'))
          } else {
            this.$toast.success(this.$t('Takip Ediliyor'))
          }
        }).catch(error => {
          this.$toast.error(error.response.data.message)
        })
      }
    },
    unFollowUser: async function () {
      this.unfollowConfirm = !this.unfollowConfirm
    },
    reportUser: async function () {
      if (!this.user) {
        const data = {
          title: 'Kullanıcıyı Şikayet Et',
          message: 'Lütfen giriş yapın',
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
      } else {

      }
    },
    reportComment: async function () {
      if (!this.user) {
        const data = {
          title: 'Kullanıcıyı Şikayet Et',
          message: 'Lütfen giriş yapın',
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
      } else {
        if (this.commentReportOptions === false) {
          this.commentReportOptions = this.$store.state.siteInfo.misc.reportCommentOptions
        } else {
          this.commentReportOptions = false
        }
      }
    },
    showInlineMenu () {
      this.inlineMenu = true
    },
    closeInlineMenu () {
      this.inlineMenu = false
    }
  }
}
</script>

<style scoped lang="scss">
.inlineBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.inlineMenu {
  margin-top: -5px;
  margin-left: -150px;
  position: absolute;
  background: var(--bg3);
  padding: 8px;
  border-radius: 5px;
  z-index: 999;
  width: 180px;
  .rows {
    .item {
      list-style: none;
      padding: 8px;
      font-size: 12px;
      border-bottom: 1px solid #666;
      display: grid;
      grid-template-columns: 24px 1fr;
      cursor: pointer;
      border-radius: 4px;
    }
    .item:hover {
      background: var(--bg2);
    }
    .item:last-child {
      border-bottom: none;
    }
  }
}
.btn1 {
  margin:0;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: unset;
  display: flex;
  gap: 5px;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.3);
  font-family: var(--font3);
  font-weight: normal;
  i {
    font-size: 13px;
  }
}
.btn1:hover {
  background: #000;
}
</style>
