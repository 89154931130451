<template>
  <div style="display:flex; gap: 15px;">
    <button type="button" v-if="platform === 'ios'" @click.prevent="appleLogin" class="btn1" :class="appleClass">
      <i v-if="!appleLoading" class="fab fa-apple"></i>
      <div v-else class="loading"></div>
    </button>
    <button type="button" @click.prevent="googleLogin" class="btn1 google">
      <i v-if="!googleLoading" class="fab fa-google"></i>
      <div v-else class="loading"></div>
    </button>
    <button type="button" v-if="platform !== 'ios'" @click.prevent="facebookLogin" class="btn1 facebook">
      <i v-if="!facebookLoading" class="fab fa-facebook"></i>
      <div v-else class="loading"></div>
    </button>
  </div>
</template>

<script>
// apple login component
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'

export default {
  props: ['redirect', 'appleClass'],
  data () {
    return {
      appleLoading: false,
      googleLoading: false,
      facebookLoading: false,
      active: false
    }
  },
  computed: {
    platform () {
      return localStorage.getItem('platform') ?? 'web'
    }
  },
  methods: {
    makeLogin: async function (res, provider) {
      const payload = {
        res: res,
        provider: provider
      }
      await this.axios.post('/social-login', payload).then(response => {
        this.$store.dispatch('setUser', response.data.user)
        this.$store.dispatch('setAxiosHeaderAuth', response.data.user.token)
        this.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.user.token
        if (response.data.user.tokens.length > 0) {
          this.$toast.success(this.$i18n.t('Bu cihazdan giriş yaptığınız için diğer oturumlarınız kapatıldı'))
        }
        this.$store.commit('setMyPrices', response.data.myPrices)
        this.$store.commit('setMyPricesList', response.data.myPricesList)
        this.$store.dispatch('setPermissions', response.data.permisssions)
        this[provider + 'Loading'] = false
        if (this.redirect) {
          this.$router.push({ name: this.redirect })
        } else {
          this.$parent.$emit('close')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    appleLogin: async function () {
      this.appleLoading = true
      await FirebaseAuthentication.signInWithApple().then(res => {
        this.makeLogin(res, 'apple')
      }).catch(err => {
        this.appleLoading = false
        console.log(err)
      })
    },
    googleLogin: async function () {
      this.googleLoading = true
      if (!this.active) {
        setTimeout(() => {
          this.googleLoading = false
        }, 1000)
        return
      }
      await FirebaseAuthentication.signInWithGoogle().then(res => {
        this.makeLogin(res, 'google')
      }).catch(err => {
        this.googleLoading = false
        console.log(err)
      })
    },
    facebookLogin: async function () {
      this.facebookLoading = true
      if (!this.active) {
        setTimeout(() => {
          this.facebookLoading = false
        }, 1000)
        return
      }
      await FirebaseAuthentication.signInWithFacebook().then(res => {
        this.makeLogin(res, 'facebook')
      }).catch(err => {
        this.facebookLoading = false
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  animation: spin 1s linear infinite;
  border-radius: 50%;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  width: 20px;
  height: 20px;
  display: inline-block;
  background: transparent;
}
.btn1 {
  border-radius: 25px;
  i {
    color: #fff;
  }
}
.facebook {
  background: #3b5998;
  color: #fff;
}
.google {
  background: #4285f4;
  color: #fff;
}
.apple {
  background: #000;
  color: #fff;
}
.whiteApple {
  background: #fff;
  color: #000;
}
</style>
