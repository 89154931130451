<template>
  <div>
    <div v-if="user">
      <h2 style="font-weight:normal">{{ $t('Profil Ayarlarım') }}</h2>
      <form method="dialog" ref="userSettings" autocomplete="off">
        <div class="row" style="margin-bottom:0px">
          <label>{{ $t('Nick') }}</label>
          <div class="input" v-if="user.nick">@{{ user.nick }}</div>
          <input v-else type="text" name="nick" autocomplete="off" :placeholder="$t('Kullanıcı Adınız')"/>
        </div>
        <div class="row"><div></div><span class="subInfo">Diğer kullanıcılar yalnızca rumuzunuzu görebilir</span></div>
        <div style="display:grid; grid-template-columns: auto 64px; gap: 12px;margin-bottom:20px; align-items:end">
          <div class="row" style="margin:0">
            <label>{{ $t('Telefon Numaranız') }}</label>
            <div class="input">{{ $fnc.phone(user.gsm) }}</div>
          </div>
          <div>
            <a class="btn1 c2" @click="verifyUser('gsm')" v-if="!user.phone_verified_at" style="margin-bottom:5px; display:grid;place-items:center">Onayla</a>
            <a class="btn1 c1" v-else style="margin-bottom:5px; display:grid;place-items:center">Onaylı</a>
          </div>
        </div>
        <div style="display:grid; grid-template-columns: auto 64px; gap: 12px;margin-bottom:20px; align-items:end">
          <div class="row" style="margin:0">
            <label>{{ $t('E-Posta Adresiniz') }}</label>
            <div class="input">{{ user.email }}</div>
          </div>
          <div>
            <a class="btn1 c2" @click="verifyUser('email')"  v-if="!user.email_verified_at" style="margin-bottom:5px;display:grid;place-items:center">Onayla</a>
            <a class="btn1 c1" v-else style="margin-bottom:5px;display:grid;place-items:center">Onaylı</a>
          </div>
        </div>
        <div class="row">
          <label>{{ $t('Ad Soyad') }}</label>
          <input type="text" autocomplete="new-name" name="name" v-model="user.name" :placeholder="$t('Ad Soyad')" />
        </div>
        <div class="row">
          <label>{{ $t('Firma Adı') }}</label>
          <input type="text" autocomplete="new-company" name="company_name" v-model="user.company_name" :placeholder="$t('Firma Adı')"/>
        </div>
        <div class="row">
          <label>{{ $t('İl') }}</label>
          <select style="background:rgba(0,0,0,.7)" name="city_id" v-model="user.city_id">
            <option value="0">{{ $t('İl Seçin')}}</option>
            <option v-for="item in $store.state.siteInfo.cities" :key="item.value" :value="item.value">{{ item.title }}</option>
          </select>
        </div>
        <div class="row">
          <label>{{ $t('Meslek') }}</label>
          <select style="background:rgba(0,0,0,.7)" name="job_id" v-model="user.job_id">>
            <option value="0">{{ $t('Meslek Seçin')}}</option>
            <option v-for="item in $store.state.siteInfo.jobs" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div v-if="user.is_merchant" class="extraFields">
          <div class="row">
            <label>{{ $t('Whatsapp') }}</label>
            <input type="text" autocomplete="new-phone" name="company_whatsapp" :value="$fnc.phone(user.company_whatsapp)" :placeholder="$t('Whatsapp')"/>
          </div>
          <div class="row">
            <label>{{ $t('Telefon') }}</label>
            <input type="text" autocomplete="new-phone" name="company_phone" :value="$fnc.phone(user.company_phone)" :placeholder="$t('Telefon')"/>
          </div>
          <div class="row">
            <label>{{ $t('Kurumsal E-Posta') }}</label>
            <input type="email" autocomplete="new-email" name="company_email" :value="user.company_email" :placeholder="$t('Kurumsal E-Posta')"/>
          </div>
        </div>
        <div class="row">
          <div></div>
          <button class="btn1 c1" @click="saveSettings"><i class="fa fa-save"></i><span>{{ $t('Save') }}</span></button>
        </div>
      </form>
    </div>
    <GlassModal v-if="verifyModal" style="color:black" @close="closeModal">
      <template slot="body" v-if="!isLoading">
        <div v-if="message" style="min-height:240px">
          <h3 v-if="verifyType === 'email'">{{ $t('E-Posta Hesabınızı Onaylayın') }}</h3>
          <h3 v-if="verifyType === 'gsm'">{{ $t('GSM Numaranızı Onaylayın') }}</h3>
          <p style="margin-top:22px; margin-bottom:22px">{{ message }}</p>
          <form method="dialog">
            <div class="row2" style="margin-bottom:14px">
              <label>{{ $t('Kod') }}</label>
              <input type="number" maxlength="6" style="color:black" autocomplete="off" name="code" v-model="verifyCode" :placeholder="$t('Kod')"/>
            </div>
            <div class="row2">
              <div></div>
              <button class="btn1 c1" @click="verifyCodeSend"><i class="fa fa-save"></i><span>{{ $t('Gönder') }}</span></button>
            </div>
          </form>
        </div>
        <div v-else style="min-height:240px"></div>
      </template>
      <template v-else slot="body">
        <div style="min-height:240px"><div class="loading"></div></div>
      </template>
    </GlassModal>
  </div>
</template>

<style scoped lang="scss">
form {
  margin-top: 20px;
}
.row {
  grid-template-columns: 160px auto;
  gap: 10px;
  label {
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .row {
    grid-template-columns: 1fr;
    gap: 0;
    label {
      text-align: left;
    }
  }
}
</style>

<script>
export default {
  data () {
    return {
      verifyModal: false,
      verifyType: 'gsm',
      verifyCode: '',
      message: '',
      isLoading: false,
      extraFields: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    closeModal: function () {
      this.verifyModal = false
      this.verifyType = ''
      this.verifyCode = ''
      this.message = ''
      this.isLoading = false
    },
    verifyCodeSend: async function () {
      const payload = {
        code: this.verifyCode,
        tip: this.verifyType
      }
      this.isLoading = true
      await this.axios.post('verify-code', payload).then(response => {
        // this.closeModal()
        this.isLoading = false
        this.$store.dispatch('setUser', response.data.user)
        this.closeModal()
      }).catch(error => {
        this.isLoading = false
        console.log(error)
      })
    },
    verifyUser: async function (tip) {
      this.verifyModal = true
      const payload = {
        tip: tip
      }
      await this.axios.post('verify-user', payload).then(response => {
        console.log(response.data)
        this.verifyType = tip
        setTimeout(() => {
          this.message = response.data.message
        }, 0)
      })
      console.log(payload)
    },
    saveSettings: async function () {
      const form = this.$refs.userSettings
      const formData = new FormData()
      formData.append('name', form.name.value)
      formData.append('company_name', form.company_name.value)
      formData.append('job_id', form.job_id.value)
      formData.append('city_id', form.city_id.value)
      if (this.user.is_merchant) {
        formData.append('company_whatsapp', form.company_whatsapp.value)
        formData.append('company_email', form.company_email.value)
        formData.append('company_phone', form.company_phone.value)
        formData.append('extra_fields', 'yes')
      }
      if (form.nick) {
        formData.append('nick', form.nick.value)
      }
      await this.axios.post('save-user-profile/' + this.user.id, formData).then(response => {
        this.$toast.success(this.$t('Profiliniz güncellendi'))
        this.$store.commit('setUser', response.data.user)
      }).catch(error => {
        const errors = error.response.data.errors
        let m = ''
        for (const key in errors) {
          m += errors[key][0] + '<br>'
        }
        this.$toast.error(m)
      })
    }
  }
}
</script>
