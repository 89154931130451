<template>
  <div>
    <div v-if="info">
      <div class="caption" v-if="info.title" style="border:none;margin:0">
        <h2>{{ $t(info.title) }}</h2>
        <router-link v-if="$route.name !== 'Comments'" :to="{ name: 'Comments' }">{{ $t(info.allTitle) }}</router-link>
      </div>
      <ul class="tabTitles" v-if="info.tabs">
        <li :class="{ 'activeTab': item.contentId === contentId }" v-for="item,idx in info.tabs" :key="idx">
          <button @click="getComments(item.contentId, info.commentType)">{{ item.title }}</button>
        </li>
      </ul>
      <div v-if="!error">
        <div class="writeComment">
          <i class="fa fa-camera" style="margin-right:12px;"></i><input @change="uploadFile" ref="file" type="file" accept="image/png, image/jpeg, image/gif" style="border:none;margin-bottom:12px" placeholder="Görsel Seçin" >
          <textarea v-model="newComment" @focus="checkUser" placeholder="Yorumunuzu buraya yazın"></textarea>
          <div class="actions">
            <div class="contract">
              <div style="display:flex;align-items:center; gap:5px">
                Bu sayfalarda yer alan okur yorumları kişilerin kendi görüşleridir. Yazılanlardan gunceldemir.com sorumlu tutulamaz.</div>
            </div>
            <button @click="sendComment" class="btn1 c1">Gönder</button>
          </div>
        </div>
        <div>
          <div>
            <CommentCard :info="comment" v-for="comment in comments" :key="comment.id" />
          </div>
          <div v-if="$route.name === 'Comments'">
            <div class="tableFooters" style="display:grid;place-items:center;padding-top:12px; padding-bottom:12px">
              <ul class="pager">
                <li v-for="item,idx in links" :key="idx">
                  <button @click="getPage(item.label)" class="c1" :class="{ activeButton: parseInt(item.label) === parseInt(activePage) }"
                    v-html="item.label">
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <MemberError v-else :message="error" />
    </div>
    <div v-else></div>
  </div>
</template>

<style lang="scss" scoped>
.activeButton, .activeButton:hover {
  background: #f5f5f5;
  color: black !important;
}
.error {
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
  border: 1px solid red;
  background: rgba($color: #ff0000, $alpha: 0.2);
  border-radius: 6px;
  margin-bottom: 4px;
}
.tabTitles {
  list-style: none;
  display: flex;
  border: 1px solid #808080;
  border-radius: 6px;
  margin-bottom: 12px;
  li {
    width: 100%;
    border-right: 1px solid #808080;
    display: grid;
    place-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    button {
      background: none;
      border: none;
      color: #fff;
      border-radius: 5px;
      display: block;
      padding-top:4px;
      padding-bottom:4px;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      width: calc(100% - 10px);
      text-align: center;
    }
    .active {
      color:#fff;
    }
  }
  li:last-child {
    border-right: none;
  }
}
.activeTab {
  transition: all 0.3s;
  button {
    // background-color: rgba($color: #fff, $alpha: .2) !important;
    background: var(--primary2)!important;
    color: #fff!important;
  }
}
.writeComment {
  margin-bottom: 20px;
  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #000;
    background: none;
    background: rgba(255,255,255,0.1);
    padding: 10px;
    color: white;
    resize: none;
  }
  .contract {
    font-size: 11px;
  }
  .actions {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
</style>

<script>
export default {
  props: ['info', 'imageSize'],
  data () {
    return {
      newComment: '',
      concent: false,
      comments: [],
      commentType: '',
      contentId: '',
      error: false,
      errorType: '',
      image: null,
      links: [],
      total: 0,
      last_page: 0,
      activePage: 1
    }
  },
  computed: {
    comment () {
      return this.$parent.comment
    },
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    user () {
      this.getComments(this.info.contentId, this.info.commentType)
    }
  },
  beforeMount () {
    this.getComments(this.info.contentId, this.info.commentType)
    // get comments every 10 seconds
  },
  methods: {
    getPage: function (page) {
      if (page === '&raquo;') {
        page = this.activePage + 1
      } else if (page === '&laquo;') {
        page = this.activePage - 1
      } else {
        page = parseInt(page)
      }
      if (page > 0 && page <= this.last_page) {
        console.log(page)
        this.activePage = page
        this.getComments(this.contentId, this.commentType)
      }
    },
    uploadFile: function () {
      this.image = this.$refs.file.files
      console.log(this.image)
    },
    getComments: async function (contentId, commentType) {
      this.commentType = commentType
      this.contentId = contentId
      await this.axios.get('comments/' + commentType + '/' + contentId + '?page=' + this.activePage).then(response => {
        this.comments = response.data.data
        this.links = response.data.links
        this.total = response.data.total
        this.last_page = response.data.last_page
        this.error = false
        this.errorType = ''
        // this.$store.dispatch('setComment', response.data)
      }).catch(error => {
        console.log(error.response.status)
        this.error = error.response.data.message
        this.errorType = 'membership'
      })
    },
    checkUser () {
      if (!this.user) {
        // this.$toast.error('Yorum yapabilmek için giriş yapmalısınız.')
      }
    },
    sendComment: async function () {
      if (this.user) {
        if (!this.newComment) {
          this.$toast.error('Yorumunuzu yazınız.')
        } else {
          let image = ''
          if (this.image) {
            image = this.image[0]
          }
          const payload = {
            comment: this.newComment,
            type: this.commentType,
            content_id: this.contentId,
            cover: image
          }
          const formData = new FormData()
          for (const key in payload) {
            formData.append(key, payload[key])
          }
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          await this.axios.post('/new-comment', formData, config).then(response => {
            this.$toast.success('Yorumunuz gönderildi.')
            this.newComment = ''
            const data = response.data
            this.getComments(data.content_id, data.type)
            this.image = null
            this.$refs.file.value = ''
          }).catch(err => {
            let message = ''
            if (err.response.status === 413) {
              message = this.$t('Yüklediğiniz dosya boyutu çok büyük. En fazla 1MB olabilir.')
            } else {
              message = err.response.data.message
            }
            this.$toast.error(message)
          })
        }
      } else {
        const data = {
          title: 'Yorum Yap',
          message: 'Yorum yapabilmek için üye olmalısınız',
          showLogin: true
        }
        this.$store.commit('setPromoteToMembership', data)
      }
    }
  }
}
</script>
