<template>
  <div>
    <div class="product" v-if="$parent.isReady">
      {{ item.title }}<br>
      {{ item.description }}<br>
      {{ item.price }}<br>
      <div v-if="!p.priceMicros">
        <button v-if="!isOwned" class="btn1 c5 buyButton" @click="$parent.buyProduct(p.id)">
          <i class="fa fa-shield-alt"></i>
          Abone Olun
        </button>
        <button v-else class="btn1 c3 buyButton"><i class="fa fa-check"></i>Bu Aboneliğe Sahipsiniz</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      ownership: false,
      isOwned: false,
      platform: localStorage.getItem('platforn') ?? 'web'
    }
  },
  methods: {
    checkOwnership: async function (p) {
      const payload = {
        product: p
      }
      await this.axios.post('check-ownership', payload).then(response => {
        if (response.data.status !== 'error') {
          this.isOwned = true
        }
      })
    }
  },
  mounted () {
    this.checkOwnership(this.p)
  },
  watch: {
    productsChanged: {
      handler: function (newVal, oldVal) {
        this.checkOwnership(this.p)
      },
      deep: true
    }
  },
  computed: {
    productsChanged () {
      return this.$store.state.productsChanged
    },
    storeProducts () {
      return this.$store.state.storeProducts
    },
    p () {
      if (this.storeProducts.length > 0) {
        return this.storeProducts.find(p => p.id === this.item.product_id)
      } else {
        return this.item
      }
    }
  }
}
</script>

<style scoped lang="scss">
.product {
  margin: auto;
  margin-bottom: 30px;
  padding: 20px;
  width: calc(100% - 40px);
  border:1px solid #ccc;
  border-radius: 5px;
  min-height: 160px;
}
</style>
