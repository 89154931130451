<template>
  <div>
    <header id="header">
      <div>
        <div class="container" v-if="$store.state.width > 768">
          <nav style="height:72px">
            <router-link :to="{ name: 'Home'}" class="logoC" style="grid-template-columns: 72px 180px;">
              <img height="72px" src="~@/assets/logo.png?v=21" alt="Artkaf" />
              <h1>
                Artvin ve Kafkas Dernekleri<br>
                Federasyonu
              </h1>
            </router-link>
            <router-link class="linkTop" :to="{ name: item.route, params: item.params }" v-for="item,idx in topMenu" :key="idx">
              <i v-if="item.icon" :class="item.icon"></i>
              <i v-else class="fa fa-circle"></i>
              <span>{{ item.title }}</span>
            </router-link>
          </nav>
        </div>
        <div v-else class="mobileNav" id="mobileNav">
          <router-link :to="{ name: 'Home'}" class="logoC">
            <img height="48px" src="~@/assets/logo.png?v=21" alt="Artkaf" />
            <h1>
              Artvin ve Kafkas Dernekleri<br>
              Federasyonu
            </h1>
          </router-link>
          <button @click="openMenu">
            <i class="fa fa-bars"></i>
          </button>
        </div>
      </div>
    </header>
    <div class="mobileMenu" @click.self="openMenu">
      <div class="mobileContainer">
        <div class="scroll">
          <ProfileCardSelf v-if="user" style="margin-bottom:12px" />
          <div v-else style="margin-bottom:12px; display:none">
            <button @click="showLogin(); openMenu()">
              <i class="fa fa-user"></i>
            </button>
          </div>
          <div class="section">
            <router-link class="link" :class="{ 'active' : $route.name === 'Home' }" :to="{ name: 'Home'}"><i class="fa fa-home"></i><span>{{ $t('Anasayfa') }}</span></router-link>
            <router-link class="link" :to="{ name: item.route, params: item.params }" v-for="item,idx in leftMenu" :key="idx">
              <i v-if="item.icon" :class="item.icon"></i>
              <i v-else class="fa fa-circle"></i>
              <span>{{ item.title }}</span>
            </router-link>
            <a v-if="user" @click="logout" class="link"><i class="fa fa-sign-out-alt"></i><span>{{ $i18n.t('Log Out') }}</span></a>
          </div>
        </div>
        <div class="section">
          <div class="social">
            <ul>
              <li v-for="item,idx in $store.state.siteInfo.socials" :key="idx">
                <a :href="item.url" target="_blank"><i :class="item.icon"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="searchModal" @click.self="showSearch">
      <div class="input">
        <i class="fa fa-search" style="color:black"></i>
        <input @keydown.esc="showSearch" type="text" id="searcher" placeholder="Arayın" autocomplete="off">
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import ProfileCardSelf from '@/components/ProfileCardSelf.vue'
export default {
  components: {
    ProfileCardSelf
  },
  data () {
    return {
      showSearchModal: false,
      showProfileMenu: false,
      showMenu: false,
      hSet: false,
      searchActive: false,
      statusBar: false,
      leftMenu: [
        {
          title: 'Federasyonumuz',
          route: 'PageDetail',
          icon: 'fa fa-university',
          params: {
            type: 'p',
            slug: 'federasyonumuz'
          }
        }, {
          title: 'Vizyon & Misyon',
          route: 'PageDetail',
          icon: 'fa fa-bullseye',
          params: {
            type: 'p',
            slug: 'vizyon-misyon'
          }
        }, {
          title: 'Dernekler',
          route: 'Dernekler',
          icon: 'fa fa-network-wired',
          params: {}
        }, {
          title: 'Delegeler',
          route: 'Delegeler',
          icon: 'fa fa-users'
        }, {
          title: 'Atvinin Değerleri',
          route: 'Delegeler',
          icon: 'fa fa-star'
        }, {
          title: 'İş Arıyorum',
          route: 'Delegeler',
          icon: 'fa fa-building'
        }, {
          title: 'İşçi Arıyorum',
          route: 'Delegeler',
          icon: 'fa fa-user-friends'
        }, {
          title: 'Artkaf TV',
          route: 'Tv',
          icon: 'fa fa-tv',
          params: {}
        }, {
          title: 'İndirim Kulübü',
          route: 'Indirim',
          icon: 'fa fa-gift'
        }, {
          title: 'Reklam Verin',
          route: 'Indirim',
          icon: 'fa fa-bullhorn'
        }, {
          title: 'İletişim Bilgilerimiz',
          route: 'Indirim',
          icon: 'fa fa-map'
        }
      ],
      topMenu: [
        {
          title: 'Dernekler',
          route: 'Dernekler',
          icon: 'fa fa-network-wired',
          params: {}
        }, {
          title: 'Delegeler',
          route: 'Delegeler',
          icon: 'fa fa-users'
        }, {
          title: 'Atvinin Değerleri',
          route: 'Delegeler',
          icon: 'fa fa-star'
        }, {
          title: 'İş Arıyorum',
          route: 'Delegeler',
          icon: 'fa fa-building'
        }, {
          title: 'İşçi Arıyorum',
          route: 'Delegeler',
          icon: 'fa fa-user-friends'
        }, {
          title: 'Artkaf TV',
          route: 'Tv',
          icon: 'fa fa-tv',
          params: {}
        }, {
          title: 'İndirim Kulübü',
          route: 'Indirim',
          icon: 'fa fa-gift'
        }
      ]
    }
  },
  watch: {
    $route: function (to, from) {
      if (this.$store.state.width < 768) {
        this.showSearchModal = false
        if (this.showMenu) {
          this.openMenu()
        }
      }
    }
  },
  methods: {
    logout: async function () {
      await this.axios.post('logout').then(response => {
        this.$store.commit('setMyPricesList', [])
        this.$store.commit('setMyPrices', [])
        this.$store.dispatch('setUser', null)
        this.$store.dispatch('setAxiosHeaderAuth', '')
        this.axios.defaults.headers.common.Authorization = ''
        this.$router.push({ name: 'Home' })
      })
    },
    showPromo: function () {
      this.$store.state.seenPromo = false
    },
    openMenu: function () {
      this.showMenu = !this.showMenu
      const el = document.querySelector('.mobileMenu')
      if (this.showMenu) {
        el.style.opacity = 1
        el.style.pointerEvents = 'all'
        el.style.left = '0px'
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        el.style.opacity = 0
        el.style.pointerEvents = 'none'
        el.style.left = '-300px'
        document.querySelector('body').style.overflow = 'unset'
      }
    },
    showLogin: function () {
      const data = {
        title: 'Giriş Yapın',
        message: '',
        showLogin: true
      }
      this.$store.commit('setPromoteToMembership', data)
    },
    showProfile: function () {
      this.showProfileMenu = !this.showProfileMenu
      const el = document.querySelector('.profileModal')
      if (this.showProfileMenu) {
        el.style.opacity = 1
        el.style.pointerEvents = 'all'
        el.style.right = '0px'
      } else {
        el.style.opacity = 0
        el.style.pointerEvents = 'none'
        el.style.right = '-300px'
      }
    },
    setScroll: function () {
      const test = true
      if (!test) {
        return
      }
      this.scroll = window.scrollY
      const el = document.getElementById('mobileNav')
      const header = document.getElementById('header')
      const c = document.getElementById('currencies')
      const platform = localStorage.getItem('platform')
      const safeArea = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat').replace('px', ''))
      if ((this.scroll > (c.offsetHeight + el.offsetHeight + safeArea))) {
        if (!this.hSet) {
          this.hSet = true
          const elHeight = (el.offsetHeight + c.offsetHeight) + 'px'
          header.style.height = elHeight
          el.style.position = 'fixed'
          el.style.top = elHeight * -1 + 'px'
        }
        // header.style.height = elHeight + safeArea + 'px'
      } else {
        header.style.height = 'unset'
        this.hSet = false
        // this.$root.showStatusBar()
      }
      if (this.scroll > 340) {
        el.style.position = 'fixed'
        if (platform === 'android') {
          el.style.paddingTop = 'calc(env(safe-area-inset-top) + 40px)'
        } else if (platform === 'ios') {
          el.style.paddingTop = 'calc(env(safe-area-inset-top) + 10px)'
        }
        el.style.top = '0px'
        this.statusBar = true
      } else {
        el.style.top = '-200px'
        el.style.position = 'relative'
        el.style.paddingTop = '10px'
        el.style.top = '0px'
        this.statusBar = false
      }
    },
    addSticky: function () {
      const el = document.querySelector('.mobileNav')
      el.classList.add('sticky')
    },
    showSearch: function () {
      this.showSearchModal = !this.showSearchModal
      const el = document.querySelector('.searchModal')
      if (this.showSearchModal) {
        el.style.opacity = 1
        el.style.pointerEvents = 'all'
        el.style.top = '0px'
        setTimeout(() => {
          document.getElementById('searcher').focus()
        }, 200)
      } else {
        el.style.opacity = 0
        el.style.pointerEvents = 'none'
        el.style.top = '-100px'
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    platform () {
      return localStorage.getItem('platform')
    }
  }
}
</script>

<style scoped lang="scss">
header {
  background: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
}
.social {
  padding-top: 12px;
  ul {
    display: flex;
    gap: 20px;
    margin-top: 5px;
    justify-content: space-around;
    list-style: none;
  }
  a {
    padding: 3px;
    border: none !important;
    i {
      font-size: 24px;
    }
    span {
      display: none;
    }
  }
}

.searchModal {
  // full screen spotlight search
  position: fixed;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: grid;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  transition: all 0.15s ease;
  opacity: 0;
  pointer-events: none;
  .input {
    border-radius: 20px;
    width: 500px;
    background: #fff;
    display: grid;
    grid-template-columns: 32px auto;
    align-items: center;
    i {
      text-align: center;
      padding-left: 8px;
    }
  }
  input {
    border: none;
    height: 48px;
    width: calc(100% - 20px);
    padding: 8px;
  }
  input:focus {
    outline: none;
  }
}
.profileModal {
  // full screen spotlight search
  position: fixed;
  top: 0px;
  left: 0;
  right: -100px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: grid;
  justify-content: right;
  align-items: start;
  backdrop-filter: blur(10px);
  transition: all 0.15s ease;
  opacity: 0;
  pointer-events: none;
}
.mobileMenu {
  // full screen spotlight search
  position: fixed;
  top: 0px;
  left: -300px;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11999;
  display: grid;
  justify-content: start;
  align-items: start;
  backdrop-filter: blur(10px);
  transition: all 0.15s ease;
  opacity: 0;
  pointer-events: none;
}
.mobileContainer {
  background:#fff;
  height: 100%;
  width: 80vw;
  padding: 10px;
  padding-top: calc(env(safe-area-inset-top) + 20px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    small {
      color: #666;
    }
    .link {
      display: grid;
      grid-template-columns: 24px auto;
      gap: 8px;
      align-items: center;
      padding: 8px;
      font-size: 14px;
      border: 1px solid #f5f5f5;
      margin-bottom: 8px;
      border-radius: 6px;
    }
    i {
      text-align: center;
      color: #666;
    }
  }
}
.linkTop {
  display: grid;
  grid-template-columns: 24px auto;
  gap: 0px;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  border: 2px solid #fff;
}
.linkTop:hover {
  border-bottom: 2px solid green;
}
.link.active {
  background: #3a6da6;
  i, span {
    color: #fff;
  }
}
.linkTop.active {
  border-bottom: 2px solid green;
  i, span {
    // color: #fff;
  }
}
.scroll {
  overflow:auto;
  // hide scroll bar
  -ms-overflow-style: none;
  // IE and Edge
  scrollbar-width: none;
  // Firefox
  &::-webkit-scrollbar {
    display: none;
  }
}
.logoC {
  display: grid;
  grid-template-columns: 48px 190px;
  align-items:center;
  height:60px;
  gap: 8px;
  border: none;
  h1 {
    font-size:14px;
    font-family: var(--font3);
  }
}
.mobileNav {
  display: grid;
  padding-left: 14px;
  padding-right: 14px;
  // grid-template-columns: 40px 40px auto 40px 40px;
  grid-template-columns: auto 40px;
  transition: all 0.35s ease;
  position: relative;
  z-index: 110;
  button {
    background: none;
    border: none;
    color: white;
    width: 100%;
    height: 100%;
  }
}
nav {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    border:none;
  }
  a {
    border-right: 1px solid #ccc;
    padding-left:10px;
    padding-right:10px;
  }
  a:last-child {
    border-right: none;
  }
  a:nth-last-child(-n+2) {
    border-right: none;
  }
}
.sticky {
  // position: fixed;
  // width: 100vw;
  // top: 0;
}

@media screen and (max-width: 1024px) {
  header {
    padding: unset;
  }
  nav {
    a{
      font-size: 12px;
      padding-left:5px;
      padding-right:5px;
      text-align: center;
    }
  }
  .logo{
    width: 130px;
    img {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .searchModal {
    .input {
      width: 280px;
      height: 42px;
    }
    input {
      height: 42px;
    }
  }
}
</style>
