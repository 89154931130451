<template>
  <div>
    <div v-if="$route.name === 'ForgetPassword'">
      <form id="passwordReset" method="dialog" v-if="!sent">
        <h1>{{ $t('Parolanızı Sıfırlayın') }}</h1>
        <div class="row" v-if="withEmail">
          <label>{{ $t('E-Posta Adresiniz') }}</label>
          <input @keypress.enter="resetPassword" name="email" v-model="email" type="email" autocomplete="off" :placeholder="$t('E-Posta Adresiniz')">
        </div>
        <div class="row" v-else>
          <label>{{ $t('Gsm Numaranız') }}</label>
          <input @keypress.enter="resetPassword" name="gsm" v-model="gsm" type="text" autocomplete="off" :placeholder="$t('Gsm Numaranız')">
        </div>
        <div class="row center grey">
          <a @click="toggleGsm" v-if="withEmail">veya GSM numaranızı kullanın</a>
          <a @click="toggleGsm" v-else>veya E-Posta adresinizi kullanın</a>
        </div>
        <div class="row">
          <button v-if="!isLoading" class="btn1 c1" @click="resetPassword" type="button"><i class="fa fa-lock"></i>{{ $t('Parolanızı Sıfırlayın') }}</button>
          <button v-else class="btn1 c1" type="button"><span class="inlineLoading"></span>{{ $t('Lütfen Bekleyin') }}</button>
        </div>
        <div class="row">
          <router-link :to="{ name: 'Login' }" class="iconLink"><i class="fa fa-user"></i><span>{{ $t('Giriş Yapın') }}</span></router-link>
          <router-link :to="{ name: 'Register' }" class="iconLink"><i class="fa fa-user-lock"></i><span>{{ $t('Üye Olun') }}</span></router-link>
        </div>
      </form>
      <div v-else class="success">
        <div style="padding-top:50px; padding-bottom:50px;">
          <i class="fa fa-check teal" style="font-size:48px"></i>
          <h1>{{ sent }}</h1>
        </div>
      </div>
    </div>
    <div v-if="$route.name === 'CompleteReset'">
      <form id="passwordReset" method="dialog" v-if="password_token">
        <h1>{{ $t('Parolanızı Sıfırlayın') }}</h1>
        <div class="row" v-if="withEmail">
          <label>{{ $t('Yeni Parolanız') }}</label>
          <input @keypress.enter="resetPasswordComplete" name="password" v-model="password" type="password" autocomplete="new-password" :placeholder="$t('Yeni Parolanız')">
        </div>
        <div class="row" v-if="withEmail">
          <label>{{ $t('Yeni Parolanız (Tekrar)') }}</label>
          <input @keypress.enter="resetPasswordComplete" name="password_confirmation" v-model="password_confirmation" type="password" autocomplete="new-password" :placeholder="$t('Yeni Parolanız (Tekrar)')">
        </div>
        <div class="row">
          <button v-if="!isLoading" class="btn1 c1" @click="resetPasswordComplete" type="button"><i class="fa fa-lock"></i>{{ $t('İşlemi Tamamlayın') }}</button>
          <button v-else class="btn1 c1" type="button"><span class="inlineLoading"></span>{{ $t('Lütfen Bekleyin') }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.success {
  text-align:center;
  display: grid;
  place-items: center;
  height: 100%;
  h1 {
    font-size:18px;
    font-weight: normal;
    line-height: 32px;
  }
}
form {
  font-size:14px;
  font-family: var(--font2);
  .isInvalid {
    border: none;
    border-bottom: 1px solid red;
    background: rgba(255,0,0,0.2) !important;
  }
  h1 {
    font-size: 22px;
    font-weight: normal;
    text-transform: uppercase;
  }
  .row {
    padding: 5x;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 0px;
    margin-bottom: 20px;
    label {
      text-align: left;
    }
  }
  @media screen and (max-width: 768px) {
    .row {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 0px;
      label {
        text-align: left;
      }
    }
  }
  input, button, select, .input {
    padding: 9px;
    border-radius: 6px;
    height: 42px;
    background: none;
    border: none;
    border: 1px solid rgb(59, 59, 59);
    color: #fff;
    width: 100%;
    background: rgba($color: #fff, $alpha: 0.05);
  }
  .input {
    background: rgba($color: #fff, $alpha: 0.03);
    border: 1px solid rgba($color: #fff, $alpha: 0);
  }
  .subInfo {
    color: grey;
    padding: 4px;
    font-size: 13px;
  }
  button {
    text-align: center;
    justify-content: center;
    height: 40px !important;
    font-size: 13px !important;
    i {
      font-size: 16px;
    }
  }
  label {
    font-size: 12px;
    margin-bottom: 3px;
    color: #ccc;
  }
  .social {
    button {
      height: 40px !important;
      display: grid;
      span {
        display: none;
      }
    }
  }
}
</style>

<script>
export default ({
  data () {
    return {
      isLoading: false,
      email: '',
      gsm: '',
      user: this.$store.state.user,
      formError: '',
      withEmail: true,
      sent: false,
      checkingToken: false,
      password: '',
      password_confirmation: '',
      password_token: ''
    }
  },
  mounted () {
    if (this.$route.name === 'CompleteReset') {
      this.checkToken()
    }
  },
  methods: {
    resetPasswordComplete: async function () {
      const payload = {
        password: this.password,
        password_confirmation: this.password_confirmation,
        password_token: this.password_token
      }
      this.isLoading = true
      await this.axios.post('reset-password-complete', payload).then(response => {
        this.$toast.success('Parolanız başarıyla değiştirildi.')
        this.isLoading = false
        this.$router.push({ name: 'Login' })
      }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
    },
    checkToken: async function () {
      const token = this.$route.query.t
      this.checkingToken = true
      await this.axios.post('/check-reset-token', { token }).then(response => {
        console.log(response)
        this.password_token = response.data.data.token
      })
      console.log(token)
    },
    toggleGsm () {
      this.withEmail = !this.withEmail
    },
    resetPassword: async function () {
      let payload = ''
      if (this.withEmail) {
        payload = {
          email: this.email
        }
      } else {
        payload = {
          gsm: this.gsm
        }
      }
      this.isLoading = true
      await this.axios.post('reset-password', payload).then(response => {
        this.isLoading = false
        this.sent = response.data.message
      }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
      console.log(payload)
    }
  }
})
</script>
