<template>
  <div class="news-card" v-if="info" @mouseover="$fnc.prefetch(info,DetailRoute)">
    <div class="image" :style="'height:' + height">
      <router-link v-if="info.cover_image" :to="{ name: DetailRoute, params: { slug: info.slug, category: info.category.slug }}">
        <img loading="lazy" :src="cdn + JSON.parse(info.image.media).medium" width="100%" :alt="info.title" :title="info.title">
      </router-link>
      <img v-else width="100%">
    </div>
    <router-link :to="{ name: DetailRoute, params: { slug: info.slug, category: info.category.slug }}" class="text">
      <h3>{{ info.title }}</h3>
      <small>{{ info.date }}</small>
      <p class="description" :title="info.description">{{ info.description }}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['info', 'DetailRoute', 'height'],
  computed: {
    cdn () {
      return this.$store.state.cdn
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  display: block;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  h3 {
    margin-bottom: 5px;
  }
  small {
    margin-top: 10px;
  }
  p {
    margin-top: 10px;
    font-size: 13px;
    line-height: 25px;
    // height: 100px;
    // overflow: hidden;
  }
}
.image {
  height: 210px;
  overflow: hidden;
  border-radius: 4px;
}
</style>
